<template>
        <div>
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form-group v-else>
                <b-row style="margin: 1rem">
                    <p> {{$t('program.login.desc')}} </p>
                </b-row>
                <b-row>
                    <b-col sm="12"> <b-form-input id="password" v-model="password" :placeholder="$t('program.login.pwd')" type="password" @keyup="login" /></b-col>
                </b-row>
                <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName2" />

            </b-form-group>
        </div>
</template>

<script>
import i18n from '@/plugins/i18n'
    export default {
        name: "AddPanel",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
              loading(){
                return this.$store.getters.getPanelSettingLoading
            },
        },
        components:{
            Modals: () => import('./index.vue')
        },
        data: function(){
            return{
                password:  "",
                modalOpen: false,
                currentForm: "ProsecPanel",
                modalProps: {},
                modalName2: null,
            }
        },
       
        watch: {
            save: async function(data){
                this.sendCommand()
            }
        },
        methods:{
            login: async function(e){
                if (e.keyCode === 13) {
                   this.sendCommand()
                }
            },
            sendCommand: async function(){
                 if(this.data.form){
                        if(this.data.form == "ARM" || this.data.form == "STAYARM" || this.data.form == "DISARM" ){
                            let params = {
                                dev_id: parseInt(this.data.panel.id),
                                command: this.data.form,
                                password: this.password,
                                partno: this.data.part ,
                                ispart: this.data.panel.partcount>0,
                            }
                            await this.$store.dispatch('panelCommand',params)
                            console.log(this.$store.getters.getCommand )
                            if(this.$store.getters.getCommand && this.$store.getters.getCommand.Err == 0){
                                //await this.$store.dispatch('panels')
                                switch(this.data.form ){
                                    case "ARM":
                                        var audio = new Audio(require('../../../assets/sound/'+i18n.t('general.sounds.arm')));
                                        break;
                                    case "DISARM":
                                        var audio = new Audio(require('../../../assets/sound/'+i18n.t('general.sounds.disarm')));
                                        break;
                                    case "STAYARM":
                                        var audio = new Audio(require('../../../assets/sound/'+i18n.t('general.sounds.stayarm')));
                                        break;
                                }
                                
                                audio.play();
                            }
                            this.modalEvent(false)
                        } else {
                            let params = {
                                dev_id: parseInt(this.data.panel.id),
                                command: 'USER',
                                user_pass: this.password
                            }
                            await this.$store.dispatch('panelSettings',params)
                            console.log(this.$store.getters.getSettings)
                            if(!this.$store.getters.getSettings.err && this.$store.getters.getSettings.user_no > 0){
                                this.openModal(true, this.data.form, this.data.panel)
                            }else{
                                vm.$root.$bvToast.toast('Lütfen yetkili bir kullanıcı ile giriş yapınız', {
                                    title: 'Yetki Bulunamadı',
                                    variant: 'danger',
                                    noAutoHide: true
                                })
                            }
                        }
                    }else{
                        this.$store.state.Panel.pwd = this.password
                        let params = {
                            dev_id: parseInt(this.data),
                            command: 'USER',
                            user_pass: this.password
                        }
                        await this.$store.dispatch('panelSettings',params)
                        this.$router.push( { path: '/PanelDetail/'+this.data })
                        this.modalEvent(false)
                    }
                    this.saveState(false)
            },
             openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName2 = modal
                this.modalOpen = state
            },
        }
        
    }
</script>