<template>
      <div>
        <b-icon icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p> {{$t('general.deleteMsg')}}</p>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                    switch(this.formName){
                        case "Version":
                            await this.$store.dispatch('deleteFirmwares', {id: this.data.version.id})
                            await this.$store.dispatch('listFirmwares', {id: this.$route.params.id})
                            break;   
                        case "Stage":
                            await this.$store.dispatch('deleteFirmwareStage', {id: this.data.stage.id})
                            await this.$store.dispatch('listFirmwaresStage', {id: this.$route.params.id})
                            break;   
                    }
                    this.saveState(false)
                    this.modalEvent(false)
                }
                this.saveState(false)
            },
        }
    }
</script>