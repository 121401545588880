<template>
    <form ref="form">
         <b-form-group>
            <b-row>
                <b-col sm="4"> <label for="accountName"> Açıklama </label> </b-col>
                <b-col sm="8"> <b-form-input id="accountName" v-model="description" placeholder="Açıklama Giriniz" /></b-col>
            </b-row>
        </b-form-group>
    </form>
</template>

<script>
    export default {
        name: "AddPanel",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        data: function(){
            return{
                description:  this.data ? this.data.description: ""
            }
        },
       
        watch: {
            save: async function(data){
                if(data){
                    let data = {
                        device_id: this.data.id,
                        Description: this.description
                    }
                    await this.$store.dispatch('changePanelDesc',data)
                    console.log(this.$store.getters.getStatus)
                    if(this.$store.getters.getStatus.status == 'Success')
                        await this.$store.dispatch('panels',{offset:0})
                }
                this.saveState(false)
            }
        }
        
    }
</script>