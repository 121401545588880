<template>
    <div>
        <form ref="form">
             <b-form-group>
                <b-row>
                    <b-col sm="4"> <label for="accountNo"> Açıklama </label> </b-col>
                    <b-col sm="8"> <b-textarea id="version" v-model="description"  /> </b-col>
                </b-row>
            </b-form-group>
            
        </form>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                description: this.data.stage ? this.data.stage.description ?  this.data.stage.description : "" : ""

            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                  if(this.modalName == "addStage"){
                      let params = {
                          description: this.description,
                          model_id: this.data.id
                      }
                      await this.$store.dispatch('addFirmwareStage', params)
                  }else{
                      let params = {
                          description: this.description,
                          id: this.data.stage.id
                      }
                      await this.$store.dispatch('updateFirmwareStage', params)
                  }
                   await this.$store.dispatch('listFirmwaresStage', {id: this.$route.params.id})
                }
                this.saveState(false)
                this.modalEvent(false)

            },
        }
    }
</script>