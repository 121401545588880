// Actiondan gelen datalar ile stateler güncellenecek
export const userLoading = (state, payload) => {
    state.loading = payload
}

export const userStatus = (state, payload) => {
    state.userStatus = payload
}

export const login = (state, payload) => {
    if(payload.data)
        state.login = typeof payload.data == "string" ? JSON.parse(payload.data) : payload.data
    else
        state.login = payload
}
export const register = (state, payload) => {
    console.log(payload)
    if(payload.data)
        state.register = payload.data
    else
        state.register = payload
}
export const listUser = (state, payload) => {
    if(payload.data)
        state.users = payload.data
    else
        state.users = payload
    state.pageInfo = payload.data.list_info
}
export const JWT = (state, payload) => {
    if(payload.data){
        localStorage.setItem("access_token", payload.data.data.token)
    }
}
export const userAuthPlan = (state, payload) => {
   state.auth = payload ? payload.data : {}
}
export const userDetail = (state, payload) => {
    if(payload.data)
        state.user = payload.data
    else
        state.user = payload
}

export const reports = (state, payload) => {
    if(payload.data)
        state.reports = payload.data
    else
        state.reports = payload
}

export const dealers = (state, payload) => {
    if(payload.data)
        state.dealers = payload.data
    else
        state.dealers = {}
}