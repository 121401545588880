<template>
      <div>
        <b-icon v-if="data.state == false" icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <b-icon v-if="data.state ==true" icon="check" variant="success" animation="throb" font-scale="8" />
       
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p v-if="data.state == true"> 
            <strong> {{data.dealer.dealer_name}} - {{data.dealer.name}} ({{data.dealer.email}}) </strong>
            Kullanıcının bayiliğini onaylamak üzeresiniz. Bu işlem geri alınamaz. Lütfen bu işlemi gerçekleştirmeden önce 
            gönderilen vergi levhasını inceleyin ve sadece gerekli koşullara uyan kullanıcıların bayiliğini onaylayınız. 
            Bayiliğini onayladığınız kullanıcılar otomatik olarak 1 yıllık mühendis yetkisine sahip olacaklardır. Lütfen
            Bayiniz için daha önce kullanılmamış bir bayi kodu belirleyin. Bayiniz bu kod ile sisteme müşterilerini kayıt 
            edebilir ve web arayüzü üzerinden yönetebilir.
                <validation-observer style="align-items: center; display: flex; justify-content: center; flex: 1;" ref="dealer">
                    <b-form style="flex: 1; padding: 1rem;">
                        <validation-provider name="Bayi Kodu" :rules="{min:3, required}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-form-input id="code" v-model="code" type="text" required placeholder="Bayi Kodu" :state="getValidationState(validationContext)"></b-form-input>
                                </b-row>
                                    <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </validation-observer>
            </p>
                
        <p v-else> 
            <strong> {{data.dealer.dealer_name}} - {{data.dealer.name}} ({{data.dealer.email}}) </strong>
            Kullanıcının bayiliğini red etmek üzeresiniz. Lütfen red sebebini kullanıcıya mail üzerinden bildiriniz.
        </p>
   </div>
</template>

<script>
    export default {
        name: "SetStateCustomer",
        data: function(){
            return {
                code: ''
            }
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                    if(this.data.state){
                        this.$refs.dealer.validate().then( async success =>{
                            if(success){
                                await this.$store.dispatch('setStateDealer', { 
                                    id: this.data.dealer.id,
                                    code: this.code,
                                    state: 1,
                                    link: 'https://prosecpanel.com/appconfig/login/PROSEC.png'
                                })
                                 if(this.$store.state.Dealer.status.status == "Success"){
                                    vm.$root.$bvToast.toast('Bayilik başarıyla oluşturulmuştur.', {
                                        title: 'Başarılı',
                                        variant: 'success',
                                        noAutoHide: true
                                    })
                                    await this.$store.dispatch('listDealerRequest')
                                    this.saveState(false)
                                    this.modalEvent(false)
                                }
                                
                            }
                        })
                    }else{
                        await this.$store.dispatch('setStateDealer', { 
                            id: this.data.dealer.id,
                            state: 2
                        })
                         if(this.$store.state.Dealer.status.status == "Success"){
                            vm.$root.$bvToast.toast('Bayilik başarıyla red edilmiştir.', {
                                title: 'Başarılı',
                                variant: 'success',
                                noAutoHide: true
                            })
                            await this.$store.dispatch('listDealerRequest')
                            this.saveState(false)
                            this.modalEvent(false)
                        }
                    }
                      
                }
                this.saveState(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            }
        }
    }
</script>