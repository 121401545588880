import Vue from 'vue'
import Vuex from 'vuex'
import User from "./modules/Users"
import Panel from "./modules/Panels"
import PanelCMD from "./modules/PanelCMD"
import Firmware from "./modules/Firmware"
import Payment from "./modules/Payment"
import Help from "./modules/Help"
import Dealer from "./modules/Dealer"


Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    User, Panel, PanelCMD, Firmware, Payment, Help, Dealer
  }
}) 