//Stateler çekilip return edilecek

export const getPaymentLoading = (state) => {
    return state.loading
}

export const payments = (state) => {
    return state.payments
}

export const getPaymentInfo = (state) => {
    return state.paymentInfo
}

export const getPaymentSettings = (state) => {
    return state.paymentSettings
}

export const getCouponReqInfo = (state) => {
    return state.couponReqInfo
}

export const getCouponReqs = (state) => {
    return state.couponReqs
}
export const getCouponHistory = (state) => {
    return state.couponHistory
}

export const getCouponList = (state) => {
    return state.couponList
}

export const getpaymentStatus = (state) => {
    return state.paymentStatus
}

export const getOrderPageInfo = (state) => {
    return state.orderPageInfo
}

export const getOrderList = (state) => {
    return state.orders
}

export const getPaymentReportInfo = (state) => {
    return state.paymentReportInfo
}

export const getPaymentReport = (state) => {
    return state.paymentReport
}
