// Actiondan gelen datalar ile stateler güncellenecek
export const yandexLoading = (state, payload) => {
    state.loading = payload
}

export const yandexList = (state, payload) => {
    if(payload._embedded)
        state.yandexList = payload._embedded.items
    else 
        state.yandexList = []
}

export const yandexFiles = (state, payload) => {
    if(payload._embedded)
        state.yandexFiles = payload._embedded.items
    else 
        state.yandexFiles = []
}

export const yandexDownload = (state, payload) => {
    state.yandexDownload = payload
}