<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="couponReqs" :filtered="filtered" type="List" :loading="loading" :actions="actions" :actionEvent="actionEvent" :goDetail="goDetail" />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    let breadCrumb = [
        { label: i18n.t('payment.requests'), url: "/Payment/Coupuns" },
    ]
     let fields = [ 
         { key: 'date', type:'date', label: i18n.t('payment.createDate'), keys:['created_at']},
         { key: 'payment_title',  type: 'badge', label: i18n.t('payment.paymentTitle'), keys:['payment_title'] },
         { key: 'reqUser', label: i18n.t('payment.creator'), keys:['request_user_info', 'email'] },
         { key: 'month', label: i18n.t('payment.duration'), keys:['month'] },
         { key: 'price', label: i18n.t('payment.price'), keys:['total_price'] },
         { key: 'amount', label: i18n.t('payment.count'), keys:['amount'] },
         { key: 'state', label: i18n.t('payment.state'), keys:['state'] },
         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let actions = [
        {key: 'see', text: i18n.t('payment.codeList')},
        {key: 'new', text: i18n.t('payment.newCoupon')},
        //{key: 'delete', text: 'Siparişi Sil', disabled: true},
    ]

    let filtered = ['date', 'amount', 'reqUser', 'state', "price", 'month', 'actions', 'payment_title']; 

    export default {
        name: 'PaymentList',
         created: async function(){
            await this.$store.dispatch('codeRequests')
        },
        computed:{
            couponReqs(){
                return this.$store.getters.getCouponReqs
            },
            loading(){
                return this.$store.getters.getPaymentLoading
            }

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"Coupon", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
            actionEvent: async function(key, data){
                console.log(data, key)
                switch(key){
                    case 'see': 
                        this.openModal(true, 'Codes', data)
                        break;
                    case 'new': 
                        await this.$store.dispatch('listPayment')
                        this.openModal(true, 'CreateCoupon', data)
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  data)
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>