<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-container align-v="center" class="container">
            <b-alert v-if="noSub" variant="danger" show>Aboneliğiniz WEB uygulamasınız kullanmak için yeterli değildir. Lütfen geçerli bir abonelik satına alın veya kullanıcı adınızın altındaki menüden aboneliğinizi uzatın. Mobil uygulamamızın ücretsiz versiyonunu kullanmaya devam edebilirsiniz.</b-alert>
            <div class="mt-5">
                <b-card-group deck class="mb-3">
                    <b-card v-for="pay in payments" :key="pay.id" :header="pay.title" no-body tag="article" 
                        header-text-variant="white" align="center"  class="mb-5 mt-2" 
                        :header-bg-variant="pay.id % 3 == 0 ? 'success' : pay.id % 3 == 1? 'danger' : 'primary'">
                        <b-card-body style='height: auto !important; margin: 1rem 0; overflow: hidden;'>
                            
                            <b-list-group flush>
                                <b-list-group-item></b-list-group-item>
                                <b-list-group-item v-for="detail in pay.details" :key="detail.id">
                                    <b-icon-check2-circle class="h5 mr-2 mb-0 mt-0" animation="fade"></b-icon-check2-circle>{{detail.description}}
                                </b-list-group-item>
                                <b-list-group-item></b-list-group-item>
                            </b-list-group>

                            <div style="display: flex; justify-content: center">
                                <div style="display: flex; flex-direction: column" v-for="price in pay.prices " :key="price.id">
                                      <b-button 
                                        style="margin-left: 1rem"
                                        @click="goPlan(pay, price)" 
                                        :variant="price.id % 2 == 0 ? 'warning' : 'info'">
                                            {{price.price + ' '+ price.currency + ' / '+ price.title}}
                                    </b-button>
                                    <b-link v-if="getUserRole() > 0" @click="goPlan(pay,price, 'code')" >Kupon Üret</b-link>

                                </div>
                              
                            </div>
                        </b-card-body>
                    </b-card> 
                </b-card-group>
            </div>
        </b-container>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import jwt_decode from 'jwt-decode'
    import i18n from '@/plugins/i18n'

    let breadCrumb = [
        { label: i18n.t('payment.subs'), url: "/Payment/List" },
    ]

    export default {
        name: 'PaymentList',
         created: async function(){
            await this.$store.dispatch('listPayment')
            if(this.$route.query.type == 'noSub'){
                vm.$bvToast.toast("Aboneliğiniz WEB uygulamasınız kullanmak için yeterli değildir. Lütfen geçerli bir abonelik satına alın veya kullanıcı adınızın altındaki menüden aboneliğinizi uzatın. Mobil uygulamamızın ücretsiz versiyonunu kullanmaya devam edebilirsiniz.", {
                    title: `Geçersiz Abonelik`,
                    variant: 'danger',
                    noAutoHide: true
                })
                this.noSub = true

             }
        },
        computed:{
            payments(){
                return this.$store.getters.payments
            },
            loading(){
                return this.$store.getters.getPaymentLoading
            }

        },
        data: function(){
            return{
                breadCrumb, noSub: false
            }
        },
        components:{
            BreadCrumb
        },
        methods:{
            goPlan(pay, price, type){
                this.$router.push( { path: '/Payment/'+pay.id+'/'+price.id+'/'+type})
            },
            getUserRole(){
                return jwt_decode(localStorage.getItem('access_token')).UserType
            }
        }
    }
</script>
