<template>
    <div>
        <validation-observer  ref="panelMessage">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <validation-provider :name="$t('program.messages.rePeriod')" :rules="{required,integer, min_value:60}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.messages.rePeriod')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="period" v-model="period" :placeholder="$t('program.messages.rePeriod')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.messages.screenTime')" :rules="{required,integer, min_value:10}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.messages.screenTime')}}</label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input  id="screenTime" v-model="screenTime" :placeholder="$t('program.messages.screenTime')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.messages.count')" :rules="{integer}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.messages.count')}}</label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input  id="repeatCount" v-model="repeatCount" :placeholder="$t('program.messages.count')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.messages.sound')" :rules="{required}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name">{{$t('program.messages.sound')}} </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    v-model="keypadSound"
                                    :show-labels="false" 
                                    :options="soundList"
                                    label="name"
                                    :state="getValidationState(validationContext)"
                                    track-by="id" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.name }} </strong> 
                                        </template>
                                </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                 <validation-provider :name="$t('program.messages.message')" :rules="{required}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.messages.message')}} </label> </b-col>
                            <b-col sm="7"> 
                                <b-form-textarea id="message" rows="5" v-model="message" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
               
            </b-form>
        </validation-observer>
        
    </div>
   
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
               period: 60,
               screenTime: 60,
               repeatCount: 5,
               message: '',
               soundList:[
                    {
                        name: i18n.t('program.messages.sound1'),
                        value: 128
                    },
                      {
                        name: i18n.t('program.messages.sound2'),
                        value: 42
                    },
                    {
                        name: i18n.t('program.messages.sound3'),
                        value: 255
                    },
               ],
            }
        },
       
        watch: { 
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.panelMessage.validate().then( async success => {
                        if(success){
                            let params = {
                            dev_id: parseInt(this.$route.params.id),
                            message: this.message,
                            period: parseInt(this.period),
                            duration: parseInt(this.screenTime),
                            count: parseInt(this.repeatCount),
                            sound: this.keypadSound ? parseInt(this.keypadSound.value) : 255
                        }
                        await this.$store.dispatch('sendMessage', params)
                        }
                        
                        
                    })
                     
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime, Multiselect
        },
         methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>