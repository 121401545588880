<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-card  class="formGroup">
            <AddForm ref="AddForm" />
             <template slot="footer">
                <b-button variant="primary" @click="saveForm">{{$t('general.save')}}</b-button>
            </template>
        </b-card>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import AddForm from './components/AddForm'
    import i18n from '@/plugins/i18n';
    //import Modals from "./modals/"

    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
        { label: 'Versiyon Ekle', url: "" },
    ]
    export default {
        name: 'UserAdd',
        created: async function(){
            await this.$store.dispatch('listModels', this.params)
        },
        data: function(){
            return{
                breadCrumb,
               
            }
        },
        components:{
            BreadCrumb, AddForm
        },
        computed:{
             models(){
                return this.$store.getters.getModels.data
            },
        },
        methods:{
          

            saveForm: async function(){
               let data = {
                    model_id: this.$refs.AddForm.model.id,
                    firmware_id: this.$refs.AddForm.version.id,
                }
                await this.$store.dispatch('addPanelFirmwareTest', data)
                if(this.$store.getters.getFirmwareStatus.status == "Success"){
                    this.$router.push('/PanelTest/List')
                }
            }
        }
    }
</script>
<style lang="css" scoped > 
    @import "./styles/add.min.css"; 
</style>