<template>
    <b-modal id="editModal" ref="modal" :title="title" @hide="onHide" @cancel="onCancel" :centered="true" :visible="open" :ok-title="okTitle"
     :cancel-title="$t('general.cancel')" size="lg" @ok="saveModal" :ok-disabled="disabled" :static="true" :hide-footer="hideFooterVal">
            <component v-bind:is="renderModalForm" :save="saveAct" :data="modalProps" :changeVal="changeDisabled" :changeData="changeData" 
            :saveState="saveState" :modalName="modalName" :modalEvent="modalEvent" :formName="form" :onComplete="onComplete"></component>
    </b-modal>
</template>

<script>
   
    import i18n from '@/plugins/i18n';
    import SetStateUser from './SetStateUser'
    import SetStatePanel from './SetStatePanel'
    import MobileResetUser from './MobileResetUser'
    import PasswordReset from './PasswordReset'
    import Verify from './Verify'
    import Forgot from './ForgotPwd'
    import ResetLogin from './ResetLogin'
    import ChangePwd from './ChangePwd'
    import MoveSubs from './MoveSubs'
    import Delete from './Delete'

    export default {
        name: 'UserModal',
        data: function(){
            return{
                title: '',
                okTitle: i18n.t('general.save'),
                disabled: false,
                saveAct: false,
                hideFooterVal: false,
            }
        },
        props:{
            open: Boolean,
            modalEvent: Function,
            form: String,
            modalName: String,
            modalProps: Object,
            onComplete: Function,
            
        },
        components:{
         SetStateUser, Delete, MobileResetUser, PasswordReset, SetStatePanel, Verify, Forgot,ResetLogin, ChangePwd, MoveSubs
        },
        computed:{
            //Açılacak Modalı belirleme işlemleri
            renderModalForm: function(){
                this.hideFooterVal = false
                if(this.modalName === "add"){
                    this.title = i18n.t('general.add')
                    this.okTitle = i18n.t('general.add')
                    return this.form
                } else if (this.modalName === "edit"){
                    this.title = i18n.t('general.edit')
                    this.okTitle = i18n.t('general.edit')
                    return this.form
                } else if(this.modalName === "delete") {
                    this.title = i18n.t('general.delete')
                    this.okTitle = i18n.t('general.delete')
                    return "Delete"
                }else if( this.form === "User"){
                    if(this.modalName === "SetState"){
                        this.title = this.modalProps &&  
                        this.modalProps.user.User ? this.modalProps.user.User.name : this.modalProps.user.name +
                        " - "+this.modalProps.user.User ? this.modalProps.user.User.email : this.modalProps.user.email 
                        this.okTitle= i18n.t('general.save')
                        return "SetStateUser"
                    } else if(this.modalName === "SetStatePanel"){
                        this.title = this.modalProps &&  this.modalProps.panel.DevId 
                        this.okTitle= i18n.t('general.save')
                        return "SetStatePanel"
                    }else if(this.modalName === "MblReset"){
                        this.title = this.modalProps && 
                          this.modalProps.user ? this.modalProps.user.name : this.modalProps.name +
                        " - "+this.modalProps.user ? this.modalProps.user.email : this.modalProps.email 
                        this.okTitle= i18n.t('general.save')
                        return "MobileResetUser"
                    } else if(this.modalName === "PwdReset"){ 
                        this.title = this.modalProps && 
                          this.modalProps.user ? this.modalProps.user.name : this.modalProps.name +
                        " - "+this.modalProps.user ? this.modalProps.user.email : this.modalProps.user.email 
                        this.okTitle= i18n.t('general.save')
                        return "PasswordReset"
                    }else if(this.modalName === "MoveSubs"){
                        this.title = this.modalProps && 
                          this.modalProps.user ? this.modalProps.user.name : this.modalProps.name +
                        " - "+this.modalProps.user ? this.modalProps.user.email : this.modalProps.user.email 
                        this.okTitle= i18n.t('general.move')
                        return "MoveSubs"
                    } else if(this.modalName === "ChangePwd"){
                        this.title = i18n.t('user.changePwd')
                        this.okTitle= i18n.t('general.save')
                        return "ChangePwd"
                    }
                    else{
                        return this.modalName
                    }
                }
            },
        },
        methods:{
            onHide: function(){
                if(!this.saveAct)
                    this.modalEvent(false)
            },
            onCancel: function(){
                this.saveState(false)
                if(!this.saveAct)
                    this.modalEvent(false)

            },
            saveState: function(val){
                this.saveAct = val
            },
            // Modal tamamlandığı zaman olacak event
            saveModal: async function(bvModalEvt){
                bvModalEvt.preventDefault()
                // Modalın içerisindeki watchı tetikler
                this.saveState(true)
            },
            changeData(val, name){
                this.modalProps[name] = val
            },
            changeDisabled(val){
                if(val == this.modalProps.name)
                    this.disabled = false
            }
        },
        watch:{
            saveAct(){
                return false
            }
        }
    }
</script>