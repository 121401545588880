
// Actiondan gelen datalar ile stateler güncellenecek
export const paymentLoading = (state, payload) => {
    state.loading = payload
}

export const payments = (state, payload) => {
    state.payments = payload && payload.data
}

export const paymentInfo = (state, payload) => {
    state.paymentInfo = payload && payload.data
}

export const paymentSettings = (state, payload) => {
    state.paymentSettings = payload && payload.data
}

export const couponReq = (state, payload) => {
    state.couponReqInfo = payload && payload.data
}
export const couponReqs = (state, payload) => {
    state.couponReqs = payload && payload.data
}
export const couponHistory = (state, payload) => {
    state.couponHistory = payload && payload.data
}
export const couponList = (state, payload) => {
    state.couponList = payload && payload.data
}
export const orders = (state, payload) => {
    state.couponReqInfo = payload && payload.data
}

export const orderList = (state, payload) => {
    state.orders = payload ? payload.data : []
    state.orderPageInfo = payload.list_info
}

export const paymentStatus = (state, payload) => {
    state.paymentStatus = payload
}

export const paymentReport = (state, payload) => {
    state.paymentReport = payload ? payload.data : []
    state.paymentReportInfo = payload.list_info
}
