//Stateler çekilip return edilecek

export const getUserLoading = (state) => {
    return state.loading
}

export const login = (state) => {
    return state.login
}

export const getRegister = (state) => {
    return state.register
}

export const getAuthUsers = (state) => {
    return state.users
}

export const getUserPageInfo = (state) => {
    return state.pageInfo
}

export const getUserStatus = (state) => {
    return state.userStatus
}

export const getAuthPlan = (state) => {
    return state.auth
}

export const getUserDetail = (state) => {
    console.log(state)
    return state.user
}

export const getReports = (state) => {
    return state.reports
}