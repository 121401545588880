import vue from 'vue'
import store from '../../store/store'
let tm;

export function startTimeout(){
    /*tm = setTimeout(function(){
        vm.$root.$bvToast.toast("Panel ile bağlantı süreniz dolduğu için panelden çıkış yapıyorsunuz. Lütfen tekrar giriş yapınız", {
            title: `Dikkat! `,
            variant: 'warning',
            noAutoHide: true
        })
        vm.$router.push({ name: 'Panels' })
        store.state.Panel.pwd = ""
        store.state.Panel.auth = {}
    }, 900000)*/
}

export function stopTimeout(){
   // clearTimeout(tm)
}