import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    firmwares: [],
    models: [],
    firmwaresTest: [],
    firmwareStages: [],
    results: [],
    status: {}
})

export default {
    state,
    getters,
    actions,
    mutations
}