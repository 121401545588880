<template>
    <validation-observer ref="AddForm">
         <b-form class="formBox">  
              
            
                <validation-provider name="Model" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group class="inputGrp" label="Model" label-for="model" >
                        <Multiselect  
                            id="model"
                            v-model="model"
                            :show-labels="false" 
                            :options="models"
                            label="description"
                            track-by="id" >
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.description }} </strong> 
                                </template>
                        </Multiselect>
                        <b-form-invalid-feedback id="live-feedback" style="display: block">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>                         
                    </b-form-group>
                </validation-provider>
                   <validation-provider name="Versiyon" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group class="inputGrp" label="Versiyon" label-for="version" >
                        <Multiselect  
                            id="version"
                            v-model="version"
                            :show-labels="false" 
                            :options="firmwares"
                            label="firm_ver"
                            track-by="id" >
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.firm_ver }} </strong> 
                                </template>
                        </Multiselect>
                        <b-form-invalid-feedback id="live-feedback" style="display: block">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>                         
                    </b-form-group>
                </validation-provider>

              
              
         </b-form>
        </validation-observer>
</template>
               
<script>
    import Multiselect from 'vue-multiselect'


    export default {
        name: "AddForm",
        components:{
            Multiselect
        },
        computed: {
            firmwares(){
                return this.$store.getters.getFirmwares.data
            },
             models(){
                return this.$store.getters.getModels.data
            },
        },
        watch:{
            model(){
                this.$store.dispatch('listFirmwares', {id: this.model.id})
            }
        },
        data: function(){
            return{
                
                version: null,
                type: null,
                model: null,
               
               
                

            }
        },
        props:{
            data: Object,
            modalEvent: Function,
            pageSettings: Function,
        },
       
        methods: {
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },

    }
</script>

<style lang="css" scoped > 
    @import "../styles/add.min.css"; 
</style>