<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-card v-else class="formGroup">
            <template slot="header">
                {{userDetail.name + ' - ' + $t('general.edit')}}
            </template>
            <Form :data="userDetail" ref="userEdit"  />
             <template slot="footer">
                <b-button variant="primary" @click="saveForm">{{$t('general.save')}}</b-button>
            </template>
        </b-card>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import i18n from '@/plugins/i18n';
    import Form from "./components/EditForm"
    //import Modals from "./modals/"

    let breadCrumb = [ ]
    export default {
        name: 'UserAdd',
        created: async function(){
            await this.$store.dispatch('getUserDetail')
        },
        data: function(){
            return{
                breadCrumb,
                modalOpen: false,
                modalProps: {},
                currentForm:"User",
                modalName: ""
            }
        },
        components:{
            BreadCrumb, Form
        },
        computed:{
              loading(){
                return this.$store.getters.getUserLoading
            },
            userDetail(){
                this.breadCrumb = [
                        { label: this.$store.getters.getUserDetail.name,
                            url: "/User/"+this.$route.params.id },
                        { label: i18n.t('general.edit'), url: "/User/Edit" }
                    ]
                    return this.$store.getters.getUserDetail
                
            },
        },
        methods:{
            saveForm: function(){
                this.$refs.userEdit.$refs.edit.validate().then( async success =>{
                    if(success){
                        let data = {
                            name: this.$refs.userEdit.name,
                            email: this.$refs.userEdit.mail,
                            phone_no: this.$refs.userEdit.phone,
                            dealercode: this.$refs.userEdit.dealer,
                        }
                        await this.$store.dispatch('userUpdate', data)
                        await this.$store.dispatch('getUserDetail')

                    }
                })  
            }
        }
    }
</script>
<style lang="css" scoped > 
    @import "./styles/add.min.css"; 
</style>