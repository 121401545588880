<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-card v-else header-tag="header" footer-tag="footer" class="cardFullHeight"> 
            <template v-slot:header>
                <div class="captions">
                    <div :class="{caption: true, active: dir == d.name}" v-for="d in directions" :key="d.resource_id" @click="filter(d)">{{d.name}}</div>
                </div>
            </template>
            <template >
                <div class="supports">
                    <div class="support" v-for="f in files" :key="f.resource_id"  @dblclick="openFolder(f)">
                        <div class="icon">
                            <b-icon :icon="f.type == 'dir' ? 'folder' : 'file-earmark-arrow-down'" font-scale="3" ></b-icon>
                        </div>
                        <div class="desc">
                            <div class="title"> {{f.name}} </div>
                            <div class="subTitle">{{f.path.split('/')[f.path.split('/').length-2]}} </div>
                        </div>
                        <div v-b-modal.modal-1 class="download" @click="download(f)">   {{$t('general.download')}}  </div>
                    </div>
                </div>
            </template>
            <b-modal id="modal-1" :title="lastFile.name" hide-footer>
                <p class="my-4">
                    {{lastFile.name}} isimli dosyayı indirmek üzeresiniz.
                </p>
                <b-button class="mt-3" variant="danger" block :href="getDownloadFile.href" target="_blank"> İndir </b-button>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import i18n from '@/plugins/i18n';

    let breadCrumb = [
        { label: i18n.t('login.title'), url: "/Panels" },
        { label: i18n.t('help.support'), url: "/Help" },
        { label: i18n.t('help.firmwares'), url: "/Help/Updates" },
    ]

   
    export default {
        name: 'ProsecPanelList',
        created: async function(){
          await this.$store.dispatch('yandexGetList',{path: '/Sanat Teknoloji/Prosec_Panel/Firmware'})
          this.filter(this.directions[0])
        },
        data: function(){
            return{
                breadCrumb,
                dir: '',
                lastFile: {},
                path: 'Sanat Teknoloji/Prosec_Panel/Firmware',
            }
        },
        components:{
            BreadCrumb, 
        },
        computed:{
            directions(){
                return this.$store.getters.getYandexList
            },
            files(){
                return this.$store.getters.getYandexFiles
            },
            loading(){
                return this.$store.getters.getYandexLoading
            },
            getDownloadFile(){
                return this.$store.getters.getYandexDownload
            }

        },
        watch:{
            path: async function(newVal, oldVal){
                await this.$store.dispatch('yandexGetFiles',{path: newVal})
            }
        },
        methods:{
            filter:function(data){
                this.dir = data.name
                this.path = 'Sanat Teknoloji/Prosec_Panel/Firmware/' + data.name
            },
            openFolder:function(data){
                console.log(data)
                if(data.type == 'dir')
                    this.path = data.path;
                else
                    window.open(data.file, '_blank');
            },
            download: async function(data){
                this.lastFile = data
                await this.$store.dispatch('yandexDownload',{path: data.path})

            }
        }
    }
</script>
<style lang="css" scoped> 
    @import "./styles/help.min.css"; 
</style>