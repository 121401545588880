// Actiondan gelen datalar ile stateler güncellenecek
export const dealerLoading = (state, payload) => {
    state.loading = payload
}

export const dealerStatus = (state, payload) => {
    if(payload.data)
        state.status = payload.data
    else
        state.status = {}
}

export const dealerList = (state, payload) => {
    if(payload.data)
        state.dealers = payload.data
    else
        state.dealers = []
}

export const setState = (state, payload) => {
    if(payload.data)
        state.status = payload.data
    else
        state.status = {}
}