<template>
    <form ref="form">
        <b-form-group>
            <b-row>
                <b-col sm="4"> <label> {{$t('program.zones.no')}}  </label> </b-col>
                <b-col sm="8">
                    <Multiselect  
                        v-model="zoneNo"
                        :show-labels="false" 
                        :options="zoneNoList"
                        label="name"
                        track-by="zoneNo" >
                            <template slot="singleLabel" slot-scope="{ option }">
                                <strong>{{ option.name }} </strong> 
                            </template>
                    </Multiselect> 
                </b-col>
            </b-row>
        </b-form-group>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect'
import i18n from '@/plugins/i18n'
    let zoneNoList =[
        {name: '1. '+i18n.t('program.zones.zone'), zoneNo:1},
        {name: '2. '+i18n.t('program.zones.zone'), zoneNo:2},
        {name: '3. '+i18n.t('program.zones.zone'), zoneNo:3},
        {name: '4. '+i18n.t('program.zones.zone'), zoneNo:4},
        {name: '5. '+i18n.t('program.zones.zone'), zoneNo:5},
        {name: '6. '+i18n.t('program.zones.zone'), zoneNo:6},
        {name: '7. '+i18n.t('program.zones.zone'), zoneNo:7},
        {name: '8. '+i18n.t('program.zones.zone'), zoneNo:8},
        {name: '9. '+i18n.t('program.zones.zone'), zoneNo:9},
        {name: '10. '+i18n.t('program.zones.zone'), zoneNo:10},
        {name: '11. '+i18n.t('program.zones.zone'), zoneNo:11},
        {name: '12. '+i18n.t('program.zones.zone'), zoneNo:12},
        {name: '13. '+i18n.t('program.zones.zone'), zoneNo:13},
        {name: '14. '+i18n.t('program.zones.zone'), zoneNo:14},
        {name: '15. '+i18n.t('program.zones.zone'), zoneNo:15},
        {name: '16. '+i18n.t('program.zones.zone'), zoneNo:16},
        {name: '17. '+i18n.t('program.zones.zone'), zoneNo:17},
        {name: '18. '+i18n.t('program.zones.zone'), zoneNo:18},
        {name: '19. '+i18n.t('program.zones.zone'), zoneNo:19},
    ]

    export default {
        name: "Zone",
        components:{
            Multiselect, 
            Modals: () => import('./index.vue')
        },
        data:function(){
           return{
               zoneNo:{},
               behavior:{},
               connectType: {},
               zoneType: {},
               zoneNoList, 
                modalOpen: false,
                currentForm: "Zone",
                modalProps: {},
                modalName: null,
           } 
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalEvent: Function
        },
        methods:{
            openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName = modal
                this.modalOpen = state
            },
        },
         watch: {
            save: async function(data){
                if(data){
                    this.openModal(true,'program', {zone_no: this.zoneNo.zoneNo-1, name: ""})
                }
                this.saveState(false)

            }
         }
        
    }
</script>