import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {tr, en} from './language';
import { localize } from "vee-validate";
import { Settings } from 'luxon'
Vue.use(VueI18n);

const messages = {
  tr, en
};

function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

if( !localStorage.getItem("lang") ){
    let browserLang = Object.keys( messages ).find( (lang) => { return lang == navigator.language.substring(0,2) })
    if( browserLang )
        localStorage.setItem( "lang", browserLang )
    else
        localStorage.setItem( "lang", "en" )
}

let locale = localStorage.getItem("lang") === 'en' ? 'en-au' : localStorage.getItem("lang")
loadLocale(localStorage.getItem("lang"))
Settings.defaultLocale = locale
require('moment/locale/'+  locale )

const i18n = new VueI18n({
     locale: localStorage.getItem("lang"),  
     fallbackLocale: 'en', 
     messages,
});

export default i18n;