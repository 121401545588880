<template>
      <div>
        <b-icon icon="exclamation-triangle" variant="warning" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0"> Emin misiniz ? </h3>
        <p>
            <strong> {{moment(data.created_at)}} </strong> tarihinde  oluşturulan <strong> {{data.price}} {{data.currency}} </strong> tutarındaki  <strong> {{data.user_info.name}} - 
            {{data.user_info.email}} </strong>  ait siparişin faturasını kestiğinize emin misiniz? Bu işlem geri alınamaz lütfen faturayı kesmeden
            önce bu işlemi gerçekleştirmeyin.
         </p>
   </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        methods:{
            moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY');
            },
        },
        watch:{
            save: async function(data){
                if(data){
                    await this.$store.dispatch('billingOrder',  this.data)
                    await this.$store.dispatch('orderList')
                    this.saveState(false)
                    this.modalEvent(false)
                }
                this.saveState(false)
            },
        }
    }
</script>