<template>
    <div>
        <form ref="form">
            <b-form-group>
                <b-row>
                    <b-col sm="4"> <label for="accountNo"> Versiyon </label> </b-col>
                    <b-col sm="8"> <b-form-input id="version" v-model="version" placeholder="Versiyon" /> </b-col>
                </b-row>
            </b-form-group>
             <b-form-group>
                <b-row>
                    <b-col sm="4"> <label for="accountNo"> İndirme Linki </label> </b-col>
                    <b-col sm="8"> <b-form-input id="url" v-model="url" placeholder="url" /> </b-col>
                </b-row>
            </b-form-group>
             <b-form-group>
                <b-row>
                    <b-col sm="4"> <label for="accountNo"> Açıklama </label> </b-col>
                    <b-col sm="8"> <b-textarea id="version" v-model="description"  /> </b-col>
                </b-row>
            </b-form-group>
            
        </form>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                version: this.data.version ? this.data.version.firm_ver ?  this.data.version.firm_ver : "" : "",
                url: this.data.version ? this.data.version.url ?  this.data.version.url : "" : "",
                description: this.data.version ? this.data.version.description ?  this.data.version.description : "" : ""

            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                  if(this.modalName == "addVersion"){
                      let params = {
                          firm_ver: this.version,
                          url: this.url,
                          description: this.description,
                          model_id: this.data.id
                      }
                      await this.$store.dispatch('addFirmware', params)

                  }else if(this.modalName == "edit"){
                      let params = {
                          firm_ver: this.version,
                          url: this.url,
                          description: this.description,
                          model_id: this.data.version.model_id,
                          id: this.data.version.id
                      }
                      await this.$store.dispatch('editFirmware', params)
                  }
                      await this.$store.dispatch('listFirmwares', {id: this.$route.params.id})

                }
                this.saveState(false)
                this.modalEvent(false)

            },
        }
    }
</script>