//Stateler çekilip return edilecek

export const getPanelSettingLoading = (state) => {
    return state.loading
}
export const getCommand = (state) => {
    return state.command
}
export const getSetSettings = (state) => {
    return state.setSettings
}
export const getPanelCode = (state) => {
    return state.panelCode
}
export const getReader = (state) => {
    return state.reader
}

export const pdksExcel = (state) => {
    return state.pdksExcel
}