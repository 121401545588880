<template>
      <div>
        <b-icon icon="files" variant="primary" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p> {{data.panel.description}} - {{data.panel.dev_id}}
            {{$t('messages.movePanel')}}
        </p>
         <b-row v-if="userList.length > 0">
            <b-col sm="12"> 
                    <Multiselect  
                    v-model="user"
                    :show-labels="false" 
                    :options="userList"
                    label="email"
                    track-by="id" 
                    :custom-label="({ name, email }) => `${name} - [${email}]`">
                        <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.name +' - '+ option.email }} </strong> 
                        </template>
                </Multiselect>
            </b-col>
        </b-row>
   </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    export default {
        name: "SetStateCustomer",
        data: function(){
            return{
                user: null
            }
        },       
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
        components:{
            Multiselect
        },
        computed:{
            userList: function(){
                return this.$store.getters.getAuthUsers.data
            }
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = {
                            id: this.data.panel.id,
                            new_user_id: this.user.id,
                        }
                        await this.$store.dispatch('copyPanel', data)
                        if(this.$store.getters.getStatus.Success='Success')
                            await this.$store.dispatch('allPanels',{offset: 0} )
                        this.saveState(false)
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>