<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
         <form v-else ref="form">
            <b-form-group>
                <b-row>
                    <b-col sm="4"> Açıklama </b-col>
                    <b-col sm="8">
                    <b-input  v-model="desc" /> 
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group>
                <b-row>
                    <b-col sm="4"> Aksiyon </b-col>
                    <b-col sm="8">
                    <Multiselect  
                            v-model="act"
                            :show-labels="false" 
                            :options="actions"
                            label="text"
                            track-by="id" >
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.text }} </strong> 
                                </template>
                        </Multiselect> 
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group>
                <b-row>
                    <b-col sm="4"> Saat </b-col>
                    <b-col sm="8">
                        <Datetime v-model="hour" type="time" style="" ></Datetime>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group>
                <b-row>
                    <b-col sm="4"> Süre </b-col>
                    <b-col sm="8">
                        <Datetime v-model="time" type="time" ></Datetime>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group>
                <b-row>
                    <b-col sm="4"> Aktif Günler </b-col>
                    <b-col sm="8">
                            <Multiselect  
                            v-model="days"
                            :show-labels="false" 
                            :options="dayList"
                            :multiple="true"
                            label="name"
                            track-by="id" >
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.name }} </strong> 
                                </template>
                            </Multiselect>
                    </b-col>
                </b-row>
            </b-form-group>

        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'
 import { Datetime } from 'vue-datetime';
    let actions = [
        { text: 'Kapalı - Devre Dışı', value: 0},
        { text: 'Alarmı Çözme', value: 1},
        { text: 'Evde Kurma', value: 2},
        { text: 'Tam Kurma', value: 3},
        { text: 'Çıkış Aktif', value: 4},
        { text: 'Çıkış Pasif', value: 5},
        { text: 'Termostat', value: 6},
    ]
    let dayList = [
        { id: 1, name: 'Pazartesi' },
        { id: 2, name: 'Salı' },
        { id: 3, name: 'Çarşamba' },
        { id: 4, name: 'Perşembe' },
        { id: 5, name: 'Cuma' },
        { id: 6, name: 'Cumartesi' },
        { id: 7, name: 'Pazar' },
    ]
    export default {
        name: "Automation",
        components:{
            Multiselect, Datetime
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 8,
                member: parseInt(this.data.Code),
                user_pass: this.$store.getters.getPWD,
                offset: 0
            }
            await this.$store.dispatch('panelSettings', params)
        },
        data:function(){
           return{
             act: null, actions, dayList, days: [], id: 0,
               daysArr: [],
           } 
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            }
        },
          watch:{
            settings: function(newVal, oldVal) { // watch it
                console.log(newVal)
                this.act = this.actions[newVal.aim]
                this.hour = newVal.starttime.hour+":"+newVal.starttime.minute
                this.time = newVal.duration.hour+":"+newVal.duration.minute
                this.daysArr = Object.values(newVal.days);
                this.desc = newVal.description
                this.id = newVal.id
            },
            daysArr: function(newVal, oldVal){
                this.days = []
                newVal.map((day,i) => {
                    day && this.days.push(this.dayList[i])
                })
            },
            save: async function(data){
                if(data){
                    let params = {
                        dev_id: parseInt(this.$route.params.id),
                        command: 'SET',
                        group: 8,
                        member: this.data.Code,
                        user_pass: this.$store.getters.getPWD,
                        offset: 0,
                        description: this.desc,
                        aim: this.act.value,
                        id: this.id,
                        days:{
                            monday: this.days.findIndex(e => e.id == 1) > -1 ? true : false,
                            tuesday: this.days.findIndex(e => e.id == 2) > -1 ? true : false,
                            wednesday: this.days.findIndex(e => e.id == 3) > -1 ? true : false,
                            thursday: this.days.findIndex(e => e.id == 4) > -1 ? true : false,
                            friday: this.days.findIndex(e => e.id == 5) > -1 ? true : false,
                            saturday: this.days.findIndex(e => e.id == 6) > -1 ? true : false,
                            sunday: this.days.findIndex(e => e.id == 7) > -1 ? true : false
                        },
                        duration: {
                            hour: moment(this.time).format('HH'),
                            minute: moment(this.time).format('mm'),
                        },
                        starttime: {
                            hour: moment(this.hour).format('HH'),
                            minute: moment(this.hour).format('mm'),
                        }
                    }
                    console.log(params)
                    await this.$store.dispatch('setAutomationSettings', params)
                }
                this.saveState(false)
            }
          },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        
    }
</script>