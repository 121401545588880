import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    panels : [],
    status:{},
    zones: [],
    outs: [],
    detail: {},
    users: [],
    keypads: [],
    settings: {},
    settingStatus: {},
    pwd: "",
    descriptions: [],
    siaCodes: [],
    auth: {},
    panel: {},
    behaviors: [],
    connectTypes: [],
    zoneTypes: [],
    outFuncs: [],
    timedArms: [],
    autoArms: [],
    allPanels: [],
    pageInfo: {}
})

export default {
    state,
    getters,
    actions,
    mutations
}