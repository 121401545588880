<template>
    <b-modal ref="modal" :title="title" @hide="onHide" @cancel="onCancel" :centered="true" :visible="open" :ok-title="okTitle"
     :cancel-title="$t('general.cancel')" :size="size" @ok="saveModal" :ok-disabled="!saveState" :static="true">
            <component v-bind:is="renderModalForm" :save="saveAct" :data="modalProps" :changeVal="changeDisabled" :changeData="changeData" 
            :saveState="saveState" :modalName="modalName" :modalEvent="modalEvent" :formName="form" :onComplete="onComplete"></component>
    </b-modal>
</template>

<script>
    import Codes from './Codes'
    import Coupon from './Coupon'
    import Results from './Results'
    import Billing from './Billing'
    import Cancel from './Cancel'
    import CreateCoupon from './CreateCoupon'
    import i18n from '@/plugins/i18n';

    export default {
        name: 'Modals',
        data: function(){
             return{
                title: '',
                okTitle: i18n.t('general.save'),
                disabled: false,
                saveAct: false,
                size: 'lg',
                fakeModalName: "",
                fakeModalProps: {}

            }
        }, components:{
           Codes, Coupon, Results, Billing, Cancel, CreateCoupon
        },
        props:{
            open: Boolean,
            modalEvent: Function,
            form: String,
            modalName: String,
            modalProps: Object,
            onComplete: Function
        },
        computed:{
            //Açılacak Modalı belirleme işlemleri
            renderModalForm: function(){
                this.okTitle= i18n.t('general.save');
                if(this.modalName === "add"){
                    this.title = i18n.t('general.add')
                    this.okTitle = i18n.t('general.add')
                    return this.form
                }else if(this.modalName === 'Codes'){
                    this.title = i18n.t('payment.orderCoupons')
                    this.okTitle = i18n.t('general.ok')
                    this.$store.dispatch('codeList', this.modalProps)
                }else if(this.modalName === 'Coupon'){
                    this.title = i18n.t('payment.extendWithCoupon')
                    this.okTitle = i18n.t('payment.extendSubs')
                }else if(this.modalName === 'billing'){
                    this.title = 'Faturalandır'
                    this.okTitle = 'Faturalandır'
                }else if(this.modalName === 'cancel'){
                    this.title = 'Ödemeyi İptal Et'
                    this.okTitle = 'İptal Et'
                }
                return this.modalName
            },
        },
        methods:{
            
            onHide: function(){
                if(!this.saveAct)
                    this.modalEvent(false)
            },
            onCancel: function(){
                this.saveState(false)
                if(!this.saveAct)
                    this.modalEvent(false)

            },
            saveState: function(val){
                this.saveAct = val
            },
            // Modal tamamlandığı zaman olacak event
            saveModal: async function(bvModalEvt){
                bvModalEvt.preventDefault()
                this.saveState(true)
            },
            changeData(val, name){
                this.modalProps[name] = val
            },
            changeDisabled(val){
                if(val == this.modalProps.name)
                    this.disabled = false
            }
        },
         watch:{
            saveAct(){
                return false
            }
        }
    }
</script>
