<template>
      <div>
        <img :src="server+'/'+data.photo"/>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        computed:{
            server(){
                return process.env.VUE_APP_API_SERVER
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                this.saveState(false)
                this.modalEvent(false)
            },
        }
    }
</script>