<template>
    <b-modal id="editModal" ref="modal" :title="title" @hide="onHide" @cancel="onCancel" :centered="true" :visible="open" :ok-title="okTitle"
     :cancel-title="$t('general.cancel')" size="lg" @ok="saveModal" :ok-disabled="disabled" :static="true" :hide-footer="hideFooterVal">
            <component v-bind:is="renderModalForm" :save="saveAct" :data="modalProps" :changeVal="changeDisabled" :changeData="changeData" 
            :saveState="saveState" :modalName="modalName" :modalEvent="modalEvent" :formName="form" :onComplete="onComplete"></component>
    </b-modal>
</template>

<script>
   
    import i18n from '@/plugins/i18n';
    import DealerApply from './DealerApply'
    import SetState from './SetState'


    export default {
        name: 'UserModal',
        data: function(){
            return{
                title: '',
                okTitle: i18n.t('general.save'),
                disabled: false,
                saveAct: false,
                hideFooterVal: false,
            }
        },
        props:{
            open: Boolean,
            modalEvent: Function,
            form: String,
            modalName: String,
            modalProps: Object,
            onComplete: Function,
            
        },
        components:{
             DealerApply, SetState
        },
        computed:{
            //Açılacak Modalı belirleme işlemleri
            renderModalForm: function(){
                this.hideFooterVal = false
                if(this.modalName === "add"){
                    this.title = i18n.t('general.add')
                    this.okTitle = i18n.t('general.add')
                    return this.form
                } else if (this.modalName === "edit"){
                    this.title = i18n.t('general.edit')
                    this.okTitle = i18n.t('general.edit')
                    return this.form
                } else if(this.modalName === "delete") {
                    this.title = i18n.t('general.delete')
                    this.okTitle = i18n.t('general.delete')
                    return "Delete"
                }else if(this.modalName === "DealerApply"){
                        this.hideFooterVal = true
                        return 'DealerApply'
                }else{
                    return this.modalName
                }
            },
        },
        methods:{
            onHide: function(){
                if(!this.saveAct)
                    this.modalEvent(false)
            },
            onCancel: function(){
                this.saveState(false)
                if(!this.saveAct)
                    this.modalEvent(false)

            },
            saveState: function(val){
                this.saveAct = val
            },
            // Modal tamamlandığı zaman olacak event
            saveModal: async function(bvModalEvt){
                bvModalEvt.preventDefault()
                // Modalın içerisindeki watchı tetikler
                this.saveState(true)
            },
            changeData(val, name){
                this.modalProps[name] = val
            },
            changeDisabled(val){
                if(val == this.modalProps.name)
                    this.disabled = false
            }
        },
        watch:{
            saveAct(){
                return false
            }
        }
    }
</script>