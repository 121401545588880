<template>
      <div>
        <b-icon icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p> {{$t('general.deleteMsg')}}</p>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                    switch(this.formName){
                        case "User":
                            await this.$store.dispatch('deleteUser', { user_id: this.data.user.id,})
                            await this.$store.dispatch('listUser')
                             this.saveState(false)
                            this.modalEvent(false)
                            break;   
                    }
                }
                this.saveState(false)
            },
        }
    }
</script>