// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const panels = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/userdevices/"+data.offset)
    res.data.offset = data.offset
    commit('panels', res.data);
    commit('panelLoading', false);
}

export const allPanels = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/useralldevices", data)
    commit('allPanels', res.data);
    commit('panelLoading', false);
}


export const panelDetail = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","/command/devices/"+data.id+"/devicepart/0")
    commit('panelDetail', res.data);
    commit('panelLoading', false);
}

export const addPanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("post","command/devices/userdevices/addpanel", data)
    commit('PanelStatus', res.data);
    commit('panelLoading', false);
}

export const changePanelDesc = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("put","command/devices/userdevices/changepaneldesc", data)
    commit('panelStatus', res);
    commit('panelLoading', false);
}

export const removePanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("delete","command/devices/userdevices/removepanel", data)
    commit('panelStatus', res);
    commit('panelLoading', false);
}

export const panelZones = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/"+data.id+"/zones")
    commit('zones', res.data);
    commit('panelLoading', false);
}

export const panelOuts = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/"+data.id+"/outs")
    commit('outs', res.data);
    commit('panelLoading', false);

}

export const panelUsers = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/"+data.id+"/users", data)
    commit('users', res.data);
    commit('panelLoading', false);
}

export const panelKeypads = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/"+data.id+"/keypads")
    commit('keypads', res.data);
    commit('panelLoading', false);

}

export const panelSettings = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("POST","command/devices/getpanelsettings",data)
    commit('settings', res.data);
    commit('panelLoading', false);
}

export const searchPanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/userdevices/devicesearch/0/"+data)
    res.data.offset = 0
    commit('panels', res.data);
    commit('panelLoading', false);
}

export const alarmDesc = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getalarmdescriptions")
    commit('alarmDesc', res.data);
    commit('panelLoading', false);
}

export const siaCodes = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getsiacodes")
    commit('siaCodes', res.data);
    commit('panelLoading', false);
}

export const behaviorList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getbehaviorlist")
    commit('behaviorList', res.data);
    commit('panelLoading', false);
}

export const zoneConnectTypeList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getzonecontypelist")
    commit('zoneConnectTypeList', res.data);
    commit('panelLoading', false);
}

export const zoneTypeList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getzonetypelist")
    commit('zoneTypeList', res.data);
    commit('panelLoading', false);
}

export const outFuncTypeList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getoutfunctionlist")
    commit('outFuncTypeList', res.data);
    commit('panelLoading', false);
}

export const timedArmActList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/gettimedarmaimlist")
    commit('timedArmActList', res.data);
    commit('panelLoading', false);
}

export const autoArmList = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","list/getautoarmaimlist")
    commit('autoArmList', res.data);
    commit('panelLoading', false);
}

export const savePanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("post","command/devices/userdevices/addpanel", data)
    commit('addPanel', res.data);
    commit('panelLoading', false);
}
export const setpanelstatus = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("put","devices/setpanelstatus",data)
    commit('panelLoading', false);
}

export const sendZone = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("put","devices/"+data.device_id+"/zone/"+data.zone_id,data)
    commit('panelLoading', false);
}

export const sendOut = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("put","devices/"+data.device_id+"/output/"+data.out_id,data)
    commit('panelLoading', false);
}

export const sendMessage = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("post","programming/sendpanelmessage",data)
    commit('panelLoading', false);
}
export const copyPanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("post","command/devices/move-device", data)
    commit('copyPanel', res.data);
    commit('panelLoading', false);
}

export const resetPanel = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("post","reset-device-info", data)
    commit('panelStatus', res);
    commit('panelLoading', false);
}

