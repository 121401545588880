
// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const login = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("post","logins", data)
    console.log(res)
    commit('login', res);
    commit('userLoading', false);
}

export const register = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","register", data)
   commit('register', res);
   commit('userLoading', false);
}
export const listUser = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("get","user/getuserlist", data)
    commit('listUser', res);
    commit('userLoading', false);
 }

 export const userSetState = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("put","user/setuseractivestatus", data)
    commit('userLoading', false);
 }
 export const userResetUid = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("delete","user/resetuseruid", data)
    commit('userLoading', false);
 }
 export const userResetPwd = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("put","user/changepasstodefault", data)
    commit('userLoading', false);
 }
 export const deleteUser = async ({ commit }, data) => {
    commit('userLoading', true);
    let res;
    res = await sendReq("delete","user/deleteappuser", data)
    commit('userLoading', false);
 }
 export const listDeviceUser = async ({ commit }, data) => {
    commit('panelLoading', true);
    let res;
    res = await sendReq("get","command/devices/userlistofdevice/0/"+data,data)
    commit('listUser', res);
    commit('panelLoading', false);
}
export const changeJWT = async ({ commit }, data) => {
   let res;
   res = await sendReq("get","changed-jwt")
   commit('JWT', res);
}

export const verifyUser = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","accept-verification", data)
   commit('userStatus', res);
   commit('userLoading', false);
}

export const forgotPwd = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","forget-password", data)
   commit('userStatus', res);
   commit('userLoading', false);
}

export const reSendCode = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","send-verification", data)
   commit('userStatus', res);
   commit('userLoading', false);
}

export const getAuthPlan = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("get","user-authority", data)
   commit('userAuthPlan', res.data);
   commit('userLoading', false);
}

export const getUserDetail = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("get","/user/getuserdetail", data)
   commit('userDetail', res.data);
   commit('userLoading', false);
}

export const userUpdate = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("put","/user/updateregister", data)
   commit('userLoading', false);
}

export const verifyResetLogin = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","/verify-reset-uid", data)
   commit('userStatus', res);
   commit('userLoading', false);
}


export const resetLogin = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","/reset-phone-uid", data)
   commit('userStatus', res);
   commit('userLoading', false);
}

export const changePwd = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("post","/user/changepass", data)
   commit('userStatus', res);
   commit('userLoading', false);
}

export const getSystemSummary = async ({ commit }, data) => {
   commit('userLoading', true);
   let res;
   res = await sendReq("get","/reports/general")
   commit('reports', res);
   commit('userLoading', false);
}