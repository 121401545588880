<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="users" :filtered="filtered" type="List" :loading="loading" :actions="actions" :actionEvent="actionEvent"  />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    import jwt_decode from 'jwt-decode'
    
    let breadCrumb = [
        { label: i18n.t('panelManage.title'), url: "/PanelManage" },
        { label: 'Kullanıcılar', url: "#" },

    ]
     let fields = [ 
         { key: 'desc', label: i18n.t('panelManage.desc'), keys:['Upaneldesc']},
         { key: 'panel_active', label: i18n.t('panelManage.panelState'), keys:['Active']},
         { key: 'name', label: i18n.t('user.name'), keys:['User','name']},
         { key: 'email', label: i18n.t('user.mail'), keys:['User','email'] },
         { key: 'Phonenumber', label: i18n.t('user.phone'), keys:['User','Phonenumber'] },
         { key: 'Dealercode', label: i18n.t('user.dealer'), keys:['User','Dealercode'] },
         { key: 'user_type', label: i18n.t('panelManage.userType'), keys:['User','user_type'] },
         { key: 'active', label: i18n.t('panelManage.userState'), keys:['User','active'] },
         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let actions = [
        {key: 'active', text: i18n.t('panelManage.activeUser'), userstate: true},
        {key: 'passive', text: i18n.t('panelManage.passiveUser'), userstate: false},
        {key: 'panelActive', text: i18n.t('panelManage.activePanel'), panelstate: true},
        {key: 'panelPassive', text: i18n.t('panelManage.passivePanel'), panelstate: false},
        {key: 'mobileReset', text: i18n.t('panelManage.mobileReset')},
        {key: 'pwdReset', text: i18n.t('panelManage.pwdReset')},
    ]

    let filtered = ['desc','panel_active', 'name', "email", 'Phonenumber', 'Dealercode',  'user_type', 'active', 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listDeviceUser', this.$route.params.dev_id)
             if(jwt_decode(localStorage.getItem('access_token')).OldUserId > 0 ){
                this.actions.splice(4,1)
            }

        },
        computed:{
            users(){
                return this.$store.getters.getAuthUsers.data
            },
        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"User", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
            
           
            actionEvent: async function(key, data){
                console.log(data, key)
                switch(key){
                    case 'active':
                        this.openModal(true, 'SetState', {user: data, state:true})
                        break;
                    case 'passive': 
                        this.openModal(true, 'SetState', {user: data, state:false})
                        break;
                    case 'panelActive':
                        this.openModal(true, 'SetStatePanel', {panel: data, state:true})
                        break;
                    case 'panelPassive': 
                        this.openModal(true, 'SetStatePanel', {panel: data, state:false})
                        break;
                    case 'pwdReset': 
                        this.openModal(true, 'PwdReset', {user: data})
                        break;
                    case 'mobileReset': 
                        this.openModal(true, 'MblReset', {user: data})
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  {user: data})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>