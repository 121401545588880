<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-card style="height: 700px;">
            <template v-slot:header>
                 <div class="headerLeft" >
                   <h4> {{info.test.model_name+" - "+info.test.firmware}}</h4>                   
                 </div>
                  <div class="headerRight">
                   <h4> {{info.test.user_name}}</h4>                   
                   
                 </div>
            </template>
            <template v-slot:default style="overflow: scroll">
                <b-table-simple hover responsive style="min-height:0px !important;">
                    <b-th> Sorumlu </b-th>
                    <b-th> Açıklama </b-th>
                    <b-th> Tarih </b-th>
                    <b-th> Sonuç </b-th>
                    <b-th> İşlemler </b-th>
                    <b-tbody>
                        <b-tr v-for=" res in results" :key="res.id"> 
                            <b-td> {{res.user_name}} </b-td>
                            <b-td> <strong> {{res.description}} </strong> </b-td>
                            <b-td> {{moment(res.add_time)}} </b-td>               
                            <b-td> 
                                <b-icon v-if="res.result" icon="check" variant="success"  scale="1.25" shift-v="1.25"  aria-hidden="true" />
                                <b-icon v-else icon="x" variant="danger"  scale="1.25" shift-v="1.25"  aria-hidden="true" />
                                {{res.result_description}}
                            </b-td>                        
                            <b-td style="font-size:32px !important;"> 
                                <b-icon icon="check" class="border border-success rounded" variant="success" style="cursor: pointer; margin: .5rem;" title="Çalışıyor"  @click="resultOk(res)"/>
                                <b-icon icon="x" class="border border-danger rounded " variant="danger" style="cursor: pointer; margin: .5rem;" title="Hata Var" @click="openModal(true, 'Error', res)"></b-icon>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
            </template>
             
        </b-card>
       
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import Modals from "./modals/";
    import moment from 'moment'
    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
    ]

    export default {
        name: 'UserList',
         created: async function(){
             console.log(this.$route.params)
            await this.$store.dispatch('listFirmwareTestResult', {id: this.$route.params.id})
        },
        computed:{
           results(){
                return this.$store.getters.getFirmwareResults.data
            },
            info(){
                return this.$route.params
            },
           /* pageInfo(){
                return this.$store.getters.getUserPageInfo
            }*/

        },
        data: function(){
            return{
                breadCrumb, modalOpen: false, modalProps: {}, currentForm:"Test", modalName: "", 
               

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },
     

        methods:{
             moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY HH:mm:ss');
            },
            resultOk: async function(data){
                 let params = {
                        id: data.id,
                        description: "",
                        result: true
                    }
                    await this.$store.dispatch('editTestResult', params)
                    await this.$store.dispatch('listFirmwareTestResult', {id: this.$route.params.id})
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>
<style lang="css" scoped > 
    @import "./styles/test.min.css"; 
</style>