<template>
    <div>
        <validation-observer  ref="remoteServer">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <b-form-group class="inputGrp" :label="$t('program.remoteServer.remote')" label-for="cmsConnect">
                    <b-form-radio-group
                        id="cmsConnect"
                        v-model="enabled"
                        :options="cmsConnects"
                        aria-describedby="live-feedback"></b-form-radio-group>
                </b-form-group>      

                <b-form-group class="inputGrp" :label="$t('program.remoteServer.connectionType')" label-for="type">
                    <b-form-radio-group
                        id="type"
                        v-model="connectType"
                        :options="types"
                        aria-describedby="live-feedback"></b-form-radio-group>
                </b-form-group>
                <validation-provider v-if="connectType == false" :name="$t('program.remoteServer.ip')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.remoteServer.ip')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="IP" :placeholder="$t('program.remoteServer.ip')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider  :name="$t('program.remoteServer.port')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.remoteServer.port')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="Port" :placeholder="$t('program.remoteServer.port')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider  :name="$t('program.remoteServer.period')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.remoteServer.period')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="Ping" :placeholder="$t('program.remoteServer.period')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>    
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                connectType: false,
                cmsNo:  "",
                IP:  "",
                URL: "",
                Port: "",
                Ping: "",
                enabled: false,
              
                types: [
                    { text: i18n.t('program.general.ip'), value: false },
                    { text: i18n.t('program.general.url'), value: true },
                ],
                cmsConnects: [
                    { text: i18n.t('program.general.passive'), value: false },
                    { text: i18n.t('program.general.active'), value: true },
                ],
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.yes'), value: true },
                ],
            }
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        watch: { 
            settings: function(newVal, oldVal) { // watch it                   
                    this.IP = newVal.ip;
                    //this.URL = newVal.url;
                    this.Port = newVal.port;
                    this.Ping = newVal.ping_period
                    this.enabled = newVal.enable
                    this.connectType = newVal.use_domain
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.remoteServer.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 13,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                id: this.data.id,
                                ip: this.IP,
                                port: parseInt(this.Port),
                                enable: this.enabled,
                                use_domain: this.connectType,
                                ping_period: parseInt(this.Ping),
                            }
                            await this.$store.dispatch('setRemoteSettings', params)
                        }else{
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                     
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>