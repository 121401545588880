<template>
    <div >
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

        <b-table-simple v-else hover responsive>
                <b-th> {{$t('program.outs.no')}} </b-th>
                <b-th> {{$t('program.outs.name')}} </b-th>
                <b-th> {{$t('program.outs.state')}} </b-th>
                <b-th> {{$t('general.edit')}} </b-th>
            <b-tbody >
                <b-tr v-for="out in outs" :key="out.id" :style="!out.active && 'background: #343a40; color: #fff'"> 
                   <b-td> {{ out.out_no+1}} </b-td>
                   <b-td > {{ out.name}}  </b-td>
                   <b-td> <b-form-checkbox v-model="out.out_status" name="check-button" switch size="lg" @change="sendOuts(out)"/> </b-td>
                    <b-td> <b-link style="color: #007bff" @click="edit(out)" v-b-modal.editOut> {{$t('general.edit')}}  </b-link></b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-modal id="editOut" :title="selectedOut.out_no+' - '+selectedOut.name+' '+$t('general.edit')" :ok-title="$t('general.edit')" :cancel-title="$t('general.cancel')" @ok="editOut">
             <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="name"> {{$t('program.outs.name')}} </label> </b-col>
                    <b-col sm="8"> 
                        <b-input :placeholder="$t('program.outs.name')" v-model="selectedOut.name"  />
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="name"> {{$t('program.outs.state')}} </label> </b-col>
                    <b-col sm="8"> 
                        <b-form-radio-group
                        id="state"
                        v-model="selectedOut.active"
                        :options="states"
                        aria-describedby="live-feedback"></b-form-radio-group>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
    import i18n from '@/plugins/i18n';

    export default {
        name: "Zones",
        data: function(){
            return{
                
                selectedOut: {},
                states: [
                    { text: i18n.t('program.general.active'), value: true},
                    { text: i18n.t('program.general.passive'), value: false},
                ],
            }
        },
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        computed:{
            outs(){
                return this.$store.getters.getOuts
            },
             loading(){
                return this.$store.getters.getPanelSettingLoading
            },
        },
        watch: {
            save: async function(data){
                this.saveState(false)
            }
        },
         methods:{
            sendOuts: async function(data){
                let params = {
                    dev_id: parseInt(this.data.id),
                    command: 'OUTPUT',
                    zoneoutno: data.out_no,
                    status: data.out_status
                }
                await this.$store.dispatch('panelCommand', params)
                if(this.$store.getters.getCommand.Err !== 0){
                    data.out_status = !data.out_status
                }
            },
             edit: function(out){
                this.selectedOut = out
            },
            editOut: async function(){
                let data = {
                    device_id: parseInt(this.selectedOut.dev_id),
                    out_id: parseInt(this.selectedOut.id),
                    active: this.selectedOut.active,
                    name: this.selectedOut.name
                }
                await this.$store.dispatch('sendOut', data)
                this.$store.dispatch('panelOuts', this.data)


            }
        }
        
    }
</script>