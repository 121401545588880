<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="panels" :filtered="filtered" type="List" :loading="loading" :limit="limit" :setLimit="setLimit" :search="search"
        :page="page" :setPage="setPage" :pageData="pageInfo" :actions="actions" :actionEvent="actionEvent" buttonName="Panel Sıfırla"  :buttonEvent="panelreset" />

        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    import jwt_decode from "jwt-decode";

    let breadCrumb = [
        { label: i18n.t('menus.panels'), url: "/Panels" },
        { label: i18n.t('panelManage.title'), url: "/PanelManage" },
    ]
     let fields = [ 
         { key: 'dev_id', label: i18n.t('panelManage.imeiMac'), keys:['device_info','dev_id']},
         { key: 'description', label: i18n.t('panelManage.desc'), keys:['description'] },
         { key: 'firm_ver', label: i18n.t('panelManage.version'), keys:['device_info','firm_ver']},
         { key: 'model', label: i18n.t('panelManage.model'), keys:['device_info','model'] },
         { key: 'add_time', type: 'date', label: i18n.t('panelManage.addTime'), keys:['add_time'] },
         { key: 'last_event_time', type: 'date', label: i18n.t('panelManage.lastEvent'), keys:['device_info','last_event_time'] },
         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let actions = [
        {key: 'users', text: i18n.t('panelManage.panelsUser')},
        {key: 'copy', text: i18n.t('panelManage.panelCopy')},


    ]

    let filtered = ['firm_ver', 'dev_id', "description", 'model', 'add_time',  'last_event_time', 'actions']; 

    export default {
        name: 'PanelList',
         created: async function(){
           await this.$store.dispatch('allPanels', this.params)

        },
        computed:{
            pageInfo(){
                return this.$store.getters.getPanelPageInfo
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            panels(){
                return this.$store.getters.getAllPanels
            },

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"PanelManage", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },
        watch:{
            limit(val){
                this.params['limit'] = val
                this.$store.dispatch('allPanels', this.params)
            },
            offset(val){
                 this.params['offset'] = val
                 this.$store.dispatch('allPanels', this.params)
            },
            searchVal(val){
                 val = val.replace(/[@!^&\/\\#,+()$~%.'":*?<>{}]/g, '')
                 this.params['search'] = val
                 this.$store.dispatch('allPanels', this.params)
            }
        },

        methods:{
            complete(){
                this.$store.dispatch('allPanels', this.params)
            },
            search(s){
                this.searchVal = s
            },

            setLimit:function(limit){
                this.limit = limit
            },
            setPage:function(page){
                if(page > 0){
                    this.page = page
                    this.offset = ((page-1) * this.limit)
                }
            },
            panelreset: function(){
                this.openModal(true, 'ResetPanel')
            },
            actionEvent: async function(key, data){
                console.log(data, key)
                switch(key){
                     case 'active':
                        this.openModal(true, 'SetState', {panel: data, state:true})
                        break;
                    case 'passive': 
                        this.openModal(true, 'SetState', {panel: data, state:false})
                        break;
                    case 'users':
                        this.$router.push( { path: '/Panels/'+data.device_info.id+'/Users' })
                        break;
                    case 'copy':
                        this.openModal(true, 'CopyPanel', {panel: data})

                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>