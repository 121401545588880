<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap;"> 
                <b-card  v-if="getRole() > 0 && type == 'code'" style="width: 500px; margin-bottom: 1rem;" no-body >
                    <b-card-body style='height: auto !important; margin: 1rem 0; overflow: hidden;'>
                        <h4>
                            {{payment.title + ' - ' + price.price + ' '+ price.currency +' / '+ price.title}}
                        </h4>
                         <validation-observer  ref="payment">
                            <b-form  class="formBox">
                                <validation-provider name="Adet" :rules="{integer}"  v-slot="validationContext">
                                    <b-form-group class="inputGrp">
                                        <b-row>
                                            <b-col sm="4"> <label for="name"> {{$t('payment.count')}} </label> </b-col>
                                            <b-col sm="8"> 
                                                <b-form-input id="name" v-model="count" :placeholder="$t('payment.count')" :state="getValidationState(validationContext)" />
                                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </validation-provider>
                                <span style='width:100%'>
                                    <b-row > 
                                        <b-col sm="6"> <label for="name"> {{$t('payment.discount')}} <b-badge variant="success">%{{price.discount}}</b-badge></label> </b-col>
                                        <b-col sm="6" style="display: block; width: 100%"> 
                                            <span style="font-size: 18px; font-weight: bold"> {{(count * parseFloat(price.price)*price.discount/100).toFixed(2)+ price.currency}} </span>
                                        </b-col>
                                    </b-row>
                                </span>
                                <span style='width:100%'>
                                    <b-row > 
                                        <b-col sm="6"> <label for="name"> {{$t('payment.price')}} </label> </b-col>
                                        <b-col sm="6" style="display: block; width: 100%"> 
                                            <span style="text-decoration: line-through;"> {{(count * parseFloat(price.price)).toFixed(2) + price.currency}} </span>
                                            <span style="font-size: 18px; font-weight: bold"> {{((count * parseFloat(price.price)) - (count * parseFloat(price.price)*price.discount/100)).toFixed(2)+ price.currency}} </span>
                                        </b-col>
                                    </b-row>
                                </span>
                            </b-form>
                        </validation-observer>
                         <b-button size="md" variant="primary" @click="createOrder()"> Siparişi Hazırla </b-button>  
                    </b-card-body>
                </b-card>
                 <b-card style="width: 500px; margin-bottom: 1rem;" no-body  v-if="pay">
                    <b-card-body style='height: auto !important; margin: 1rem 0; overflow: hidden;'>
                        <h4> Sipariş Özeti </h4>
                        <b-row > 
                            <b-col sm="6"> <label for="name"> {{$t('payment.order')}}: </label>  </b-col>
                            <b-col sm="6" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold"> #{{codeRes.merchant_no}} </span>
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="6"> <label for="name"> {{$t('payment.dealer')}}: </label>  </b-col>
                            <b-col sm="6" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold"> {{userDetail.dealercode}} </span>
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="6"> <label for="name"> {{$t('general.phone')}}: </label>  </b-col>
                            <b-col sm="6" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold"> {{userDetail.phone_no}} </span>
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="6"> <label for="name"> {{$t('payment.usager')}}: </label>  </b-col>
                            <b-col sm="6" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold"> {{codeRes.email}} </span>
                            </b-col>
                        </b-row>
                        <b-row > 
                            <b-col sm="6"> <label for="name"> {{$t('payment.price')}}: </label>  </b-col>
                            <b-col sm="6" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold"> {{codeRes.price +' '+ codeRes.currency + ' / '+ codeRes.title}} </span>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                 <b-card style=" width: 600px; margin-bottom: 1rem;" no-body  v-if="pay">
                    <b-card-body style='height: auto !important; margin: 1rem 0; overflow: hidden;'>
                        <h4> {{$t('payment.buyerInfo')}} </h4>
                        <b-row > 
                            <b-col sm="3"> <label for="name"> {{$t('payment.buyerAddress')}}: </label>  </b-col>
                            <b-col sm="8" style="display: block; width: 100%"> 
                                <span style="font-size: 18px; font-weight: bold">
                                    <b-textarea rows="7" v-model="address"></b-textarea> 
                                </span>
                            </b-col>
                            <b-col sm="1"></b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card no-body style="width: 100%;" v-if="pay">
                    <b-card-body style='height: auto !important; margin: 1rem 0; overflow: hidden;'>
                       <VCreditCard direction='row' :trans="translations"  @change="creditInfoChanged" />
                        <b-button size="md" variant="primary" @click="sendPayment(userDetail)"> Ödeme Yap </b-button>  
                    </b-card-body>
                </b-card>
            </div>
          
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import jwt_decode from "jwt-decode";
    import VCreditCard from 'v-credit-card';
    import 'v-credit-card/dist/VCreditCard.css';
    import  CryptoJS from 'crypto-js'
    import axios from "axios";

    const publicIp = require('public-ip');
    let breadCrumb = [
        { label: i18n.t('payment.subs'), url: "/Payment/List" },
        { label: i18n.t('payment.pay'), url: "/Payment/Payment" },
    ]

    export default {
        name: 'Payment',
         created: async function(){
            await this.$store.dispatch('paymentInfo',this.$route.params.id)
            await this.$store.dispatch('paymentSettings')
            await this.$store.dispatch('getUserDetail')
            let data = {
                payment_plan_id: parseInt(this.$route.params.id),
                payment_plan_price_id: parseInt(this.$route.params.price)
            }
            await this.$store.dispatch('orders',data)
            if(this.$route.params.type == 'code')
                this.pay = false;
            else 
                this.pay = true;

    },
        computed:{
            payment(){
                return this.$store.getters.getPaymentInfo
            },
            settings(){
                return this.$store.getters.getPaymentSettings
            },
            codeRes(){
                return this.$store.getters.getCouponReqInfo
            },
            loading(){
                return this.$store.getters.getPaymentLoading
            },
            type(){
                return this.$route.params.type
            },
            price(){
                return this.$store.getters.getPaymentInfo.prices.find(e => e.id == parseInt(this.$route.params.price))
            },
            userDetail(){
                return this.$store.getters.getUserDetail
            },

        },
        data: function(){
            return{
                breadCrumb, count: 1, pay: false, address: '', card:{
                    name: '',
                    cardNumber: '',
                    expiration: '',
                    security: ''
                }, translations: {
                    name: {
                        label: i18n.t('payment.name'),
                        placeholder: i18n.t('payment.nameInput')
                    },
                    card: {
                        label:  i18n.t('payment.card'),
                        placeholder:  i18n.t('payment.cardInput')
                    },
                    expiration: {
                        label:  i18n.t('payment.expration')
                    },
                    security: {
                        label:  i18n.t('payment.cvc'),
                        placeholder:  i18n.t('payment.cvcInput')
                    }
                }
            }
        },
        components:{
            BreadCrumb, VCreditCard
        },
        methods:{
            getRole: function(){
                return jwt_decode(localStorage.getItem('access_token')).UserType
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            createOrder: async function(){
                let data = {
                    payment_plan_id: parseInt(this.$route.params.id),
                    payment_plan_price_id: parseInt(this.$route.params.price),
                    amount: parseInt(this.count)
                }
                await this.$store.dispatch('codeRequest',data)
                this.pay = true;
            },
            creditInfoChanged(values) {
                for (const key in values) {
                    this.card[key] = values[key];
                }
            },
            sendPayment: async  function(userDetail){
                let user_ip = await publicIp.v4();
              let email = this.codeRes.email //jwtDecode(token).Username;
              let merchant_id = this.codeRes.payment_settings.merchant_id //DB den Gelecek
              let merchant_oid =  this.codeRes.merchant_no//DB den Gelecek
              let payment_amount = this.codeRes.price //Kullanıcı için iskontoyu yok et!!!!
              let payment_type = 'card'
              let installment_count =  0
              let test_mode = 0  
              let currency = this.codeRes.currency  //DB den gelecek
              let non3D = "0"  
              let merchantSalt = this.codeRes.payment_settings.merchant_salt //DB den gelecek
              let merchantKey = this.codeRes.payment_settings.merchant_key //DB den gelecek
              let okURL = this.codeRes.payment_settings.ok_url  //DB den gelecek
              let failURL = this.codeRes.payment_settings.fail_url //DB den gelecek
              let basketItem = JSON.stringify([
                    [ userDetail.dealercode+' Alarm Panelim Mobil Uygulama Kullanımı '+this.codeRes.price + this.codeRes.currency+' / '+ this.codeRes.title, this.codeRes.price, 1], // 1. ürün (Ürün Ad - Birim Fiyat - Adet)
                ]);//this.codeRes.title //DB den gelecek
              let paymentURL = this.codeRes.payment_settings.pay_url //DB den gelecek
            let hash_str =merchant_id+user_ip+merchant_oid+email+payment_amount+payment_type+installment_count+currency+test_mode+non3D+merchantSalt;
            var hash = CryptoJS.HmacSHA256(hash_str, merchantKey)
            var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
            let cardNo = this.card.cardNumber.split(' ').join('')
           
               let formInput = {
                   paytr_token: hashInBase64,
                   cc_owner: this.card.name,
                   card_number: this.card.cardNumber.split(' ').join(''),
                   expiry_month: this.card.expiration.split("/")[0],
                   expiry_year: this.card.expiration.split("/")[1],
                   cvv: this.card.security,
                   merchant_id: merchant_id,
                   user_ip: user_ip,
                   merchant_oid: merchant_oid,
                   email: email,
                   payment_type: payment_type,
                   payment_amount: payment_amount,
                   installment_count: installment_count,
                   currency: currency,
                   non_3d: 0,
                   merchant_ok_url: okURL,
                   merchant_fail_url: failURL,
                   user_name: this.$store.getters.getUserDetail.name,
                   user_address: this.address,
                   user_phone: this.$store.getters.getUserDetail.phone_no,
                   user_basket: basketItem,
                   test_mode: test_mode,
                   debug_on: 0
               }
               var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", paymentURL);
                form.setAttribute("target", "Map");
                for (var i in formInput)
                {
                    if (formInput.hasOwnProperty(i))
                    {
                        var input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = i;
                        input.value = formInput[i];
                        form.appendChild(input);
                    }
                }
                document.body.appendChild(form);
                let map = window.open("", "Map", "dependant=no, location=0, alwaysRaised=no,resizable=0, menubar=no,toolbar=no, status=no,title=0,height=600,width=800,scrollbars=1");
                if (map) {
                    form.submit();
                } else {
                    alert('You must allow popups for this map to work.');
                }
                map.focus();
                let popupTick = setInterval( async () => {
                    if (map && map.closed) {
                        clearInterval(popupTick);
                        if(this.$route.params.type == 'code')
                            this.$router.push({path: '/Payment/Coupons'})
                        else{
                            await this.$store.dispatch('changeJWT')
                            this.$router.push({path: '/'})
                        }
                    }
                }, 500);
            }
        }
    }
</script>