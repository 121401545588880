<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-table-simple v-else hover responsive>
            <b-th> {{$t('program.general.nu')}} </b-th>
            <b-th> {{$t('general.name')}} </b-th>
            <b-th> {{$t('program.general.program')}} </b-th>
            <b-tbody >
                <b-tr v-for="item in [0,1,2,3,4,5,6,7]" :key="item.id"> 
                    <b-td> {{item+1}} </b-td>
                    <b-td > {{item+1 +". "+$t('program.general.program')}}  </b-td>
                    <b-td> <b-button size="sm" variant="info" @click="modalEvent(true,'program',{Description: item+'. '+$t('program.general.program'), Code: item})">{{$t('general.edit')}} </b-button>  </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
     
</template>

<script>
    import Modals from "../modals/"
   
    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                 yesNo: [
                    { text: $t('program.general.no'), value: false },
                    { text: $t('program.general.yes'), value: true },
                ],
            }
        },       
        props:{
            data: Array,
            loading: Boolean,
            modalEvent: Function
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>