<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
         <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.keypads.no')}} </b-th>
                <b-th> {{$t('program.keypads.name')}} </b-th>
                <b-th> {{$t('program.keypads.temp')}} </b-th>
                <b-th> {{$t('program.general.program')}} </b-th>
            </b-thead>
            <b-tbody >
            <b-tr v-for="item in data" :key="item.id"> 
                    <b-td> {{item.keypad_no+1}} </b-td>
                    <b-td > {{item.name}}  </b-td>
                    <b-td > {{item.temp}}  </b-td>
                    <b-td> <b-button size="sm" variant="info" @click="modalEvent(true,'program',item)">{{$t('general.edit')}} </b-button>  </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
   
   
</template>

<script>
    import Modals from "../modals/"

    export default {
        name: 'ProsecPanel',

       
        props:{
            data: Array,
            loading: Boolean,
            modalEvent: Function
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>