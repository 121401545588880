// Actiondan gelen datalar ile stateler güncellenecek
export const fwLoading = (state, payload) => {
    state.loading = payload
}

export const listFirmwares = (state, payload) => {
    if(payload.data)
        state.firmwares = payload.data
    else
        state.firmwares = payload
}

export const listModels = (state, payload) => {
    if(payload.data)
        state.models = payload.data
    else
        state.models = payload
}

export const listFirmwareTest = (state, payload) => {
    if(payload.data)
        state.firmwaresTest= payload.data
    else
        state.firmwaresTest = payload
}
export const listFirmwareStage = (state, payload) => {
    if(payload.data)
        state.firmwareStages= payload.data
    else
        state.firmwareStages = payload
}
export const status = (state, payload) => {
    state.status = payload.data
}
export const listFirmwareResult = (state, payload) => {
    if(payload.data)
        state.results= payload.data
    else
        state.results = payload
}
