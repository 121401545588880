import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    payments : [],
    paymentInfo : {},
    paymentSettings : {},
    couponReqInfo: {},
    couponReqs: [],
    couponList: [],
    couponHistory: [], 
    paymentStatus: {},
    orders: [],  
    orderPageInfo: {},
    paymentReportInfo: {},
    paymentReport: []
})

export default {
    state,
    getters,
    actions,
    mutations
}