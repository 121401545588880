<template>
    <div>
        <div>
            <b-row style="margin: 1rem">
                <b-col sm="4"> {{$t('program.reader.start')}}</b-col>
                <b-col sm="8">
                    <Datetime v-model="beginDate" type="date"></Datetime>
                </b-col>
            </b-row>
            <b-row style="margin: 1rem">
                <b-col sm="4"> {{$t('program.reader.end')}}</b-col>
                <b-col sm="8">
                    <Datetime v-model="endDate" type="date"></Datetime>
                </b-col>
            </b-row>
            <b-button variant="primary" style="margin: 1rem" @click="changeDate"> {{$t('program.reader.report')}} </b-button>
            <b-button variant="warning" style="margin: 1rem" @click="pdksRapor"> {{$t('program.reader.excel')}} </b-button>
        </div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.reader.name')}} </b-th>
                <b-th> {{$t('program.reader.date')}} </b-th>
                <b-th> {{$t('program.reader.keypad_name')}} </b-th>
                <b-th> {{$t('program.reader.keypad_type')}} </b-th>

            </b-thead>
            <b-tbody >
                <b-tr v-for="item in data" :key="item.id" > 
                   <b-td> {{item.name}} </b-td>
                    <b-td > {{moment(item.date)}} </b-td>
                   <b-td> {{item.keypad_name}} </b-td>
                   <b-td> {{item.keypad_type}} </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>

</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import moment from 'moment'
    export default {
        name: 'ProsecPanel',
        data: () =>{
            return {
                beginDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            }
        },
        methods:{
            changeDate: async function(){
                let params = {
                    begin_date: moment(this.beginDate).format('YYYY-MM-DD'),
                    end_date: moment(this.endDate).format('YYYY-MM-DD'),
                    id: this.$route.params.id
                }
                 await  this.$store.dispatch('panelReader', params)
            },
            moment: function(date){
                return moment(date.slice(0, -1)).format('DD MMMM YYYY HH:mm')
            }, 
            pdksRapor: async function() {        
                let params = { 
                    id: this.$route.params.id,
                    begin_date: moment(this.beginDate).format('YYYY-MM-DD'),
                    end_date: moment(this.endDate).format('YYYY-MM-DD'),
                  
                }
                params["responseType"]="arraybuffer"
                await this.$store.dispatch('panelInOutReport', params) 
                if(this.$store.getters.pdksExcel){
                    let url = window.URL.createObjectURL(new Blob([this.$store.getters.pdksExcel]))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', moment(this.beginDate).format('DD.MM.YYYY')+'-'+moment(this.endDate).format('DD.MM.YYYY')+' Report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }else{
                    this.$bvToast.toast('Rapor Oluşturulamadı. Filtrelemelerinizin doğru olduğundan emin olun lütfen', {
                        title: `Başarılı! `,
                        variant: 'danger',
                        solid: true
                    })
                }
            }
        },
        props:{
            data: Array,
            loading: Boolean,
            openModal: Function,
            auth: Object
        },
        components:{
            Modals,Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>