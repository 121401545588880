<template>  
    <div class="editForm">
    
        <validation-observer ref="edit" >
            <b-form class="formBox">
                <validation-provider :name="$t('user.name')" :rules="{min:3}"  v-slot="validationContext">
                    <b-form-group class="editGrp" >
                        <b-row>
                            <b-col cols="4" class="col">
                                <span class="label"> {{$t('user.name')}} </span>
                            </b-col>
                            <b-col cols="8">
                                 <b-input-group class="editGrp" >
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="person-fill"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input id="name" v-model="name" type="text" required :placeholder="$t('user.name')" :state="getValidationState(validationContext)"></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('user.mail')" :rules="{email, required}"  v-slot="validationContext">
                    <b-form-group class="editGrp">
                        <b-row>
                            <b-col cols="4" class="col">
                                <span class="label"> {{$t('user.mail')}} </span>
                            </b-col>
                            <b-col cols="8" >
                                 <b-input-group class="editGrp">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="at"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input id="mail" v-model="mail" type="text" required :placeholder="$t('user.mail')" :state="getValidationState(validationContext)"></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%; margin-top:0">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('user.phone')" :rules="{required}" >
                    <b-form-group class="editGrp">
                        <b-row>
                            <b-col cols="4" class="col">
                                <span class="label"> {{$t('user.phone')}} </span>
                            </b-col>
                            <b-col cols="8" >
                                <b-input-group class="editGrp">
                                    <vue-tel-input 
                                        style="width:100%"
                                        v-model="phone" 
                                        :validCharactersOnly="true" 
                                        @input="phoneValid" 
                                        defaultCountry="tr" 
                                        :disabled="true"
                                    ></vue-tel-input>
                                
                                </b-input-group>
                                 <b-form-invalid-feedback v-if="!phoneValidVal.valid" id="live-feedback" style="display: block; width: 100%">
                                    {{$t('user.noValidPhone')}}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('user.dealer')" >
                    <b-form-group class="editGrp">
                        <b-row>
                            <b-col cols="4" class="col">
                                <span class="label"> {{$t('user.dealer')}} </span>
                            </b-col>
                            <b-col cols="8" >
                                <b-input-group class="editGrp">
                                    <b-input-group-prepend is-text>
                                        <b-icon icon="tools"></b-icon>
                                    </b-input-group-prepend>
                                    <b-form-input id="dealer" v-model="dealer" type="text" :placeholder="$t('user.dealer')" :disabled="true"></b-form-input>
                                </b-input-group>
                            </b-col>
                            
                        </b-row>
                    </b-form-group>
                </validation-provider>
                
            </b-form>
    </validation-observer>
    </div>
</template>

<script>

    export default {
        name: "LoginForm",
        data: function(){
            return{
                name: this.data.name,
                mail: this.data.email,
                phone: this.data.phone_no,
                dealer: this.data.dealercode,
                arm: this.auth && this.auth.ARM,
                phoneValidVal: {},

            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            phoneValid: function(val, obj){
                this.phoneValidVal = obj
                this.phone = obj.number
            },
        },
        props:{
            data: Object,
            auth: Object
        }
    }
</script>
<style lang="css" scoped > 
    @import "../styles/edit.min.css"; 
</style>