
// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/yandexReq'

export const yandexGetList = async ({ commit }, data) => {
    commit('yandexLoading', true);
    let res;
    res = await sendReq("get","/resources?path="+data.path)
    commit('yandexList', res.data);
    commit('yandexLoading', false);
 }
 export const yandexGetFiles = async ({ commit }, data) => {
   commit('yandexLoading', true);
   let res;
   res = await sendReq("get","/resources?path="+data.path)
   commit('yandexFiles', res.data);
   commit('yandexLoading', false);
}
export const yandexDownload = async ({ commit }, data) => {

   let res;
   res = await sendReq("get","/resources/download?path="+data.path)
   commit('yandexDownload', res.data);
}
