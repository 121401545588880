import axios from "axios";
import Vue from "vue";
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER
});
axiosInstance.interceptors.request.use(config => {
    //Tabiki burada token değerini local yada session storage gibi bir yerden okumamız gerekebilir
    config.headers["Authorization"] = "token "+localStorage.getItem('access_token');
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["lang"] = localStorage.getItem('lang')
    config.headers["agent"] = 'browser'
    config.headers["country"] = localStorage.getItem('lang') == 'tr' ? 'TRY' : 'USD';
    return config;
});

axiosInstance.interceptors.response.use(undefined, async function axiosRetryInceptors(err) {
     //Token Takibi
     const originalRequest = err.config
     console.log(err)
    if (err.response.status === 401) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        //console.log('Login Error: Refresh token a gitmeye çalıştım ama çalışmadı: '+res.data)
        //window.location.href= "/Login"

    }else if(err.response.status !== 200 && err.response.status !== 201 ){
        console.log(err)
        vm.$root.$bvToast.toast(err.response.data.message, {
            title: `Hata! `,
            variant: 'danger',
            noAutoHide: true
          })
          return err.config;
    }  else 
    return err.config;
});
export default axiosInstance
/**/
