<template>
    <div class="login">
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
        <b-container>
            <b-navbar toggleable="lg">
                <b-navbar-brand href="#"> <img src="@/assets/img/logo.png" class="logo" alt="Alarm Panelim"> </b-navbar-brand>
                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item @click="openModal(true, 'ResetLogin')"> Giriş Bilgisini Sıfırla </b-nav-item>
                        <b-nav-item @click="openModal(true, 'Verify')"> Hesabımı Onayla </b-nav-item>
                        <b-nav-item @click="changeForm(2)"> Kayıt Ol</b-nav-item>
                        <b-nav-item href="/Dealer/Create"> Bayilik Başvuru </b-nav-item>

                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
                <b-row class="content">
                    <b-col  class="image">
                        <img class="login-pic" src="@/assets/img/smart-home.png">
                    </b-col>
                    <b-col class="form">
                        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
                        <template v-else>
                            <LoginForm v-if="formType == 1" :onLogin="onLogin" :changeForm="changeForm" :openModal="openModal" />
                            <RegisterForm v-else-if="formType == 2" :onRegister="onRegister" :changeForm="changeForm" />
                        </template>
                    </b-col>
                </b-row>
                
        </b-container>
        <div class="copyright">
            <a href="https://sanatteknoloji.com.tr" target="_blank"> <img class="login-pic" src="@/assets/img/sanatLogo.png"> {{ $t('login.copyright')}} </a>
        </div>
    </div>

</template>

<style lang="css" scoped> 
    @import "./styles/login.min.css"; 
</style>
<script>
    import LoginForm from './components/LoginForm'
    import RegisterForm from './components/RegisterForm'
    import Modals from './modals'
    export default {
        name: "Login",
        data:function(){
            return{
                formType: 1, modalOpen: false, modalProps: {}, currentForm:"User", modalName: "",
            }
        },
        components:{
            LoginForm, RegisterForm, Modals
        },
        computed:{
            loading(){
                return this.$store.getters.getLoading
            },
            login(){
                return this.$store.getters.login
            },
            register(){
                return this.$store.getters.getRegister
            },
        },
        methods:{
            changeForm: function(data){
                this.formType = data
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
            onLogin: async function(data){
                await this.$store.dispatch('login', data)
                if(this.login.data){
                    localStorage.setItem('access_token',this.login.data.token)
                    window.location.href="/"
                }
            },
            onRegister: async function(data){
                await this.$store.dispatch('register', data)
                if(this.$store.state.User.register.status == "Success"){
                    this.openModal(true, 'Verify')
                }
            }
        }
    }
</script>
