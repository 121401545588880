<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
         <form v-else ref="form">
            <validation-observer  ref="alarmsPRG">
                <validation-provider :name="$t('program.alarms.time')" :rules="{ integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="warningTime"> {{$t('program.alarms.time')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-input id="warningTime" :placeholder="$t('program.alarms.time')" v-model="warningTime" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                  <b-form-group>
            <b-row>
                <b-col sm="4"> {{$t('program.alarms.siren')}}  </b-col>
                <b-col sm="8">
                     <b-form-radio-group
                        v-model="siren"
                        :options="yesNo"
                        :aria-describedby="ariaDescribedby"
                        name="siren"
                    />
                </b-col>
            </b-row>
        </b-form-group>

        <b-form-group>
            <b-row>
                <b-col sm="4"> {{$t('program.alarms.warningMsg')}}  </b-col>
                <b-col sm="8">
                     <b-form-radio-group
                        v-model="message"
                        :options="yesNo"
                        :aria-describedby="ariaDescribedby"
                        name="buzzer"
                    />
                </b-col>
            </b-row>
        </b-form-group>

         <b-form-group>
            <b-row>
                <b-col sm="4"> {{$t('program.alarms.warningBeep')}} </b-col>
                <b-col sm="8">
                     <b-form-radio-group
                        v-model="buzzer"
                        :options="yesNo"
                        :aria-describedby="ariaDescribedby"
                        name="message"
                    />
                </b-col>
            </b-row>
        </b-form-group>

         <b-form-group>
            <b-row>
                <b-col sm="4"> {{$t('program.alarms.lighting')}} </b-col>
                <b-col sm="8">
                     <b-form-radio-group
                        v-model="light"
                        :options="yesNo"
                        :aria-describedby="ariaDescribedby"
                        name="light"
                    />
                </b-col>
            </b-row>
        </b-form-group>
            </validation-observer>
    </form>
    </div>
   
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

  

    export default {
        name: "Zone",
        components:{
            Multiselect, 
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 5,
                member: parseInt(this.data.Code),
                user_pass: this.$store.getters.getPWD,
                offset: 0
            }
            await this.$store.dispatch('panelSettings', params)
        },
        data:function(){
           return{
             yesNo: [
                    { text:  i18n.t('program.general.no'), value: false},
                    { text:  i18n.t('program.general.yes'), value: true},
                ], warningTime: 0, 
           } 
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
          watch:{
            settings: function(newVal, oldVal) { // watch it
                this.warningTime = newVal.timeout
                this.siren = newVal.alarm_events.siren
                this.buzzer = newVal.alarm_events.keypad_buzzer
                this.message = newVal.alarm_events.keypad_message
                this.light = newVal.alarm_events.keypad_light
            },
            save: async function(data){
                if(data){
                     this.$refs.alarmsPRG.validate().then( async success =>{
                         if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 5,
                                member: this.data.Code,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                alarm_no: this.data.Code,
                                alarm_events:{
                                    siren: this.siren,
                                    keypad_buzzer: this.buzzer,
                                    keypad_message: this.message,
                                    keypad_light: this.light
                                },
                                timeout: parseInt(this.warningTime)
                            }
                            await this.$store.dispatch('setAlarmSettings', params)
                         }else{
                             vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                         }
                     })
                }
                this.saveState(false)
            }

          },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        
    }
</script>