<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table add="" :fields="fields" :items="orders" :filtered="filtered" :inputs="inputs" :search="search" type="List" 
            :loading="loading" :actions="actions" :actionEvent="actionEvent" :limit="limit" :setLimit="setLimit" :page="page" 
            :setPage="setPage" :pageData="pageInfo" buttonUrl="Payment/Report" buttonName="Rapor"/>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    let breadCrumb = [
        { label: i18n.t('payment.orderHistory'), url: "/Payment/CoupunHistory" },
    ]
     let fields = [ 
         { key: 'date', type:'date', label: i18n.t('payment.createDate'), keys:['created_at']},
         { key: 'month', label: i18n.t('payment.duration'), keys:['sub_month'] },
         { key: 'amount', label: i18n.t('payment.amount'), keys:['amount'] },
         { key: 'bill', type: 'badge', label: 'Abonelik Tipi', keys:['payment_plan','title'] },
         { key: 'price', type: 'price', label: i18n.t('payment.price'), keys:['price'] },
         { key: 'total_price', type: 'price', label: i18n.t('payment.total'), keys:['total_price'] },
         { key: 'user', type: 'user',label: 'Satın Alan Kullanıcı', keys:['user_info'] },
         { key: 'dealer',type: 'badge', label: 'Bayi Kodu', keys:['user_info', 'Dealercode'] },
         { key: 'subs', type:'date', label: 'Abonelik Bitişi', keys:['sub_expire_date']},
         { key: 'order_state', label: 'Sipariş Durumu', keys:['order_state']},
         { key: 'is_billing', label: 'Fatura Durumu', keys:['is_billing']},
         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let inputs = [ 
        { key: "is_billing", label: "Fatura Durumu", type: "Select",checked: false,val: "", array: [
            { text: "Faturalandırılan Siparişler", value: 2},
            { text: "Faturalandırılmayan Siparişler", value: 1},
            { text: "Hepsi", value: 0}
        ]},
        { key: "is_canceled", label: "İptal Durumu", type: "Select",checked: false,val: "", array: [
            { text: "İptal Edilen Siparişler", value: true},
            { text: "Aktif Siparişler", value: false}
        ]},
    ]; 

    let actions = [
        {key: 'results', text: 'Sipariş Sonuçları'},
        {key: 'billing', text: 'Faturalandır'},
        {key: 'cancel', text: 'İptal Et'},
       // {key: 'delete', text: 'Siparişi Sil', disabled: true},
    ]

    let filtered = ['date', 'bill', 'month', 'price', "user", 'dealer', 'subscription', 'order_state', 'is_billing','actions', 'amount', 'total_price']; 

    export default {
        name: 'PaymentList',
        watch: {
            limit(val) {
                this.params["limit"] = val;
                this.$store.dispatch("orderList", this.params);
            },
            offset(val) {
                this.params["offset"] = val;
                this.$store.dispatch("orderList", this.params);
            },
            
        },
         created: async function(){
            await this.$store.dispatch('orderList', this.params)
        },
        computed:{
            orders(){
                return this.$store.getters.getOrderList
            },
            loading(){
                return this.$store.getters.getPaymentLoading
            },
            pageInfo(){
                return this.$store.getters.getOrderPageInfo
            }

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"Coupon", modalName: "", searchVal:"", inputs,  params:{
                    limit:10,
                    offset: 0
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
            search(){
                this.inputs.map((input)=>{
                    input.checked ? this.params[input.key] = input.val : delete this.params[input.key]
                })
                console.log(this.params,  this.inputs)
                this.$store.dispatch('orderList', this.params)
            },
             setLimit: function (limit) {
                this.limit = limit;
            },
            setPage: function (page) {
                if (page > 0) {
                    this.page = page
                    this.offset = ((page-1) * this.limit)
                }
            },
            actionEvent: async function(key, data){
                this.openModal(true, key,  data)
                
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>