import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    yandexList: [],
    yandexFiles: [],
    yandexDownload: {}
})

export default {
    state,
    getters,
    actions,
    mutations
}