<template>
    <div>
        <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Rapor"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="auto"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <div class="reportHeader">
            <img src="../../../assets/img/sanat.png" class="logo" alt="SanatTeknoloji" width="300" style="padding: 1rem">
            <div class="info" style="width: 50%">
                <ul>
                    <li> <strong> Sanat Teknoloji </strong></li>
                    <li> <strong> Adres: </strong> Atakent Mah., Atakan Cad., Öncü Sok. 9/A, 34760 Ümraniye/İstanbul </li>
                    <li> <strong> Telefon: </strong> 0850 259 00 99 </li>
                    <li> <strong> E-posta: </strong> destek@sanatteknoloji.com.tr </li>
                    <li> <strong> Web Sitesi: </strong> <a target="_blank" href="https://sanatteknoloji.com.tr/"> sanatteknoloji.com.tr </a> </li>

                </ul>
            </div>
        </div>
        <div class="title"> {{data.version.model_name+ " - "+data.version.firmware}}</div>
        <div class="info">
            <ul>
                <li> <strong> Testi Yapan: </strong> {{data.version.user_name}} </li>
                <li> <strong> Test Oranı: </strong> % {{data.version.percentage}} </li>
                <li> <strong> Başlangıç Tarihi: </strong> {{moment(data.version.add_time)}}</li>
                <li> <strong> Bitiş Tarihi: </strong> {{moment(data.version.end_time)}} </li>
            </ul>
        </div>
        <div class="title"> Test Detayı</div>
         <b-table-simple hover responsive style="min-height: 0 !important">
                <b-th> Açıklama </b-th>
                <b-th> Tarih </b-th>
                <b-th> Sonuç </b-th>
                <b-tbody>
                    <b-tr v-for="res in results" :key="res.id"> 
                        <b-td> {{res.description}} </b-td>
                        <b-td> {{moment(res.add_time)}} </b-td>
                        <b-td> 
                            <b-icon v-if="res.result" icon="check" variant="success"  scale="1.25" shift-v="1.25"  aria-hidden="true" />
                            <b-icon v-else icon="x" variant="danger"  scale="1.25" shift-v="1.25"  aria-hidden="true" />
                            {{res.result_description}}
                        </b-td>                      
                    </b-tr>
                </b-tbody>
            </b-table-simple> 
   
        </section>
    </vue-html2pdf>
       </div> 
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
    export default {
        name: "PanelReport",
        created:function(){
            this.$store.dispatch('listFirmwareTestResultAll', {id: this.data.version.id})
        },
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        components:{
            VueHtml2pdf
        },
        computed:{
            results(){
                return this.$store.getters.getFirmwareResults.data
            }
        },
        methods: {
            moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY HH:mm:ss');
            },
        },
        watch:{
            save: async function(data){
                if(data){
                    this.$refs.html2Pdf.generatePdf()
                }
                this.saveState(false)
            },
        }
    }
</script>

<style lang="css" scoped > 
    @import "../styles/report.min.css"; 
</style>