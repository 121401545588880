<template>
    <div>
        <b-card header-tag="header" footer-tag="footer" :style="this.mode == 'comp' && 'height: auto' ">  
             <template v-slot:header>
                 <div class="headerLeft" >
                    <router-link v-if="buttonUrl" class="btn hl-element btn-warning" variant="success" tag="a" :to="'/'+buttonUrl"> 
                       {{buttonName}}
                    </router-link>
                    <b-button v-else-if="buttonEvent" class="hl-element"  variant="warning" @click="buttonEvent">{{buttonName}}</b-button>

                    <router-link v-if="addUrl" class="btn hl-element btn-success" variant="success" tag="a" :to="'/'+addUrl"> 
                       {{$t('general.add')}}
                    </router-link>
                    <b-button class="hl-element"  v-b-modal.fields variant="primary">{{$t('general.title')}}</b-button>

                    <b-button class="hl-element" v-if="inputs" v-b-modal.filterModal variant="info">{{$t('general.filter')}}</b-button>

                 </div>
                  <div class="headerRight">
                    <template v-if="setLimit">
                        <b-form-select v-model="limit" class="mb-3" @change="setLimit(limit)">
                            <b-form-select-option :value="10">10 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="25">25 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="50">50 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="100">100 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="250">250 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="500">500 {{$t('general.units')}}</b-form-select-option>
                            <b-form-select-option :value="1000">1000 {{$t('general.units')}}</b-form-select-option>
                        </b-form-select>
                        <b-form-input v-if="search" :placeholder="$t('general.search')" v-model="s" @keyup.enter="searching"></b-form-input>

                    </template>
                 </div>
            </template>
                 
            <b-modal id="fields" :title="$t('general.tableHeaders')" size="lg" hide-footer >
                <b-form-group :label="$t('general.viewHeaders')">
                    <b-form-checkbox-group id="tableField" v-model="filtered" name="tableField">
                        <b-form-checkbox v-for="field in fields" :key="field.key" :value="field.key" checked>{{field.label}}</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>
            </b-modal> 

             <b-modal v-if="search" id="filterModal" :title="$t('general.search')" size="lg" :cancel-title="$t('general.cancel')" :ok-title="$t('general.filter')" @ok="search">
                <b-form-group :label="$t('general.filterMsg')" >
                        <div class="filterInputs" v-for="input in inputs" :key="'filter-'+input.key">
                            <b-form-checkbox class="filterChck" style="" v-model="input.checked">
                                {{input.label}}
                            </b-form-checkbox>
                            <Datetime  class="filterInput" v-if="input.type == 'Date'"  v-model="input.val" :disabled="!input.checked"></Datetime>
                            <b-form-select v-if="input.type == 'Select'" class="filterInput" v-model="input.val" :disabled="!input.checked">
                                <b-form-select-option v-for="item in input.array" :key="item.value" :value="item.value">{{item.text}}</b-form-select-option>
                            </b-form-select>
                            <b-form-input class="filterInput" v-else :placeholder="input.label" v-model="input.val" :disabled="!input.checked"></b-form-input>
                        </div>
                </b-form-group>
            </b-modal> 

            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
                                  
            <b-table-simple sticky-header v-else hover responsive >
                <draggable v-model="fields" tag="thead" >
                    <tr>
                        <b-th v-for="field in fields" :key="field.key" >
                            <div v-if="filtered.includes(field.key)">
                                {{field.label}}
                            </div>
                        </b-th>
                    </tr>
                </draggable>
                <b-tbody >
                    
                    <b-tr  v-for="item in items" :key="item.id" @dblclick="goDetail(item)" :style="'color:'+item.fontColor+'; background:'+item.backColor" > <!-- v-b-popover.hover="popoverConfig(item.note)" -->
                        <b-td v-for="field in fields" :key="field.key"> 
                            <div v-if="filtered.includes(field.key)">
                                <div v-if="field.key == 'note'">
                                    <span v-for=" note in item[field.key]" :key="note.index"> {{ note }} </span>
                                </div>
                                <div v-else-if="field.key == 'active'">
                                   <div v-if=" renderData(field.keys, item) == 0" > <b-icon icon="x" variant="danger" animation="throb"></b-icon>  <b-badge variant="danger" >  {{$t('general.passive')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 1" > <b-icon icon="check" variant="success"></b-icon> <b-badge variant="success" >  {{$t('general.active')}} </b-badge> </div>
                                </div>
                                <div v-else-if="field.key == 'panel_active'">
                                   <div v-if=" renderData(field.keys, item) == 0" > <b-icon icon="x" variant="danger" animation="throb"></b-icon>  <b-badge variant="danger" >  {{$t('general.passive')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 1" > <b-icon icon="check" variant="success"></b-icon> <b-badge variant="success" >  {{$t('general.active')}} </b-badge> </div>
                                </div>
                                 <div v-else-if="field.key == 'user_type'">
                                   <div v-if=" renderData(field.keys, item) == 0" >  <b-badge variant="info" > {{$t('user.types.user')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 1" > <b-badge variant="warning" > {{$t('user.types.dealer')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 2" > <b-badge variant="danger" > {{$t('user.types.admin')}} </b-badge> </div>

                                </div>
                                <div v-else-if="field.key == 'state'">
                                   <div v-if=" renderData(field.keys, item) == 0" >  <b-badge variant="info" > {{$t('general.waiting')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 1" > <b-badge variant="success" > {{$t('general.active')}} </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 2" > <b-badge variant="danger" > İptal </b-badge> </div>

                                </div>
                                <div v-else-if="field.key == 'order_state'">
                                   <div v-if=" renderData(field.keys, item) == 0" > <b-badge variant="danger" >  Sipariş Tamamlanamadı </b-badge> </div>
                                    <div v-if=" renderData(field.keys, item) == 1" ><b-badge variant="success" >  Alım Gerçekleşti </b-badge> </div>
                                </div>
                                 <div v-else-if="field.key == 'is_billing'">
                                   <div v-if=" renderData(field.keys, item)" ><b-badge variant="success" >  Faturalandırıldı </b-badge> </div>
                                    <div v-else >   <b-badge variant="danger" >  Faturalandırılmadı</b-badge> </div>
                                </div>
                                <div v-else-if="field.key == 'subscribes'"> 
                                    <span v-for="(sub,index) in item[field.keys[0]]" :key="sub.id"> 
                                        <b-badge  :id="'sub'+sub.key" :variant="variants[index%7]"> {{sub.sub.name}} </b-badge> 
                                        <b-tooltip v-if="sub.users" :target="'sub'+sub.key" triggers="click">
                                            <ul class="others" >
                                                <li v-for="user in sub.users" :key="user.id">{{user}}</li>
                                            </ul>
                                        </b-tooltip>
                                    </span>
                                </div>
                                <div v-else-if="field.key == 'actions'">
                                     <b-dropdown variant="default">
                                        <template slot="button-content"> <b-icon :title="field.label" icon="three-dots"  scale="1.25" shift-v="1.25" aria-hidden="true" /> </template>                                  
                                        <b-dropdown-item 
                                            v-for="action in actions"
                                            :key="action.key"
                                            @click="actionEvent(action.key, item )"
                                            :disabled=" 
                                            action.state !== undefined && action.state == item['active'] || 
                                            action.state !== undefined && action.state == item['is_active'] ||
                                            action.userstate !== undefined && action.userstate == item['User']['active'] ||
                                            action.panelstate !== undefined && action.panelstate == item['Active'] ||
                                            action.state !== undefined && action.state == item['state'] || 
                                            action.isAdmin == true && getRole() < 2 ||
                                            action.disabled == true && item['state'] == 1
                                            "> 
                                            {{action.text}}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div v-else-if="field.key == 'address'"> 
                                    {{ item[field.key].address +" "+ item[field.key].town.name + " / " + item[field.key].city.name + " / " + item[field.key].country.name}}
                                </div>
                                <span v-else-if="field.type == 'date'">{{ dateFormat(renderData(field.keys, item))}}</span>
                                <span v-else-if="field.type == 'badge'"> <b-badge :variant="getVariant(field.keys, item)"> {{ renderData(field.keys, item)}} </b-badge> </span>
                                <span  style="white-space: nowrap" v-else-if="field.type == 'price'">{{renderData(field.keys, item) + ' '+ (items.includes('currency') ?  renderData(['currency'], item) : 'TL' )}} </span>
                                <span v-else-if="field.type == 'user'">{{renderData([...field.keys,'name'], item) + ' - '+ renderData([...field.keys,'email'], item)}}</span>
                                <span v-else-if="field.type == 'percent'"><b-progress :value="renderData(field.keys, item)" :max="100" show-progress animated variant="success"></b-progress> </span>
                                <span v-else-if="field.type == 'show'"><b-link @click="openModal(true,'Photo',{photo: renderData(field.keys, item)})" target="_blank"> Görüntüle </b-link> </span>
                                <span v-else>{{ renderData(field.keys, item)}}</span>

                            </div>    
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <span v-if="type === 'Accounts'" class="detailText"> <a href="#"> {{ $t('general.seeAll') }} </a> </span>
            <template v-slot:footer v-if="pageData">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="pageData.count"
                    :per-page="pageData.limit"
                ></b-pagination>
            </template>
        </b-card>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName"  />
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import moment from 'moment'
    import { Datetime } from 'vue-datetime';
    import jwt_decode from "jwt-decode";
    import Modals from '../modal'


    export default {
        name: "Table",
        components: {
            draggable, Datetime, Modals
        },
        watch:{
            currentPage(val){
                this.setPage(val)
            }
        },
        data: () => {
            return {
                signalType: "signals", currentPage: 1, modalOpen: false, modalProps: {}, currentForm:"General", modalName: "",
                variants: [  'primary', 'success', 'danger', 'dark', 'warning', 'default', 'info']
            }
        },
        props:{
            fields: Array,
            items: Array,
            filtered: Array,
            type: String,
            mode: String,
            addUrl: String,
            loading: Boolean,
            limit: Number,
            page: Number,
            setLimit: Function,
            setPage: Function,
            actions: Array,
            actionEvent: Function,
            buttonUrl: String,
            buttonEvent: Function,
            pageData: Object,
            goDetail: Function,
            inputs: Array, 
            buttonName: String,
            search: Function
        },
        methods:{
            getRole: function(){
                return jwt_decode(localStorage.getItem('access_token')).UserType
            },
            renderData: function(data, obj){
                let detail;
                if(data){
                   for(let i = 0; i<data.length; i++){

                        detail = detail ? detail[data[i]] : obj[data[i]]
                    }
                    return detail 
                }
                
            },
            searching: function(e){
                this.search(this.s)
            },
            dateFormat(val){
                return moment(String(val)).format('DD.MM.YYYY HH:mm') 
            },
            popoverConfig(data) {
                return {
                    html: true,
                    placement: 'bottom',
                    customClass: 'popoverFluid',
                    content: () => {
                        let content = ""
                        for( let i = 0; i < data.length; i++)
                            content += '<strong> Tarih: </strong>'+ data[i].date+ ' <strong> Operator: </strong>'+ data[i].user+ ' <strong> Not: </strong>'+ data[i].desc +'<br />'
                        return content
                    },
                }
            },
             getVariant(field, item){
                let variants = ['primary','success','danger','info','warning','dark']
                let i = item[field[0]].id ? item[field[0]].id : item[field[0]].ID ? item[field[0]].ID : 1
                if(field[1] == 'Dealercode')
                    return variants[(item[field[0]].Dealercode.charCodeAt(0) + item[field[0]].Dealercode.length ) % 6]
                if(field[0] == 'payment_plan')
                    return variants[(item[field[0]].title.charCodeAt(0) + item[field[0]].title.length) % 6]
                return variants[i%6]
            },
             openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }

</script>

<style lang="css" scoped>
    @import "./styles/table.min.css";
</style>
