
// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const listModels = async ({ commit }, data) => {
    commit('fwLoading', true);
    let res;
    res = await sendReq("get","command/getmodellist", data)
    commit('listModels', res);
    commit('fwLoading', false);
 }

 export const listFirmwares = async ({ commit }, data) => {
    commit('fwLoading', true);
    let res;
    res = await sendReq("get","command/getfirmwarelist/"+data.id, data)
    commit('listFirmwares', res);
    commit('fwLoading', false);
 }

 export const addFirmware = async ({ commit }, data) => {
    commit('fwLoading', true);
    let res;
    res = await sendReq("post","command/devices/addfirmware", data)
    commit('firmwareStatus', res);
    commit('fwLoading', false);
 }
 export const editFirmware = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("post","command/devices/updatefirmware", data)
   commit('firmwareStatus', res);
   commit('fwLoading', false);
}
 export const addPanelFirmwareTest = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("post","command/devices/addfirmwaretest", data)
   commit('status', res);
   commit('fwLoading', false);
}
export const addFirmwareStage = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("post","command/devices/addfirmwareteststage", data)
   commit('status', res);
   commit('fwLoading', false);
}
export const listFirmwareTest = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("get","command/devices/firmwaretestlist/0", data)
   commit('listFirmwareTest', res);
   commit('fwLoading', false);
}
export const listFirmwaresStage = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("get","command/getfirmwareteststagelist/"+data.id, data)
   commit('listFirmwareStage', res);
   commit('fwLoading', false);
}
export const listFirmwareTestResult = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("get","command/devices/firmwaretestresultlist/"+data.id, data)
   commit('listFirmwareResult', res);
   commit('fwLoading', false);
}
export const deleteFirmwares = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("delete","command/devices/deletefirmware/"+data.id, data)
   commit('status', res);
   commit('fwLoading', false);
}
export const editTestResult = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("post","command/devices/updatefirmwaretestresult", data)
   commit('status', res);
   commit('fwLoading', false);
}
export const listFirmwareTestResultAll = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("get","command/devices/firmwaretestresultlistallrecords/"+data.id, data)
   commit('listFirmwareResult', res);
   commit('fwLoading', false);
}
export const updateFirmwareStage = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("post","command/devices/updatefirmwareteststage", data)
   commit('status', res);
   commit('fwLoading', false);
}
export const deleteFirmwareStage = async ({ commit }, data) => {
   commit('fwLoading', true);
   let res;
   res = await sendReq("delete","command/devices/deletefirmwareteststage/"+data.id, data)
   commit('status', res);
   commit('fwLoading', false);
}
