<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="models" :filtered="filtered" type="List" :loading="loading"  :actions="actions" :actionEvent="actionEvent"
          :goDetail="goDetail" addUrl="PanelTest/Add" buttonUrl="PanelTest/Models" />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import Modals from "./modals/";

    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
    ]
     let fields = [ 
         { key: 'model', label: 'Model', keys:['model']},
         { key: 'description', label: 'Açıklama', keys:['description'] },
         { key: 'actions', label: 'İşlemler', }        
    ]; 

    let actions = [
        {key: 'Versions', text: 'Versiyonlar'},
        {key: 'Stages', text: 'Test Aşamaları'},
        {key: 'addVersion', text: 'Versiyon Ekle'},
        {key: 'addStage', text: 'Test Aşaması Ekle'}
    ]


    let filtered = ['model', "description", 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listModels', this.params)
        },
        computed:{
            models(){
                return this.$store.getters.getModels.data
            },
        },
        data: function(){
            return{
                breadCrumb, fields, filtered, actions, modalOpen: false,
                modalProps: {}, currentForm:"Models", modalName: "", 
            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
           
            actionEvent: async function(key, data){
                 switch(key){
                    case 'Versions':
                         this.$router.push( { path: '/PanelTest/'+data.id+'/Versions' })
                         break;
                    case 'Stages':
                         this.$router.push( { path: '/PanelTest/'+data.id+'/Stages' })
                         break;
                    default :
                        this.openModal(true, key, data)
                }
            },
          
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>