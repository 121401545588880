<template>
    <div>
        <b-spinner v-if="loading || !reports" variant="primary" label="Spinning"></b-spinner>
        <div v-else >
            <b-modal :visible="reports.waiting_order_count > 0" title="Yeni Sipariş Var">
                <b-icon icon="exclamation-triangle-fill" variant="danger" animation="throb" font-scale="8" />

                <p class="my-4">
                    Faturalandırmayı bekleyen <strong> {{ reports.waiting_order_count }} adet </strong> sipariş var. 
                    Lütfen faturalarını kesin ve faturalandırma işlemini gerçekleştirin
                </p>
                <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="danger"
                        href="/Payment/Billing"
                    >
                        Faturalandırma Sayfası
                    </b-button>
                </div>
            </template>
            </b-modal>
            <div class="multiItems">
                <b-card body-class="noScroll" class="item summary primary">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Aktif Panel Sayısı
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Bağlı Panel Sayısı: </span>
                                <span> {{reports.connected_device_count_last_1_hour}}  </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary dark">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Pasif Panel Sayısı
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> İletişimi Kopuk Panel Sayısı: </span>
                                <span> {{reports.disconnected_device_count}} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="multiItems">
                <b-card body-class="noScroll" class="item summary success">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Aktif Kullanıcı Sayısı
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Düzenli Giriş Yapan Kullanıcı Sayısı </span>
                                <span> {{ reports.user_count - reports.user_count_not_login_last_7_day}} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary danger">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Eski Uygulamadaki Kullanıcılar
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Eski Uygulamadaki Aktif Kullanıcılar (3 Gün) </span>
                                <span> {{ reports.old_user_login_count_last_3_day}} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="multiItems">
                <b-card body-class="noScroll" class="item summary success">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Sipariş Adeti
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Başarıyla Tamamlanan Sipariş Adeti </span>
                                <span> {{ reports.complated_order_count }} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary success">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Sipariş Adeti
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Yeni Tamamlanan Sipariş Adeti (30 Gün)</span>
                                <span> {{ reports.complated_order_count_in_last_7_day }} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary info">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Sipariş Adeti
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Faturalandırılmış Sipariş Adeti </span>
                                <span> {{ reports.billing_order_count }} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                 <b-card body-class="noScroll" class="item summary danger">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Sipariş Adeti
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Ödemesi İade Edilen Sipariş Adeti </span>
                                <span> {{ reports.canceled_invoice_count }} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary warning">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Sipariş Adeti
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Faturalandırmayı Bekleyen Sipariş Adeti </span>
                                <span> {{ reports.waiting_order_count }} </span>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="multiItems">
                 
                
                
                <b-card body-class="noScroll" class="item summary primary">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Gelen Ödeme Tutarı
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Bu Ay Gelen Ödeme Tutarı</span>
                                <span> {{ reports.total_order_price_this_month }} TL</span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card body-class="noScroll" class="item summary success">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            Gelen Ödeme Tutarı
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small">  Toplam Gelen Ödeme Tutarı</span>
                                <span> {{ reports.total_price_all_of_time }} TL</span>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
             <div class="multiItems">
                 <b-card body-class="noScroll" class="item primary">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Aktif Kullanıcı Raporu
                    </div>
                    <div class="cardDesc">
                        <div class="information"> 
                            <Doughnut
                                :chart-options="chartOptions"
                                :chart-data="activeUserReport"
                                :height="400"
                                :width="400"

                            />
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card body-class="noScroll" class="item danger">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Sipariş Raporu
                    </div>
                    <div class="cardDesc">
                        <div class="information"> 
                            <Doughnut
                                :chart-options="chartOptions"
                                :chart-data="orderReport"
                                :height="400"
                                :width="400"
                            />
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card body-class="noScroll" class="item success">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Panel Raporu
                    </div>
                    <div class="cardDesc">
                        <div class="information"> 
                             <Bar
                                :chart-options="chartOptions"
                                :chart-data="panelReport"
                                :height="600"
                                :width="600"
                            />
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card body-class="noScroll" class="item danger">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Kullanıcı Raporu
                    </div>
                    <div class="cardDesc">
                        <div class="information"> 
                             <Bar
                                :chart-options="chartOptions"
                                :chart-data="userReport"
                                :height="600"
                                :width="600"
                            />
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card body-class="noScroll" class="item primary">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Bayi Listesi
                    </div>
                    <div class="cardDesc">
                        <div class="information"> 
                            <div>
                                <span class="small"> Toplam Bayi Sayısı: </span>
                                <span> {{reports.dealer_count}} </span>
                            </div>
                            <div>
                                <span class="small"> Toplam Üst Bayi Sayısı: </span>
                                <span> {{reports.wholesaler_count}} </span>
                            </div>
                        </div>
                         <div class="information"> 
                             <b-table-simple sticky-header hover responsive >
                                <b-thead tag="thead" >
                                    <b-tr>
                                        <b-th> Bayi Kodu </b-th>
                                        <b-th> Kullanıcı Adeti </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody >
                                    <b-tr v-for="(dealer,i) in reports.dealer_users" :key="i">
                                        <b-td> {{dealer.dealercode}} </b-td>
                                        <b-td> {{dealer.dealer_count}} </b-td>
                                    </b-tr>
                                </b-tbody>
                             </b-table-simple>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card body-class="noScroll" class="item info">
                <div class="cardDesc">
                    <div class="cardTitle"> 
                        Panel Model Listesi
                    </div>
                    <div class="cardDesc">
                         <div class="information"> 
                             <b-table-simple sticky-header hover responsive >
                                <b-thead tag="thead" >
                                    <b-tr>
                                        <b-th> Model </b-th>
                                        <b-th> Bağlı Panel Sayısı </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody >
                                    <b-tr v-for="(model,i) in reports.device_model_count" :key="i">
                                        <b-td> {{model.model}} </b-td>
                                        <b-td> {{model.device_count}} </b-td>
                                    </b-tr>
                                </b-tbody>
                             </b-table-simple>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
            
        </div>
    </div>
</template>

<script>
    import { Bar,Doughnut } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
    ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'Home',
        created: async function(){
            await this.$store.dispatch('getSystemSummary')
        },
        data(){
            return{
                panelReport: {
                    labels: [ 'İletişimi Kopuk Paneller', 'Yeni Panel Sayısı (30 Gün)', 'Aktif Paneller' ],
                    datasets: [ {
                        label: '',
                        backgroundColor: ['#343a40', '#007bff', '#28a745' ],
                        data: [0,0]
                    }
                    ]
                },
                userReport: {
                    labels: [ 'Toplam Kullanıcı', 'Yeni Kullanıcı (30 Gün)', 'Giriş Yapmayan Kullanıcı (7 Gün)' ],
                    datasets: [ {
                            label: '',
                            backgroundColor: ['#007bff', '#28a745', '#dc3545' ],
                            data: [0,0,0]
                        }
                    ]
                },
                activeUserReport:{
                    labels: ['Aktif Kullanıcı Adeti', '7 Gündür Giriş Yapmayan Kullanıcı Adeti'],
                    datasets: [
                        {
                            backgroundColor: ['#28a745', '#dc3545'],
                            data: [0,0]
                        }
                    ]
                    },
                orderReport:{
                    labels: ['Faturalandırılan Ödemeler', 'İade Edilen Ödemeler', 'Faturalandırmayı Bekleyen Ödemeler' ],
                    datasets: [
                        {
                            backgroundColor: ['#28a745', '#dc3545', '#ffc107'],
                            data: [0,0]
                        }
                    ]
                    },

                chartOptions: {
                    responsive: true
                }
            }
        },
        components:{
            Bar, Doughnut
        },
        computed:{
            reports(){
                if(this.$store.getters.getReports.data){
                    this.panelReport.datasets[0].data = [
                        this.$store.getters.getReports.data.disconnected_device_count,
                        this.$store.getters.getReports.data.device_added_count_last_30_day,
                        this.$store.getters.getReports.data.connected_device_count_last_1_hour,
                    ]
                    this.userReport.datasets[0].data = [
                        this.$store.getters.getReports.data.user_count,
                        this.$store.getters.getReports.data.user_added_count_last_30_day,
                        this.$store.getters.getReports.data.user_count_not_login_last_7_day,
                    ]
                    this.orderReport.datasets[0].data = [
                        this.$store.getters.getReports.data.billing_order_count,
                        this.$store.getters.getReports.data.canceled_invoice_count,
                        this.$store.getters.getReports.data.waiting_order_count,

                        
                    ]
                    this.activeUserReport.datasets[0].data = [
                        this.$store.getters.getReports.data.user_count - this.$store.getters.getReports.data.user_count_not_login_last_7_day,
                        this.$store.getters.getReports.data.user_count_not_login_last_7_day
                    ]
                    return this.$store.getters.getReports.data
                }
               
            },
            loading(){
                return this.$store.getters.getUserLoading
            }

        },
       
    }
</script>

<style scoped>
    @import "./styles/home.min.css"; 
</style>