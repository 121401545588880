import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    login : {},
    userStatus : {},
    register: {},
    users: [],
    pageInfo:{},
    auth:{},
    user:{},
    reports: {},
    dealers: {}
})

export default {
    state,
    getters,
    actions,
    mutations
}