<template>
      <div>
        <b-icon v-if="data.state == false" icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <b-icon v-if="data.state ==true" icon="check" variant="success" animation="throb" font-scale="8" />
       
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p v-if="data.state == false"> {{data.user.User ? data.user.User.name : data.user.name}} - {{data.user.User ? data.user.User.email : data.user.email}}
            {{$t('user.activeMsg')}}
        </p>
        <p v-if="data.state ==1"> {{data.user.User ? data.user.User.name : data.user.name}} - {{data.user.User ? data.user.User.email : data.user.email}}
            {{$t('user.passiveMsg')}}
        </p>
   </div>
</template>

<script>
    export default {
        name: "SetStateCustomer",
//setState        
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = {
                            user_id: this.data.user.User.id,
                            state: this.data.state,
                        }
                        await this.$store.dispatch('userSetState', data)
                        await this.$store.dispatch('listDeviceUser', this.$route.params.dev_id)
                        this.saveState(false)
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>