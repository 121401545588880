<template>
    <form ref="form">
        <b-form-group>
            <b-row>
                <b-col sm="4"> <label> {{$t('program.keypads.no')}} </label> </b-col>
                <b-col sm="8">
                    <Multiselect  
                        v-model="keypadNo"
                        :show-labels="false" 
                        :options="keypads"
                        label="name"
                        track-by="id" >
                            <template slot="singleLabel" slot-scope="{ option }">
                                <strong>{{ option.name }} </strong> 
                            </template>
                    </Multiselect> 
                </b-col>
            </b-row>
        </b-form-group>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
      
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

   let keypads = [
          { id: 1, name: '1. '+i18n.t('program.general.keypad') },
          { id: 2, name: '2. '+i18n.t('program.general.keypad')  },
          { id: 3, name: '3. '+i18n.t('program.general.keypad')  },
          { id: 4, name: '4. '+i18n.t('program.general.keypad')  }
        ]
    let yesNo = [
          { text: i18n.t('program.general.no') , value: 0},
          { text: i18n.t('program.general.yes') , value: 1},
        ]

    export default {
        name: "Keypads",
        components:{
            Multiselect,
            Modals: () => import('./index.vue')
        },
        data:function(){
           return{
              keypadNo: {},
               keypads, yesNo,
               modalOpen: false,
                currentForm: "Keypads",
                modalProps: {},
                modalName: null,
           } 
        },
          props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalEvent: Function
        },
        methods:{
            openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName = modal
                this.modalOpen = state
            },
        },
         watch: {
            save: async function(data){
                if(data){
                    this.openModal(true,'program', {keypad_no: this.keypadNo.id-1, name: ""})
                }
                this.saveState(false)

            }
         }
        
    }
</script>