<template>
    <div class="header"> 
        <div class="fast-menu">
            <ul>
                <li> <b-icon-list :title= "$t('menus.menu')" @click="changeCollapse"></b-icon-list> </li>
            </ul>  
        </div>
        <div class="settings">
           <ul class="settingsUl">
                <li>
                    <b-nav>  
                      <span style="font-size: 12px; font-weight: bold;  white-space: nowrap;"> {{$t('payment.subsEnd')}}: </span> 
                      <span style="font-size: 14px; font-weight: bold;  white-space: nowrap; color: red;"> {{ getSubDate()}}</span>
                    </b-nav>    
                </li>
                <li>
                    <b-nav>  
                        <b-nav-item-dropdown id="my-nav-dropdown" right>
                            <template slot="button-content"> <country-flag :country="activeLang.detail.flagCode" size="normal" /> {{activeLang.detail.name}} </template>
                            <b-dropdown-item v-for="lang in languages" :key="lang.detail.code" @click="changeLang(lang.detail.code)"> 
                                <country-flag :country="lang.detail.flagCode" size="normal" /> {{lang.detail.name}}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-nav>    
                </li>
                <li> 
                    <b-nav>  
                        <b-nav-item-dropdown :text="getName()" id="my-nav-dropdown" right>
                            <template slot="button-content"> <b-avatar :size="28"></b-avatar> {{getName()}}</template>
                            <b-dropdown-item @click=" goSettings"> {{$t('menus.profile')}} </b-dropdown-item>
                            <b-dropdown-item @click=" openModal(true, 'ChangePwd')"> {{$t('menus.changePwd')}} </b-dropdown-item>
                            <b-dropdown-item @click=" openModalPay(true, 'Coupon')"> {{$t('menus.coupon')}} </b-dropdown-item>
                            <b-dropdown-item @click="logout"> {{$t('menus.signOut')}} </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-nav>  
                </li>
            </ul>  
        </div>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
        <ModalPay :open="modalOpenPay" :modalEvent="openModalPay" :modalProps="modalPropsPay" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import CountryFlag from 'vue-country-flag'
    import i18n from '@/plugins/i18n'
    import {eventBus} from '../main'
    import jwt_decode from "jwt-decode";
    import moment from 'moment'
    import Modals from '../pages/Users/modals'
    import ModalPay from '../pages/Payments/modals'

    export default {
        name: 'Header',
        data: () =>{
            return {
                languages: Object.values(i18n.messages),
                activeLang: Object.values(i18n.messages).find( (language) => { return language.detail.code == localStorage.getItem("lang") }),
                modalOpen: false, modalProps: {}, currentForm:"User", modalName: "", 
            }
        },
        methods:{
            openModal: function(state, modal, props){
                this.currentForm='User'
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
            openModalPay: function(state, modal, props){
                this.currentForm='Payment'
                this.modalOpenPay = state
                this.modalPropsPay = props
                this.modalName = modal
            },
            getName: function(){
                return jwt_decode(localStorage.getItem('access_token')).Username
            },
            getSubDate: function(){
                if(moment(jwt_decode(localStorage.getItem('access_token')).SubExpireDate).format('YYYY') < 2000)
                    return 'Abonelik Satın Alın' 
                else
                    return  moment(jwt_decode(localStorage.getItem('access_token')).SubExpireDate).format(' DD MMMM YYYY ');
            },
            goSettings: function(){
                let user_id = jwt_decode(localStorage.getItem('access_token')).user_id;
                this.$router.push( { path: '/User/Edit' })
            },
            goChangePwd: function(){
                this.$router.push( { path: '/User/ChangePassword' })
            },
            changeLang: (lang) =>{
                localStorage.setItem("lang", lang)
                location.reload();
            },
            changeCollapse: () => {
                eventBus.$emit("collapse")
            },
            logout: function(){
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                location.reload();
            }
        },
        components:{
            CountryFlag, Modals, ModalPay
        }
    }
</script>

<style lang="css" scoped>
    @import "./styles/header.min.css";
</style>