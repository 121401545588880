import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import i18n from './plugins/i18n'
import axios from "./utils/axios";
import VueMqtt from 'vue-mqtt';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-datetime/dist/vue-datetime.css'
import VueCookie from 'vue-cookie'
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import video from "video.js";
import "videojs-flash";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import LoadScript from 'vue-plugin-load-script';

Vue.use(VueTelInput);
Vue.prototype.$video = video;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('v-icon', Icon)
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
export const eventBus = new Vue();
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookie);
Vue.use(LoadScript);
Vue.use(VueMqtt, process.env.VUE_APP_MQTT_SERVER, {
  clientId: 'WebClient-' + parseInt(Math.random() * 100000), 
  username: process.env.VUE_APP_MQTT_USER,
  password: process.env.VUE_APP_MQTT_PASSWORD,
  rejectUnauthorized: false});


var vm = new Vue({
  el: '#app',
  store,
  i18n,
  router,
  components: { App },
  template: '<App/>',
})

global.vm = vm; 