<template>
    <div>
        <validation-observer  ref="gsm">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <validation-provider :name="$t('program.gsm.apn')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.gsm.apn')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="apn" :placeholder="$t('program.gsm.apn')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.gsm.user')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.gsm.user')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="username" :placeholder="$t('program.gsm.user')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.gsm.password')"   v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.gsm.password')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="password" :placeholder="$t('program.gsm.password')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>

                <validation-provider :name="$t('program.gsm.renewal')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.gsm.renewal')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="invoiceDay" :placeholder="$t('program.gsm.renewal')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                apn: "",
                username: "",
                password: "",
                invoiceDay: "",
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.yes'), value: true },
                ],
            }
        },
        
        watch: { 
            data: function(newVal, oldVal) { // watch it
                this.apn = newVal.apn_name
                this.username = newVal.user_name
                this.password =  newVal.password
                this.invoiceDay =  newVal.invoice_day
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.gsm.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 9,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                id: this.data.id,
                                apn_name: this.apn,
                                user_name: this.username,
                                password: this.password,
                                invoice_day: parseInt(this.invoiceDay)
                            }
                            await this.$store.dispatch('setGSMSettings', params)
                        } else {
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                }
                this.saveRemove(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>