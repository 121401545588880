<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="users" :filtered="filtered" type="List" :loading="loading" :limit="limit" :setLimit="setLimit" :search="search"
        :page="page" :setPage="setPage" :pageData="pageInfo" :actions="actions" :actionEvent="actionEvent" addUrl="User/Add" />

        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    import jwt_decode from "jwt-decode";

    let breadCrumb = [
        { label: i18n.t('panelManage.users'), url: "/User/List" },
        { label: i18n.t('panelManage.userList'), url: "/User/List" },
    ]
     let fields = [ 
         { key: 'name', label: i18n.t('user.name'), keys:['name']},
         { key: 'email', label: i18n.t('user.mail'), keys:['email'] },
         { key: 'Phonenumber', label: i18n.t('user.phone'), keys:['Phonenumber'] },
         { key: 'Dealercode', label: i18n.t('user.dealer'), keys:['Dealercode'] },
         { key: 'user_type', label: i18n.t('panelManage.userType'), keys:['user_type'] },
         { key: 'active', label: i18n.t('panelManage.userState'), keys:['active'] },
         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let actions = [
        {key: 'active', text: i18n.t('panelManage.activeUser'), state: true},
        {key: 'passive', text: i18n.t('panelManage.passiveUser'), state: false},
        {key: 'mobileReset', text: i18n.t('panelManage.mobileReset')},
        {key: 'pwdReset', text: i18n.t('panelManage.pwdReset')},
    ]

    let filtered = ['name', "email", 'Phonenumber', 'Dealercode',  'user_type', 'active', 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listUser', this.params)
            console.log(jwt_decode(localStorage.getItem('access_token')))
            if(jwt_decode(localStorage.getItem('access_token')).OldUserId > 0 ){
                let MRIndex = this.actions.findIndex(a => a.key == 'mobileReset')
                if (MRIndex > -1) {
                    this.actions.splice(MRIndex,1)
                }
                
            }
        },
        computed:{
            users(){
                return this.$store.getters.getAuthUsers.data
            },
            pageInfo(){
                return this.$store.getters.getUserPageInfo
            }

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"User", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },
        watch:{
            limit(val){
                this.params['limit'] = val
                this.$store.dispatch('listUser', this.params)
            },
            offset(val){
                 this.params['offset'] = val
                 this.$store.dispatch('listUser', this.params)
            },
            searchVal(val){
                 this.params['search'] = val
                 this.$store.dispatch('listUser', this.params)
            }
        },

        methods:{
           
            complete(){
                this.$store.dispatch('listUser', this.params)
            },
            search(s){
                this.searchVal = s
            },

            setLimit:function(limit){
                this.limit = limit
            },
            setPage:function(page){
                if(page > 0){
                    this.page = page
                    this.offset = ((page-1) * this.limit)
                }
            },
            actionEvent: async function(key, data){
                console.log(data, key)
                switch(key){
                     case 'active':
                        this.openModal(true, 'SetState', {user: data, state:true})
                        break;
                    case 'passive': 
                        this.openModal(true, 'SetState', {user: data, state:false})
                        break;
                    case 'pwdReset': 
                        this.openModal(true, 'PwdReset', {user: data})
                        break;
                    case 'mobileReset': 
                        this.openModal(true, 'MblReset', {user: data})
                        break;
                    case 'moveSubs': 
                        this.openModal(true, 'moveSubs', {user: data})
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  {user: data})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>