<template>
    <div id="notfound">
		<div class="notfound">
			<div>
				<div class="notfound-404">
					<h1>!</h1>
				</div>
				<h2> {{$t('messages.forbiddenTitle')}}</h2>
			</div>
			<p> {{$t('messages.forbiddenMsg')}} </p>
		</div>
	</div>
</template>
<script>
    export default {
         name: 'Forbidden',
    }
</script>
<style lang="css" scoped> 
    @import "./styles/error.min.css"; 
</style>