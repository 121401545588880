// Actiondan gelen datalar ile stateler güncellenecek
export const panelSettingLoading = (state, payload) => {
    state.loading = payload
}

export const command = (state, payload) => {
    if(payload.data){
        if(payload.data.Err){
            if(payload.data.Err !== 0){
                vm.$root.$bvToast.toast("Komut panele gönderilirken bir hata oluştu", {
                    title: `Hata! `,
                    variant: 'danger',
                    noAutoHide: true
                })
            }else{
                vm.$root.$bvToast.toast("Komut başarıyla panele gönderildi", {
                    title: `Başarılı! `,
                    variant: 'success',
                }) 
            }
        }
     
    }
    state.command = payload.data
}

export const setSettings = (state, payload) => {
    if(payload.data && payload.data.err){
        vm.$root.$bvToast.toast("Komut panele gönderilirken bir hata oluştu", {
            title: `Hata! `,
            variant: 'danger',
            noAutoHide: true
        })
    }else{
        vm.$root.$bvToast.toast("Komut başarıyla panele gönderildi", {
            title: `Başarılı! `,
            variant: 'success',
        }) 
    }
    state.setSettings = payload.data
}

export const panelCode = (state, payload) => {
    if(payload.data.err){
        vm.$root.$bvToast.toast("Komut panele gönderilirken bir hata oluştu", {
            title: `Hata! `,
            variant: 'danger',
            noAutoHide: true
        })
    }else{
        vm.$root.$bvToast.toast("Komut başarıyla panele gönderildi. Lütfen panel ekranındaki kodu giriniz", {
            title: `Başarılı! `,
            variant: 'success',
        }) 
    }
    state.panelCode = payload.data
}
export const reader = (state, payload) => {
    if(payload.data){
        state.reader = payload.data
    }else{
        state.reader = []
    }
}
export const pdksReport = (state, payload) => { 
        state.pdksExcel = payload.data  
}


 