<template>
    <div>
        <validation-observer  ref="stationary">
            <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <validation-provider :name="$t('program.stationary.program')" :rules="{required}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.stationary.program')}} </label> </b-col>
                            <b-col sm="8"> 
                               <div class="elements">
                                    <Multiselect  
                                        v-model="program"
                                        :show-labels="false" 
                                        :options="programs"
                                        label="Description"
                                        track-by="Code" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.Description }} </strong> 
                                            </template>
                                    </Multiselect>
                                </div>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
               
                <b-form-group  class="inputGrp" :label="$t('program.stationary.detectionTime')" label-for="time">
                    <div class="elements">
                        <Datetime v-model="time" type="time" ></Datetime>
                    </div>
                </b-form-group>
                <validation-provider :name="$t('program.stationary.days')" :rules="{required}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.stationary.days')}} </label> </b-col>
                            <b-col sm="8"> 
                                    <Multiselect  
                                        v-model="days"
                                        :show-labels="false" 
                                        :options="dayList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </b-form>
        </validation-observer>
    </div>
    
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import { Datetime } from 'vue-datetime';
    import moment from 'moment'
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        computed:{
            programs(){
                return this.$store.getters.getautoArms
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
        },
        data: function(){
            return {
                daysArr: [],
                days: [],
                program: {},
                time: "",
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.yes'), value: true },
                ],
                dayList: [
                    { id: 1, name: i18n.t('program.general.monday') },
                    { id: 2, name: i18n.t('program.general.tuesday') },
                    { id: 3, name: i18n.t('program.general.wednesday') },
                    { id: 4, name: i18n.t('program.general.thursday') },
                    { id: 5, name: i18n.t('program.general.friday') },
                    { id: 6, name: i18n.t('program.general.saturday') },
                    { id: 7, name: i18n.t('program.general.sunday')},
                ]
            }
        },
        
        watch: { 
            data: function(newVal, oldVal) { // watch it
                this.program = this.programs[newVal.aim]
                this.time =  newVal.time.hour+":"+newVal.time.minute
                this.daysArr = Object.values(newVal.days);
            },
             daysArr: function(newVal, oldVal){
                this.days = []
                newVal.map((day,i) => {
                    day && this.days.push(this.dayList[i])
                })
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.stationary.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 11,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                time: {
                                    hour: moment(this.time).format('HH'),
                                    minute: moment(this.time).format('mm'),
                                },
                                aim: parseInt(this.program.Code),
                                days: {
                                    monday: this.days.findIndex(e => e.id == 1) > -1 ? true : false,
                                    tuesday: this.days.findIndex(e => e.id == 2) > -1 ? true : false,
                                    wednesday: this.days.findIndex(e => e.id == 3) > -1 ? true : false,
                                    thursday: this.days.findIndex(e => e.id == 4) > -1 ? true : false,
                                    friday: this.days.findIndex(e => e.id == 5) > -1 ? true : false,
                                    saturday: this.days.findIndex(e => e.id == 6) > -1 ? true : false,
                                    sunday: this.days.findIndex(e => e.id == 7) > -1 ? true : false
                                },
                            }
                            await this.$store.dispatch('setStationarySettings', params)
                        } else {
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Multiselect, Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>