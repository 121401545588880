<template>
    <div>
        <BreadCrumb :data="breadCrumb" />   
        <Panel :morePanel="morePanels" :loading="loading" :data="panels" :search="searchPanel" :type="type" :changeType="changeType" />
       
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Panel from './components/Panel'
    import {stopTimeout, startTimeout} from '../../assets/js/timing'
    import i18n from '@/plugins/i18n';
    
    let breadCrumb = [
        { label: i18n.t('login.title'), url: "/Panels" },
    ]

    export default {
        name: 'ProsecPanelList',
        mqtt: {
           'Sanat/+' (data, topic) {
            let reciveData = JSON.parse(this.bin2String(data))
            let index = this.panels.findIndex(item => item.dev_id === reciveData.dev_id)
            let newData = Object.assign(this.panels[index], reciveData)
            this.panels.splice(index, 1)
            this.panels.splice(index, 0, newData)
            if(newData.KLStatus.armed){
                 var audio = new Audio(require('../../assets/sound/siren.mp3'));
                 audio.play()
            }
           }
        },
        created: async function(){
            this.$store.state.Panel.auth = {}
            this.$store.state.Panel.pwd = {}
            stopTimeout();
            this.getPanels()
        },
        data: function(){
            return{
                breadCrumb,
                panelsData: [],
                type: 'Card',
                offset: 0
            }
        },
        computed:{
            loading(){
                return this.$store.getters.getPanelLoading
            },
            panels(){
                this.panelsData = this.$store.getters.panels

                return this.$store.getters.panels
            },
        },
        components:{
            BreadCrumb, Panel
        },
        methods:{
            changeType: function(){
                this.type = this.type == 'Card' ? 'List' : 'Card'
            },
            getPanels: async function(){
                await this.$store.dispatch('panels',{offset: this.offset})
                this.panels.map(panel => {
                    this.$mqtt.subscribe('Sanat/'+panel.dev_id)
                })
            },
            async searchPanel(search){
                search = search.replace(/[@!^&\/\\#,+()$~%.'":*?<>{}]/g, '')
                console.log(search)
                search == "" ? await this.$store.dispatch('panels',{offset: 0}) : await this.$store.dispatch('searchPanel', search)
            },
            bin2String: function(array) {
                let encodedString = String.fromCharCode.apply(null, array),
                decodedString = decodeURIComponent(escape(encodedString));
                return decodedString;
            },
            morePanels: function(){
                this.offset =  this.panels.length;
                this.getPanels()
            }
        }
    }
</script>

<style lang="css" scoped> 
    @import "./styles/panel.min.css"; 
</style>