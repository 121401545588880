
<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
         <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.zones.no')}} </b-th>
                <b-th> {{$t('program.zones.name')}} </b-th>
                <b-th> {{$t('program.zones.bypass')}} </b-th>
                <b-th> {{$t('program.zones.state')}} </b-th>
                <b-th v-if="auth.auth.installer"> {{$t('program.general.program')}} </b-th>
            </b-thead>
            <b-tbody >
                <b-tr v-for="zone in data" :key="zone.id"> 
                    <b-td> {{zone.zone_no+1}} </b-td>
                    <b-td > {{zone.name}} </b-td>
                    <b-td> 
                        <b-badge v-if="zone.zone_byp_status == 1" variant="success"> {{$t('program.zones.bypassActive')}} </b-badge>
                        <b-badge v-else variant="danger"> {{$t('program.zones.bypassPassive')}} </b-badge>
                    </b-td>
                    <b-td> 
                        <b-badge v-if="zone.zone_status == 1" variant="success"> {{$t('program.zones.zoneActive')}} </b-badge>
                        <b-badge v-else variant="danger"> {{$t('program.zones.zonePassive')}} </b-badge>
                    </b-td>
                    <b-td v-if="auth.auth.installer"> <b-button size="sm" variant="info" @click="openModal(true,'program', zone)">{{$t('program.general.program')}} </b-button> </b-td>
                    
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
   
</template>

<script>
    import Modals from "../modals/"

    export default {
        name: 'ProsecPanel',
     
       
        props:{
            data: Array,
            loading: Boolean,
            openModal: Function,
            auth: Object
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>