<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
        <form v-else ref="form">
            <b-tabs >
                <validation-observer  ref="ZonePRG">
                    <b-tab :title="$t('program.zones.tab1')" >
                        <b-card-text style="margin-top:1rem">
                            <validation-provider :name="$t('program.zones.name')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.name')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="zoneName" :placeholder="$t('program.zones.name')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                                
                            <b-form-group>
                                <b-row>
                                    <b-col sm="3"> <label for="accountName"> {{$t('program.zones.settings')}} </label> </b-col>
                                    <b-col sm="3"> 
                                        <b-form-checkbox
                                        v-model="status"
                                        name="checkbox-1"
                                        :value="true"
                                        :unchecked-value="false"
                                        > {{$t('program.zones.usage')}} </b-form-checkbox>
                                    </b-col>
                                    <b-col sm="3"> 
                                        <b-form-checkbox
                                        v-model="homeStatus"
                                        name="checkbox-2"
                                        :value="true"
                                        :unchecked-value="false"
                                        > {{$t('program.zones.homeMode')}} </b-form-checkbox>
                                    </b-col>
                                    <b-col sm="3"> 
                                        <b-form-checkbox
                                        v-model="silent"
                                        name="checkbox-3"
                                        :value="true"
                                        :unchecked-value="false"
                                        > {{$t('program.zones.silent')}}</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-form-group>

                            <validation-provider :name="$t('program.zones.behaviours')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.behaviours')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="behavior"
                                                :show-labels="false" 
                                                :options="behaviorList"
                                                label="Description"
                                                track-by="Code" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.Description }} </strong> 
                                                    </template>
                                            </Multiselect> 
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                          
                            <validation-provider :name="$t('program.zones.connectType')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.connectType')}} </label> </b-col>
                                        <b-col sm="8"> 
                                           <Multiselect  
                                                v-model="connectType"
                                                :show-labels="false" 
                                                :options="connectTypes"
                                                label="Description"
                                                track-by="Code" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.Description }} </strong> 
                                                    </template>
                                            </Multiselect> 
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.zones.zoneType')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.zoneType')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="zoneType"
                                                :show-labels="false" 
                                                :options="zoneTypes"
                                                label="Description"
                                                track-by="Code" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.Description }} </strong> 
                                                    </template>
                                            </Multiselect> 
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                        </b-card-text>
                    </b-tab>
                    </validation-observer>
                    <b-tab :title="$t('program.zones.tab2')" >
                        <b-card-text style="margin-top:1rem">
                            <validation-provider :name="$t('program.zones.entryChime')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.entryChime')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="entryRing"
                                                :show-labels="false" 
                                                :options="keypads"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.name }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.zones.outChime')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.outChime')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="outRing"
                                                :show-labels="false" 
                                                :options="keypads"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.name }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                             <validation-provider :name="$t('program.zones.keypadLight')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.keypadLight')}} </label> </b-col>
                                        <b-col sm="8"> 
                                             <Multiselect  
                                                v-model="lighthing"
                                                :show-labels="false" 
                                                :options="keypads"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.name }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.zones.notification')" :rules="{ required: true}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.notification')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="notification"
                                                :show-labels="false" 
                                                :options="keypads"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.name }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title="$t('program.zones.tab3')">
                        <b-card-text style="margin-top:1rem">
                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.zones.contactType')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="relayType"
                                            :options="relayTypes"
                                            name="relayType"
                                        />
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.zones.autoBypass')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            name="autoByPass"
                                            v-model="autoByPass"
                                            :options="yesNo"
                                        />
                                    </b-col>
                                </b-row>
                            </b-form-group>
                             <validation-provider :name="$t('program.zones.reactionTime')" :rules="{ integer }"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.reactionTime')}} </label> </b-col>
                                        <b-col sm="8"> 
                                           <b-input :placeholder="t('program.zones.reactionTime')" v-model="relayTime"  />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.zones.wirelessId')" :rules="{ integer }"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.wirelessId')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-input placeholder="Kablosuz Id" v-model="wifiNo"  />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.zones.crossZone')" :rules="{ integer }"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.crossZone')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-input placeholder="Çapraz Bölge No" v-model="crossZone"  />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                             <validation-provider :name="$t('program.zones.zoneParams')" :rules="{ integer }"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.zones.zoneParams')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-input placeholder="Zone Parametre" v-model="zonePrm"  />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%;">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
             
        </form>
    </div>
  
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    let keypads = [
          { id: 1, name: '1. '+i18n.t('program.general.keypad') },
          { id: 2, name: '2. '+i18n.t('program.general.keypad')  },
          { id: 3, name: '3. '+i18n.t('program.general.keypad')  },
          { id: 4, name: '4. '+i18n.t('program.general.keypad')  }
        ]
    let relayTypes = [
          { text: i18n.t('program.general.nClose'), value: false},
          { text: i18n.t('program.general.nOpen'), value: true},
        ]
    let yesNo = [
          { text: i18n.t('program.general.no'), value: false},
          { text: i18n.t('program.general.yes'), value: true},
        ]
    export default {
        name: "Zone",
        components:{
            Multiselect, 
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 0,
                member: this.data.zone_no,
                user_pass: this.$store.getters.getPWD,
                offset: 0,
                list_id: this.data.id ? this.data.id : -1,
            }
            await this.$store.dispatch('behaviorList')
            await this.$store.dispatch('zoneConnectTypeList')
            await this.$store.dispatch('zoneTypeList')
            await this.$store.dispatch('panelSettings', params)
            await this.$store.dispatch('panelZones', {id: this.$route.params.id})

        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            setSettings(){
                return this.$store.getters.getSetSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
            behaviorList(){
                return this.$store.getters.getBehaviors
            },
            connectTypes(){
                return this.$store.getters.getConnectTypes
            },
            zoneTypes(){
                return this.$store.getters.getZoneTypes
            },
        },
        watch:{
            settings: function(newVal, oldVal) { // watch it
                this.zoneName = newVal.name
                this.status = newVal.active
                this.homeStatus = newVal.stay_arm
                this.behavior = this.behaviorList[newVal.behaviour]
                this.connectType = this.connectTypes[newVal.el_registor]
                this.zoneType = this.zoneTypes[newVal.zone_type]
                this.relayType = newVal.no_nc
                this.autoByPass = newVal.autobypass
                this.relayTime = newVal.act_time
                this.entryRingArr = newVal.entry_chime_keypad.split("")
                this.outRingArr = newVal.exit_chime_keypad.split("")
                this.lighthingArr = newVal.light_keypad.split("")
                this.notificationArr = newVal.warn_keypad.split("")
                this.wifiNo = newVal.Wireless_ID
                this.crossZone = newVal.Cross_Zone_No
                this.zonePrm = newVal.zone_prm
                this.silent = newVal.Silent
                this.id = newVal.id
            },
            entryRingArr: function(newVal, oldVal){
                this.entryRing = []
                let index = 0;
                newVal.map((keypad, i) => {
                    if(i < 4){
                        index = index + 1
                        if(keypad == "1"){
                          this.entryRing.push(this.keypads[i])
                        }
                    }
                })
            },
            outRingArr: function(newVal, oldVal){
                this.outRing = []
                let index = 0;
                console.log(newVal)
                newVal.map((keypad, i) => {
                    if(i < 4){
                        index = index + 1
                        if(keypad == "1"){
                          this.outRing.push(this.keypads[i])
                        }
                    }
                })
            },
            lighthingArr: function(newVal, oldVal){
                this.lighthing = []
                let index = 0;
                newVal.map((keypad, i) => {
                    if(i < 4){
                        index = index + 1
                        if(keypad == "1"){
                          this.lighthing.push(this.keypads[i])
                        }
                    }
                })
            },
            notificationArr: function(newVal, oldVal){
                this.notification = []
                let index = 0;
                newVal.map((keypad, i) => {
                    if(i < 4){
                        index = index + 1
                        if(keypad == "1"){
                          this.notification.push(this.keypads[i])
                        }
                    }
                })
            },
            save: async function(data){
                if(data){
                    this.$refs.ZonePRG.validate().then( async success =>{
                        if(success){
                            let lighthingKeypads = this.keypadCount(this.lighthing)
                            let light_keypad = this.arrToStrByte(lighthingKeypads)
                            let entryRingKeypads = this.keypadCount(this.entryRing)
                            let entry_chime_keypad = this.arrToStrByte(entryRingKeypads)
                            let outRingKeypads = this.keypadCount(this.outRing)
                            let exit_chime_keypad = this.arrToStrByte(outRingKeypads)
                            let notificationKeypads = this.keypadCount(this.notification)
                            let warn_keypad = this.arrToStrByte(notificationKeypads)                    
                            let params = {
                                id: this.id,
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 0,
                                member: this.data.zone_no,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                zone_no: this.data.zone_no,
                                name:this.zoneName,
                                no_nc:this.relayType,
                                autobypass:this.autoByPass,
                                stay_arm:this.homeStatus,
                                el_registor:parseInt(this.connectType.Code),
                                act_time: parseInt(this.relayTime),
                                zone_type:parseInt(this.zoneType.Code),
                                behaviour: parseInt(this.behavior.Code),
                                entry_chime_keypad: entry_chime_keypad,
                                exit_chime_keypad: exit_chime_keypad,
                                light_keypad: light_keypad,
                                warn_keypad:warn_keypad,
                                zone_prm: parseInt(this.zonePrm),
                                Cross_Zone_No: parseInt(this.crossZone),
                                Wireless_ID: parseInt(this.wifiNo),
                                active:this.status,
                                Silent: this.silent
                            }
                            await this.$store.dispatch('setZoneSettings', params)
                            await this.$store.dispatch('panelZones', {id: this.$route.params.id})
                        }else{
                             vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                }
                this.saveState(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            keypadCount: function(arr){
                let val = arr.map(function(item) {
                    return item['id'];
                });
                return val
            },
            arrToStrByte: function(arr){
                let val = ""
                for(let i = 0; i<4; i++){
                    let isSelect = arr.find(e => e == i+1);
                    isSelect ? val = val + "11" : val = val+"00";
                }
                return val;                
            }
        },
        data:function(){
           return{
               id:0,
               behavior:{},
               connectType: {},
               zoneType: {},
               entryRing: [],
               notification: [],
               lighthing: [],
               outRing: [],
               outRingArr: [],
               entryRingArr: [],
               lighthingArr: [],
               notificationArr: [],
               silent: false,
               relayType: false,
               autoByPass: false,
                keypads, relayTypes, yesNo
           } 
        }
        
    }
</script>