<template>
  <div id="app">
    <div v-if="pageName === 'Login' || pageName === 'DealerForm'">
      <router-view/>
    </div>
    <div v-else>
      <DashboardMenu :collapsed="collapsed" />
      <div class="content" :class="{ collapsedContent: !collapsed}">
        <Header />
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import DashboardMenu from './components/DashboardMenu'
import {eventBus} from "./main"

export default {
  name: 'App',
  data: function(){
    return {
      collapsed: false,
      pageName: this.$route.name
    }
  },
  components: {
    Header, DashboardMenu
  },
   created:function() {
    eventBus.$on("collapse", () =>{
      this.collapsed = !this.collapsed
    })
  }
}
</script>

<style lang="css">
    @import "./assets/styles/style.min.css";
    @import "./assets/styles/themify-icons.css";

</style>