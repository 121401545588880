<template>
      <div>
        <h3 class="mb-0">  {{$t('user.changePwd')}} </h3>
       
        <p > 
            {{$t('user.changePwdMsg')}}
        </p>
        <b-form-group class="inputGrp">
            <b-row>
                <b-col sm="4"> <label for="code"> {{$t('user.currentPwd')}}</label> </b-col>
                <b-col sm="8"> 
                    <b-form-input id="pass" v-model="pass" :placeholder="$t('user.currentPwd')"  type="password"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4"> <label for="code"> {{$t('user.newPassword')}} </label> </b-col>
                <b-col sm="8"> 
                    <b-form-input id="newPass" v-model="newPass" :placeholder="$t('user.newPassword')"  type="password"/>

                </b-col>
            </b-row>
            <b-row>
                <b-col sm="4"> <label for="code"> {{$t('user.rePassword')}} </label> </b-col>
                <b-col sm="8"> 
                    <b-form-input id="newPass2" v-model="newPass2" :placeholder="$t('user.rePassword')"  type="password"/>

                </b-col>
            </b-row>
        </b-form-group>
   </div>
</template>

<script>
    import i18n from '@/plugins/i18n'

    export default {
        name: "ForgotPwd",
        data:function(){
            return{
                pass: "",
                newPass: "",
                newPass2: "",

            }
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                    if(this.newPass == this.newPass2 && this.newPass !== ""){
                        let data = {
                            old: this.pass,
                            new:this.newPass,
                            new_retry:this.newPass2
                        }
                        await this.$store.dispatch('changePwd', data)
                        if(this.$store.state.User.userStatus.data.status== "Success"){
                            vm.$root.$bvToast.toast(i18n.t('messages.changePwdSuccess'), {
                                title: i18n.t('general.success'),
                                variant: 'success',
                                noAutoHide: true
                            })
                        }
                        this.modalEvent(false)
                    }else{
                        vm.$root.$bvToast.toast(i18n.t('messages.changePwdError'), {
                            title:  i18n.t('general.error'),
                            variant: 'danger',
                            noAutoHide: true
                        })
                    }
                      
                }
                this.saveState(false)
            }
        }
    }
</script>