import axios from "./yandexAxios"

export default async function sendReq (method, url, data){
    let response, qData = {};
    if(method== 'get'){
        qData.params = data
        if(data && data.responseType == 'blob')
            qData.responseType = 'blob'
    }else{
        qData.data = data
    }
    await axios({method, url, ...qData}).then((res) => {
        response = res; 
    }).catch( err => {
        console.log(err)
        if(err.response){
            response = err.response.data;
           
        }else{
            response = {
                data:{
                    message: "Cannot to server",
                    status: "Error"
                }
            }
        }
    })
    return response;
}