<template>
      <div>
        <b-icon  icon="exclamation-triangle" variant="info" animation="throb" font-scale="4" />
        <hr>
        <h3 class="mb-0">  {{$t("login.forgot")}} </h3>
        <p > 
            {{$t("login.forgotMsg")}}
        </p>
        <b-form-group class="inputGrp">
            <b-row>
                <b-col sm="4"> <label for="code"> {{$t("login.user")}} </label> </b-col>
                <b-col sm="8"> 
                    <b-form-input id="username" v-model="username" :placeholder="$t('login.user')" />
                </b-col>
            </b-row>
        </b-form-group>
   </div>
</template>

<script>
    export default {
        name: "ForgotPwd",
        data:function(){
            return{
                username: "",
            }
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = { info: this.username }
                        await this.$store.dispatch('forgotPwd', data)
                        console.log( this.$store.state.User)
                        if(this.$store.state.User.userStatus.data.status== "Success"){
                            vm.$root.$bvToast.toast(i18n.t('messages.forgotSuccess'), {
                                title: i18n.t('login.forgot'),
                                variant: 'success',
                                noAutoHide: true
                            })
                        }
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>