<template>  
    <b-form>
        <div class="title"> 
            {{$t("login.title")}} 
        </div>
        <b-input-group >
            <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="userName" v-model="username" type="text" required :placeholder="$t('login.user')" ></b-form-input>
        </b-input-group>
        <b-input-group >
            <b-input-group-prepend is-text>
                <b-icon icon="lock-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input  id="pass" v-model="password" type="password" required :placeholder="$t('login.password')" ></b-form-input>
        </b-input-group>
        <div class="links">
            <b-form-checkbox id="remember" v-model="rememberMe"  name="remember"> {{$t("login.remember")}} </b-form-checkbox>
            <div class="reset">
                <div class="registerLink" @click="openModal(true, 'Forgot')"> {{$t("login.forgot")}} </div>
            </div>
        </div>
        <div class="submitButton" variant="danger" @click="login"> {{$t("login.login")}} </div>
        <div class="registerLink" @click="changeForm(2)"> {{$t("login.register")}} </div>
    </b-form>
</template>

<style lang="css" scoped> 
    @import "../styles/login.min.css"; 
</style>
<script>
    export default {
        name: "LoginForm",
        data: function(){
            return{
                username: this.$cookie.get('username')? this.$cookie.get('username') :"",
                password: this.$cookie.get('password')? this.$cookie.get('password') :"",
                rememberMe: this.$cookie.get('rememberMe')? this.$cookie.get('rememberMe') :false
            }
        },
        methods:{
            login: function(){
                if(this.rememberMe){
                    this.$cookie.set('username', this.username);
                    this.$cookie.set('password', this.password);
                    this.$cookie.set('rememberMe', this.rememberMe);
                }else{
                    this.$cookie.delete('username');
                    this.$cookie.delete('password');
                    this.$cookie.delete('rememberMe');
                }
                let data = {
                  username: this.username,
                  password: this.password, 
                }
                this.onLogin(data)
            }
        },
        props:{
            onLogin: Function,
            changeForm: Function,
            openModal: Function
        }
    }
</script>