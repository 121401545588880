<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.users.no')}} </b-th>
                <b-th> {{$t('program.users.userName')}} </b-th>
                <b-th> {{$t('program.general.program')}} </b-th>

            </b-thead>
            <b-tbody >
                <b-tr v-for="item in data" :key="item.id" > 
                    <template v-if="item.user_no !== 0 || auth.auth.installer">
                        <b-td> {{item.user_no == 0 ? item.user_no+". "+$t('program.users.installer') : item.user_no == 1 ? item.user_no+". "+$t('program.users.master') : item.user_no+". " +$t('program.users.user')}} </b-td>
                        <b-td > {{item.name}} </b-td>
                        <b-td> 
                            <b-button style="margin-right: 1rem" size="sm" variant="info" @click="openModal(true,'program', item)">{{$t('general.edit')}} </b-button> 
                            <b-button v-if="auth.auth.manage && item.user_no >1" style="margin-right: 1rem" size="sm" variant="danger" @click="openModal(true,'delete', item)">{{$t('general.delete')}} </b-button> 
                            <b-button v-if="auth.auth.manage && item.user_no >1" size="sm" variant="primary" @click="openModal(true,'changePwd', item)"> {{$t('program.users.changePwd')}} </b-button> 
                        </b-td>
                    </template>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>

</template>

<script>
    import Modals from "../modals/"

    export default {
        name: 'ProsecPanel',
     
       
        props:{
            data: Array,
            loading: Boolean,
            openModal: Function,
            auth: Object
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>