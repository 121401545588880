<template>
    <form ref="form">
        <b-form-group>
            <b-row>
                <b-col sm="4"> <label> {{$t('program.outs.no')}} </label> </b-col>
                <b-col sm="8">
                    <Multiselect  
                        v-model="outNo"
                        :show-labels="false" 
                        :options="outNoList"
                        label="name"
                        track-by="value" >
                            <template slot="singleLabel" slot-scope="{ option }">
                                <strong>{{ option.name }} </strong> 
                            </template>
                    </Multiselect> 
                </b-col>
              
            </b-row>
        </b-form-group>
         <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect'
import i18n from '@/plugins/i18n'

    let outNoList =[
        {name: '1. '+i18n.t('program.outs.out'), value:1},
        {name: '2. '+i18n.t('program.outs.out'), value:2},
        {name: '3. '+i18n.t('program.outs.out'), value:3},
        {name: '4. '+i18n.t('program.outs.out'), value:4},
        {name: '5. '+i18n.t('program.outs.out'), value:5},
        {name: '6. '+i18n.t('program.outs.out'), value:6},
        {name: '7. '+i18n.t('program.outs.out'), value:7},
        {name: '8. '+i18n.t('program.outs.out'), value:8},
        {name: '9. '+i18n.t('program.outs.out'), value:9},
        {name: '10. '+i18n.t('program.outs.out'), value:10},
    ]

    export default {
        name: "Zone",
        components:{
            Multiselect,
            Modals: () => import('./index.vue')
        },
        data:function(){
           return{
               outNo:0,
               func:{},
               using: {},
               params1: "",
               params2: "",
               outNoList, funcList, usingList,
               modalOpen: false,
                currentForm: "Outputs",
                modalProps: {},
                modalName: null,
           } 
        },
         props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalEvent: Function
        },
        methods:{
            openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName = modal
                this.modalOpen = state
            },
        },
         watch: {
            save: async function(data){
                if(data){
                    this.openModal(true,'program', {out_no: this.outNo.value, name: ""})
                }
                this.saveState(false)

            }
         }
        
    }
</script>