<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <form ref="form">
            <validation-observer  ref="coupon">
                <validation-provider name="Kupon Tipi" :rules="{ required }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="type"> Kupon Tipi </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    id="type"
                                    v-model="type"
                                    :show-labels="false" 
                                    :options="payments"
                                    @input="selectType"
                                    label="title"
                                    track-by="id" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.title }} </strong> 
                                        </template>
                                </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider v-if="plans" name="Kupon Planı" :rules="{ required }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="plan"> Kupon Planı </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    id="plan"
                                    v-model="plan"
                                    :show-labels="false" 
                                    :options="plans"
                                    label="title"
                                    track-by="id" 
                                    :custom-label="couponLabel">
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.title + ' ('+ option.price + ' ' +option.currency +')'}} </strong> 
                                        </template>
                                </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Kupon Adeti" :rules="{ required }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="count"> Kupon Adeti </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="count" v-model="count" placeholder="Kupon Adeti" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </validation-observer>
        </form>
       
    </div>
</template>

<script>
    import i18n from '@/plugins/i18n';
    import Multiselect from 'vue-multiselect'

    export default {
        name: "Coupons",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        components:{
            Multiselect
        },
        data:function(){
           return{
               count: 0,
               plans: null,
               plan: null,
               type: null
           } 
        },
        computed:{
            loading(){
                return this.$store.getters.paymentLoading
            },
            payments(){
                return this.$store.getters.payments
            },
        },
        watch: {
            save: async function(data){
                if(data){
                    let params = {
                        payment_plan_id: this.type.id,
                        payment_plan_price_id: this.plan.id,
                        Amount: parseInt(this.count)
                    }
                    await this.$store.dispatch('couponRequest', params)
                    if(this.$store.state.Payment.paymentStatus && this.$store.state.Payment.paymentStatus.status== "Success"){
                        await this.$store.dispatch('codeRequests')
                        vm.$root.$bvToast.toast('Kuponlarınız başarıyla oluşturuldu', {
                            title: i18n.t('general.success'),
                            variant: 'success',
                            noAutoHide: true
                        })
                    }
                }
                this.saveState(false)
            }
        },
         methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            selectType: function(data){
                this.plans = data.prices
                this.plan = null
            },
            couponLabel(option) {
                console.log(option)
                return `${option.title} — [${option.price} ${option.currency}]`;
            }
        }
        
    }
</script>