<template>  
    <validation-observer style="width:100%" ref="register">
        <b-form>
            <validation-provider :name="$t('user.name')" :rules="{min:3}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group class="inputGrp" >
                            <b-input-group-prepend is-text>
                                <b-icon icon="person-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="name" v-model="name" type="text" required :placeholder="$t('user.name')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.mail')" :rules="{email, required}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group >
                            <b-input-group-prepend is-text>
                                <b-icon icon="at"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="mail" v-model="mail" type="text" required :placeholder="$t('user.mail')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.password')" :rules="{required}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group >
                            <b-input-group-prepend is-text>
                                <b-icon icon="lock-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input  id="pass" v-model="password" type="password" required :placeholder="$t('user.password')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                    <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.rePassword')" :rules="{required, is:password}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group >
                            <b-input-group-prepend is-text>
                                <b-icon icon="lock-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input  id="pass2" v-model="password2" type="password" required :placeholder="$t('user.rePassword')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                    <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.phone')" :rules="{required}" >
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group >
                            <vue-tel-input 
                                style="width:100%"
                                v-model="phone" 
                                :validCharactersOnly="true" 
                                @input="phoneValid" 
                                defaultCountry="tr" 
                            ></vue-tel-input>
                           
                        </b-input-group>
                    </b-row>
                    <b-form-invalid-feedback v-if="!phoneValidVal.valid" id="live-feedback" style="display: block; width: 100%">
                        {{$t('user.noValidPhone')}}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.dealer')">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-icon icon="tools"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="dealer" v-model="dealer" type="text" :placeholder="$t('user.dealer')" ></b-form-input>
                        </b-input-group>
                    </b-row>
                </b-form-group>
            </validation-provider>

            <div class="submitButton" variant="danger" @click="register"> {{$t('user.create')}} </div>
            <div class="registerLink" @click="changeForm(1)"> {{$t('user.alreadyRegistered')}} </div>
        </b-form>
    </validation-observer>
    
</template>

<style lang="css" scoped> 
    @import "../styles/login.min.css"; 
</style>
<script>
    import i18n from '@/plugins/i18n'
    export default {
        name: "LoginForm",
        data: function(){
            return{
                name: "",
                mail: "",
                password: "",
                password2: "",
                phone: "",
                dealer: "",
                phoneValidVal: {},

            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            phoneValid: function(val, obj){
                this.phoneValidVal = obj
                this.phone = obj.number
            },
            register: function(){
               this.$refs.register.validate().then( async success =>{
                    if(success){ 
                        if(this.password === this.password2){
                            let data = {
                                name: this.name,
                                email: this.mail,
                                password: this.password,
                                phone_no: this.phone,
                                dealercode: this.dealer
                            }
                            this.onRegister(data)
                        } else {
                            this.$bvToast.toast(i18n.t('messages.notEqualPwd'), {
                                    title: i18n.t('general.error'),
                                    variant: 'warning',
                                })
                        }
                    }else{
                        this.$bvToast.toast(i18n.t('messages.formError'), {
                                    title: i18n.t('general.error'),
                                    variant: 'warning',
                                })
                    }
                })
            }
        },
        props:{
            onRegister: Function,
            changeForm: Function,
        }
    }
</script>