<template>
    <div >
        {{paymentStatus}}
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <form ref="form">
            <validation-observer  ref="coupon">
                <validation-provider name="Kod" :rules="{ required }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="code"> {{$t('login.code')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="code" v-model="code" :placeholder="$t('login.code')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
            </validation-observer>
        </form>
       
    </div>
</template>

<script>
    import i18n from '@/plugins/i18n';

    export default {
        name: "Coupons",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        data:function(){
           return{
               code: ''
           } 
        },
        computed:{
            loading(){
                return this.$store.getters.paymentLoading
            },
              paymentStatus(){
                return this.$store.getters.getPaymentStatus
            },
        },
        watch: {
            save: async function(data){
                if(data){
                    let params = {
                        coupon_code: this.code
                    }
                    await this.$store.dispatch('couponOrder', params)
                    if(this.$store.state.Payment.paymentStatus && this.$store.state.Payment.paymentStatus.status== "Success"){
                        vm.$root.$bvToast.toast(i18n.t('payment.couponSuccess'), {
                            title: i18n.t('general.success'),
                            variant: 'success',
                            noAutoHide: true
                        })
                        await this.$store.dispatch('changeJWT')
                        this.$router.push({path: '/'})
                    }else{
                        vm.$root.$bvToast.toast(i18n.t('payment.couponError'), {
                            title: i18n.t('general.error'),
                            variant: 'danger',
                            noAutoHide: true
                        });
                    }
                }
                this.saveState(false)
            }
        },
         methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        }
        
    }
</script>