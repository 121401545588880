<template>
    <div >
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

        <b-table-simple v-else hover responsive>
                <b-th> İşlem </b-th>
            <b-tbody >
                <b-tr v-for="(od,i) in data.order_details" :key="i"> 
                   <b-td> {{ od.description}} </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "Codes",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        computed:{
            codes(){
                return this.$store.getters.getCouponList
            },
            loading(){
                return this.$store.getters.paymentLoading
            },
        },
        watch: {
            save: async function(data){
                this.saveState(false)
            }
        },
         methods:{
            moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY HH:mm:ss');
            },
        }
        
    }
</script>