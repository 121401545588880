<template>
      <div>
        <p> Bu aşamada karşılaştığınız sorunu açıklar mısınız? </p>
        <b-textarea rows="5" v-model="desc" />
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                desc: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                    let params = {
                        id: this.data.id,
                        description: this.desc,
                        result: false
                    }
                    await this.$store.dispatch('editTestResult', params)
                    await this.$store.dispatch('listFirmwareTestResult', {id: this.$route.params.id})

                }
                this.saveState(false)
                this.modalEvent(false)

            },
        }
    }
</script>