import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store';

import Home from '../pages/Home.vue'
import Users from '../pages/Users/List'
import Panels from '../pages/Panels/List.vue'
import PanelDetail from '../pages/Panels/Detail.vue'
import Login from '../pages/Users/Login'
import Help from '../pages/Help/'
import HelpList from '../pages/Help/List'
import Updates from '../pages/Help/Updates'
import PanelManage from '../pages/Panels/PanelManage'
import DeviceUser from '../pages/Users/DeviceUser'
import Errors from '../pages/Error/'
import Forbidden from '../pages/Error/Forbidden'
import Test from '../pages/Test'
import TestList from '../pages/Test/List'
import VersionList from '../pages/Test/Versions'
import StagesList from '../pages/Test/Stages'
import ModelList from '../pages/Test/Models'
import PanelTest from '../pages/Test/Test'
import PanelTestAdd from '../pages/Test/Add'
import Payment from '../pages/Payments'
import PaymentList from '../pages/Payments/List'
import PaymentProcess from '../pages/Payments/Payment'
import CouponRequest from '../pages/Payments/CouponRequest'
import CouponHistory from '../pages/Payments/CouponHistory'
import User from '../pages/Users/'
import UserEdit from '../pages/Users/Edit'
import Billing from '../pages/Payments/Billing'
import BillReport from '../pages/Payments/Report'
import Dealer from '../pages/Dealer/'
import DealerCreate from '../pages/Dealer/Create'
import DealerRequests from '../pages/Dealer/List'
import jwt_decode from "jwt-decode";

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/login', name: 'Login', component: Login, meta:{ menu_id: 0} },
    { path: '/Dealer', name: 'Dealer', component: Dealer, meta:{ menu_id: 0}, children: [
      { path: 'Create', name: 'DealerForm', component: DealerCreate, meta:{ menu_id: 0} },
      { path: 'Requests', component: DealerRequests, meta:{ menu_id: 2} },

    ]},
    { path: '/', name: 'Home', component: Home, meta:{ menu_id: 0}  },
    { path: '/Panels', name: 'Panels', component: Panels, meta:{ menu_id: 0}  },
    { path: '/PanelManage', name: 'PanelManage', component: PanelManage, meta:{ menu_id: 1}  },
    { path: '/PanelDetail/:id', name: 'PanelDetail', component: PanelDetail, meta:{ menu_id: 0}  },
    { path: '/Users', name: 'Kullanıcılar', component: Users, meta:{ menu_id: 1}  },
    { path: '/Panels/:dev_id/Users', name: 'Panel Kullanıcıları', component: DeviceUser, meta:{ menu_id: 1}  },
    { path: '/Help', name: 'Help', component: Help, meta:{ menu_id: 0}, children: [
      { path: 'List', component: HelpList, meta:{ menu_id: 0}  },
      { path: 'Updates', component: Updates, meta:{ menu_id: 2}  },
    ] },
    { path: '/PanelTest', name: 'Test', component: Test, meta:{ menu_id: 2}, children: [
      { path: 'List', component: TestList, meta:{ menu_id: 2}  },
      { path: 'Models', component: ModelList, meta:{ menu_id: 2}  },
      { path: ':id/Versions', component: VersionList, meta:{ menu_id: 2}  },
      { path: ':id/Stages', component: StagesList, meta:{ menu_id: 2}  },
      { path: 'Add', component: PanelTestAdd, meta:{ menu_id: 2}  },
      { name: "StartTest", path: ':id/test', component: PanelTest, meta:{ menu_id: 2}  },
    ]  },
    { path: '/Payment', name: 'Payment', component: Payment, meta:{ menu_id: 0}, children: [
      { path: 'Coupons', component: CouponRequest, meta:{ menu_id: 2}  },
      { path: 'History', component: CouponHistory, meta:{ menu_id: 1}  },
      { path: 'List', name:'PriceList', component: PaymentList, meta:{ menu_id: 0}  },
      { path: 'Billing', name:'Billing', component: Billing, meta:{ menu_id: 2}  },
      { path: 'Report', name:'BillReport', component: BillReport, meta:{ menu_id: 2}  },
      { path: ':id/:price/:type', name:'Pay', component: PaymentProcess, meta:{ menu_id: 0}  },
      
    ]  },
    { path: '/User', name: 'User', component: User, meta:{ menu_id: 0}, children: [
      { path: 'Edit', component: UserEdit, meta:{ menu_id: 0}  },    
    ]  },
    { path: '/Error', name: 'Error', component: Errors, meta:{ menu_id: 0}, children: [
      { path: 'Forbidden', component: Forbidden, meta:{ menu_id: 0}  },
    ],},
    { path: "*", component: Forbidden }         
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('access_token')  || to.name === 'Login' || to.name === 'DealerForm') {
      to.matched.some(async record => {
        let auth = false;
        let auth_id = 0
        try {
            if(localStorage.getItem('access_token')){
              auth = true 
              auth_id = jwt_decode(localStorage.getItem('access_token')).UserType
              if(Object.keys(store.getters.getAuthPlan).length  == 0)
                await store.dispatch('getAuthPlan')
              if(!store.getters.getAuthPlan.NOADVR && (to.name == 'Pay') ){
                return next()
              }
              if(!store.getters.getAuthPlan.NOADVR && (to.name !== 'PriceList') ){
                console.log(to)
                return next({name: 'PriceList', query:{type: 'noSub'}, replace:true})
              }
              
              if(auth){
                if(record.meta.menu_id <= auth_id){
                  if(to.name === "Home" && auth_id !== 2){
                  return next({ name: 'Panels' });
                  } else if(to.name === "PanelDetail" && !store.getters.getAuth.uname){
                  return next({ name: 'Panels' });
                  }else
                    return next()
                }else{
                  next({
                    path: '/Error/Forbidden'
                  });
                }
              }
            }else{
              next()
            }
           
           
        } catch (error) {
          console.log(error)
        }
        
        next()
        
      })
   
  }else {
    // Not logged in, redirect to login.
    next({name: 'Login'})
  }

})

export default router
