<template>
    <div >
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

        <b-table-simple v-else hover responsive>
                <b-th> {{$t('payment.couponCode')}} </b-th>
                <b-th> {{$t('general.active')}} </b-th>
                <b-th> {{$t('payment.usager')}} </b-th>
                <b-th> {{$t('payment.usageTime')}} </b-th>
            <b-tbody >
                <b-tr v-for="code in codes" :key="code.id"> 
                   <b-td> {{ code.code}} </b-td>
                   <b-td > 
                        <b-icon v-if="!code.is_used" icon="check" variant="success"/>
                        <b-icon v-else icon="x" variant="danger" />
                   </b-td>
                    <b-td> {{ code.used_user.name + ' - ' + code.used_user.email}} </b-td>
                    <b-td> {{ moment(code.used_time)}} </b-td>


                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "Codes",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        computed:{
            codes(){
                return this.$store.getters.getCouponList
            },
            loading(){
                return this.$store.getters.paymentLoading
            },
        },
        watch: {
            save: async function(data){
                this.saveState(false)
            }
        },
         methods:{
            moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY HH:mm:ss');
            },
        }
        
    }
</script>