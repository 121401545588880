var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-icon',{attrs:{"icon":"files","variant":"primary","animation":"throb","font-scale":"8"}}),_c('hr'),_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('general.sure'))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.data.panel.description)+" - "+_vm._s(_vm.data.panel.dev_id)+" "+_vm._s(_vm.$t('messages.movePanel'))+" ")]),(_vm.userList.length > 0)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('Multiselect',{attrs:{"show-labels":false,"options":_vm.userList,"label":"email","track-by":"id","custom-label":function (ref) {
	var name = ref.name;
	var email = ref.email;

	return (name + " - [" + email + "]");
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('strong',[_vm._v(_vm._s(option.name +' - '+ option.email)+" ")])]}}],null,false,3542726316),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }