<template>
    <form ref="form">
        <b-form-group>
            <b-row>
                <b-col sm="4"> <label>  {{$t('program.users.no')}} </label> </b-col>
                <b-col sm="8">
                    <Multiselect  
                        v-model="userNo"
                        :show-labels="false" 
                        :options="userNoList"
                        label="name"
                        track-by="value" >
                            <template slot="singleLabel" slot-scope="{ option }">
                                <strong>{{ option.name }} </strong> 
                            </template>
                    </Multiselect> 
                </b-col>
            </b-row>
        </b-form-group>
         <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />

    </form>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';
    
    let userNoList =[
        {name: '1. '+i18n.t('program.users.user'), value:1},
        {name: '2. '+i18n.t('program.users.user'), value:2},
        {name: '3. '+i18n.t('program.users.user'), value:3},
        {name: '4. '+i18n.t('program.users.user'), value:4},
        {name: '5. '+i18n.t('program.users.user'), value:5},
        {name: '6. '+i18n.t('program.users.user'), value:6},
        {name: '7. '+i18n.t('program.users.user'), value:7},
        {name: '8. '+i18n.t('program.users.user'), value:8},
        {name: '9. '+i18n.t('program.users.user'), value:9},
        {name: '10. '+i18n.t('program.users.user'), value:10},
        {name: '11. '+i18n.t('program.users.user'), value:11},
        {name: '12. '+i18n.t('program.users.user'), value:12},
        {name: '13. '+i18n.t('program.users.user'), value:13},
        {name: '14. '+i18n.t('program.users.user'), value:14},
        {name: '15. '+i18n.t('program.users.user'), value:15},
        {name: '16. '+i18n.t('program.users.user'), value:16},
        {name: '17. '+i18n.t('program.users.user'), value:17},
        {name: '18. '+i18n.t('program.users.user'), value:18},
        {name: '19. '+i18n.t('program.users.user'), value:19},
    ]
 
    export default {
        name: "Users",
        components:{
            Multiselect, 
            Modals: () => import('./index.vue')
        },
        data:function(){
           return{
               userNo:{},
               userNoList,
               modalOpen: false,
                currentForm: "Users",
                modalProps: {},
                modalName: null,
           } 
        },
         props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalEvent: Function
        },
        methods:{
            openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName = modal
                this.modalOpen = state
            },
        },
         watch: {
            save: async function(data){
                if(data){
                    this.openModal(true,'program', {user_no: this.userNo.value, name: ""})
                }
                this.saveState(false)

            }
         }
        
    }
</script>