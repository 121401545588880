<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
         <form v-else ref="form" >
            <b-tabs >
                <validation-observer  ref="UserPRG">
                    <b-tab :title="$t('program.users.tab1')" >
                        <b-card-text style="margin-top:1rem">
                            <validation-provider :name="$t('program.users.userName')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.users.userName')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="userName" :placeholder="$t('program.users.userName')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.users.phone')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.users.phone')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="phone" :placeholder="$t('program.users.phone')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.users.cardNo')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.users.cardNo')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cardNo" :placeholder="$t('program.users.cardNo')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title="$t('program.users.tab2')" >
                        <b-card-text style="margin-top:1rem">
                            <validation-provider :name="$t('program.users.keypads')" :rules="{ required: true}" v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.users.keypads')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                v-model="keypad"
                                                :show-labels="false" 
                                                :options="keypads"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                <template slot="singleLabel" slot-scope="{ option }">
                                                    <strong>{{ option.name }} </strong> 
                                                </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>

                            <b-form-group>
                                <b-row>
                                    <b-col sm="4">{{$t('program.users.armDisarm')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="armDisarm"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="armDisarm"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>
                            
                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.doorAuth')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="openDoor"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="openDoor"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>

                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.manageAuth')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="managing"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="managing"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>

                            <b-form-group>
                                <b-row>
                                    <b-col sm="4">{{$t('program.users.internetAuth')}}</b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="internet"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="internet"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>

                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.smsAuth')}}</b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="sms"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="sms"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>
                            
                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.installerAuth')}} </b-col>
                                    <b-col sm="8">
                                        <b-form-radio-group
                                            v-model="setting"
                                            :options="existings"
                                            :aria-describedby="ariaDescribedby"
                                            name="setting"
                                            :disabled="true"
                                        />
                                    </b-col>
                                
                                </b-row>
                            </b-form-group>
                            
                        </b-card-text>
                    </b-tab>
                    <b-tab :title="$t('program.users.tab3')" >
                        
                        <b-card-text style="margin-top:1rem">
                            <validation-provider :name="$t('program.users.authDay')" :rules="{ required: true}" v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.users.authDay')}} </label> </b-col>
                                        <b-col sm="8"> 
                                             <Multiselect  
                                                v-model="days"
                                                :show-labels="false" 
                                                :options="dayList"
                                                :multiple="true"
                                                label="name"
                                                track-by="id" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.name }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>

                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.startTime')}}</b-col>
                                    <b-col sm="8">
                                        <Datetime v-model="beginTime" type="time"></Datetime>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group>
                                <b-row>
                                    <b-col sm="4"> {{$t('program.users.endTime')}} </b-col>
                                    <b-col sm="8">
                                        <Datetime v-model="endTime" type="time" ></Datetime>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card-text>
                    </b-tab>
                </validation-observer>

            </b-tabs>
        
        
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import { Datetime } from 'vue-datetime';
    import moment from 'moment'
    import i18n from '@/plugins/i18n';
    
    let keypads = [
        { id: 1, name: '1. Keypad' },
        { id: 2, name: '2. Keypad' },
        { id: 3, name: '3. Keypad' },
        { id: 4, name: '4. Keypad' }
    ]

    export default {
        name: "Zone",
        components:{
            Multiselect, Datetime
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 2,
                member: this.data.user_no,
                user_pass: this.$store.getters.getPWD,
                offset: 0,
                list_id: this.data.id ? this.data.id : -1
            }
            await this.$store.dispatch('panelSettings', params)
            await this.$store.dispatch('panelUsers', {id: this.$route.params.id}) 

        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
             loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
        },
         watch:{
            settings: function(newVal, oldVal) { 
                console.log("watch", Object.values(newVal.days))// watch it
                this.userName = newVal.uname
                this.phone = newVal.phone
                this.cardNo = newVal.card_id
                this.armDisarm = newVal.auth.arm_disarm
                this.openDoor = newVal.auth.door_open
                this.managing = newVal.auth.manage
                this.internet = newVal.auth.internet
                this.sms = newVal.auth.sms
                this.setting = newVal.auth.installer
                this.beginTime = newVal.startTime.hour+":"+newVal.startTime.minute
                this.endTime = newVal.endTime.hour+":"+newVal.endTime.minute
                this.daysArr =   Object.values(newVal.days);
                this.keypadArr = newVal.keypad.split("")
                this.id = newVal.id 
            },
            daysArr: function(newVal, oldVal){
                this.days = [] 
                newVal.map((day,i) => {
                    day && this.days.push(this.dayList[i])
                })
            },
            keypadArr: function(newVal, oldVal){
                this.keypad = []
                newVal.map((keypad,i) => {
                    keypad && i < 4 && this.keypad.push(this.keypads[i])
                })
            },
             save: async function(data){
                if(data){
                    let keypads = this.keypadCount(this.keypad)
                    let keypadByte = this.arrToStrByte(keypads)
                    this.$refs.UserPRG.validate().then( async success =>{
                        if(success){
                            let params = {
                                id: this.id,
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 2,
                                member: this.data.user_no,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                user_no: this.data.user_no,
                                uname:this.userName,
                                phone:this.phone,
                                auth:{
                                    arm_disarm: this.armDisarm,
                                    door_open: this.openDoor,
                                    manage: this.managing,
                                    installer: this.setting,
                                    sms: this.sms,
                                    internet: this.internet
                                },
                                card_id: this.cardNo,
                                keypad: keypadByte,
                                days:{
                                    monday: this.days.findIndex(e => e.id == 1) > -1 ? true : false,
                                    tuesday: this.days.findIndex(e => e.id == 2) > -1 ? true : false,
                                    wednesday: this.days.findIndex(e => e.id == 3) > -1 ? true : false,
                                    thursday: this.days.findIndex(e => e.id == 4) > -1 ? true : false,
                                    friday: this.days.findIndex(e => e.id == 5) > -1 ? true : false,
                                    saturday: this.days.findIndex(e => e.id == 6) > -1 ? true : false,
                                    sunday: this.days.findIndex(e => e.id == 7) > -1 ? true : false
                                },
                                start_time: {
                                    hour: moment(this.beginTime).format('HH'),
                                    minute: moment(this.beginTime).format('mm'),
                                },
                                end_time: {
                                    hour: moment(this.endTime).format('HH'),
                                    minute: moment(this.endTime).format('mm'),
                                },
                            }
                            await this.$store.dispatch('setUserSettings', params)
                            await this.$store.dispatch('panelUsers', {id: this.$route.params.id})
                        }else{
                             vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                    this.saveState(false)

                }
             }
        },
        data:function(){
           return{
               userName: "",
               id: 0,
               setting: 1,
               keypad: [],
               days: [],
               daysArr: [],
               keypadArr: [],
               keypads,
               existings: [
                    { text: i18n.t('program.general.unavailable'), value: false},
                    { text: i18n.t('program.general.available'), value: true},
                ], dayList : [
                    { id: 1, name: i18n.t('program.general.monday') },
                    { id: 2, name: i18n.t('program.general.tuesday') },
                    { id: 3, name: i18n.t('program.general.wednesday') },
                    { id: 4, name: i18n.t('program.general.thursday') },
                    { id: 5, name: i18n.t('program.general.friday') },
                    { id: 6, name: i18n.t('program.general.saturday') },
                    { id: 7, name: i18n.t('program.general.sunday') },
                ]
           } 
        },
         methods:{
             getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            keypadCount: function(arr){
                let val = arr.map(function(item) {
                    return item['id'];
                });
                return val
            },
            arrToStrByte: function(arr){
                let val = ""
                for(let i = 0; i<4; i++){
                    let isSelect = arr.find(e => e == i+1);
                    isSelect ? val = val + "11" : val = val+"00";
                }
                return val;                
            }
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        }
        
    }
</script>