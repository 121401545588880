<template>
      <div>
        <b-icon icon="check" variant="success" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.success')}} </h3>
        <p> {{$t('user.dealers.success')}}</p>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                this.saveState(false)
                this.modalEvent(false)
                window.location.href = '/'
            },
        }
    }
</script>