<template>
    <div >
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>

        <b-table-simple v-else hover responsive>
                <b-th> {{$t('program.zones.no')}} </b-th>
                <b-th> {{$t('program.zones.name')}} </b-th>
                <b-th> {{$t('program.zones.bypass')}} </b-th>
                <b-th> {{$t('program.zones.state')}} </b-th>
                <b-th> {{$t('general.edit')}} </b-th>
            <b-tbody >
                <b-tr v-for="zone in zones" :key="zone.id" :title="zone.byp_title" :style="!zone.active && 'background: #343a40; color: #fff'"> 
                   <b-td> {{ zone.zone_no}} </b-td>
                   <b-td > {{ zone.name}}  </b-td>
                   <b-td> <b-form-checkbox v-if="zone.active" v-model="zone.byp" name="check-button" switch size="lg" @change="sendBypass(zone)" /> </b-td>
                   <b-td> 
                        <b-badge v-if="zone.zone_status == 1" variant="danger"> Hareket </b-badge>
                        <b-badge v-if="zone.zone_byp_status == 2" variant="warning"> Elle Bypass Edildi</b-badge>
                        <b-badge v-else-if="zone.zone_byp_status == 1" variant="primary"> Otomatik Bypass Edildi</b-badge>
                   </b-td>
                    <b-td> <b-link style="color: #007bff" @click="edit(zone)" v-b-modal.edit> {{$t('general.edit')}}  </b-link></b-td>

                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-modal id="edit" :title="selectedZone.zone_no+' - '+selectedZone.name+' '+$t('general.edit')" :ok-title="$t('general.edit')" :cancel-title="$t('general.cancel')" @ok="editZone">
             <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="name"> {{$t('program.zones.name')}} </label> </b-col>
                    <b-col sm="8"> 
                        <b-input :placeholder="$t('program.zones.name')" v-model="selectedZone.name"  />
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="name"> {{$t('program.zones.state')}} </label> </b-col>
                    <b-col sm="8"> 
                        <b-form-radio-group
                        id="state"
                        v-model="selectedZone.active"
                        :options="states"
                        aria-describedby="live-feedback"></b-form-radio-group>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
    import i18n from '@/plugins/i18n';
    
    export default {
        name: "Zones",
        data: function(){
            return{
                zoneBypass: [],
                selectedZone: {},
                states: [
                    { text: i18n.t('program.general.active'), value: true},
                    { text: i18n.t('program.general.passive'), value: false},
                ],
            }
        },
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        computed:{
            zones(){
                return this.$store.getters.getZones
            },
            loading(){
                return this.$store.getters.getPanelSettingLoading
            },
        },
        watch: {
            save: async function(data){
                this.saveState(false)
            },
            zones: function(data){
                //this.zoneBypass.push({id: zones.id, status: zones.zone_byp_status > 0 ? true : false })
                data.map(zone => {
                    zone.byp = zone.zone_byp_status > 0 ? true : false
                    zone.byp_title = zone.zone_byp_status == 2 ? "Otomatik Bypass Edildi" : zone.zone_byp_status == 1 ? "Elle Bypass Edildi" : ""
                })
            }
        },
        methods:{
            sendBypass: async function(data){
                let params = {
                    dev_id: parseInt(this.data.id),
                    command: 'BYPASS',
                    zoneoutno: data.zone_no,
                    status: data.byp
                }
                await this.$store.dispatch('panelCommand', params)
                if(this.$store.getters.getCommand.Err !== 0){
                    data.byp = !data.byp
                }
                this.$store.dispatch('panelZones', this.data)

            },
            edit: function(zone){
                this.selectedZone = zone
            },
            editZone: async function(){
                let data = {
                    device_id: parseInt(this.selectedZone.dev_id),
                    zone_id: parseInt(this.selectedZone.id),
                    active: this.selectedZone.active,
                    name: this.selectedZone.name
                }
                await this.$store.dispatch('sendZone', data)
                this.$store.dispatch('panelZones', this.data)


            }
        }
        
    }
</script>