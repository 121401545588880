<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="firmwares" :filtered="filtered" type="List" :loading="loading"  :actions="actions" :actionEvent="actionEvent" />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";

    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
        { label: 'Versiyon Ekle', url: "" },
    ]
     let fields = [ 
         { key: 'version', label: 'Versiyon', keys:['firm_ver']},
         { key: 'description', label: 'Açıklama', keys:['description'] },
         { key: 'download', label: 'indir', keys:['url'] },
         { key: 'actions', label: 'İşlemler', }        
    ]; 

    let actions = [
        {key: 'edit', text: 'Versiyon Düzenle'},
        {key: 'delete', text: 'Versiyon Sil'},
        {key: 'addVersion', text: 'Yeni Versiyon Ekle'},
    ]

    

    let filtered = ['version', "description", 'download', 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listFirmwares', {id: this.$route.params.id})
        },
        computed:{
            firmwares(){
                return this.$store.getters.getFirmwares.data
            },
        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"Version", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                },
               

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
           
            actionEvent: async function(key, data){
                switch(key){
                    case 'addVersion':
                         this.openModal(true, 'addVersion', data)
                        break;
                    case 'edit':
                         this.openModal(true, 'edit', {version: data})
                        break;
                    case 'report': 
                        this.openModal(true, 'report', {version: data})
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  {version: data})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>