import axios from "axios";
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_YANDEX_DISK_API_URL
});
axiosInstance.interceptors.request.use(config => {
    //Tabiki burada token değerini local yada session storage gibi bir yerden okumamız gerekebilir
    config.headers["Authorization"] = "AQAEA7qjdPE8AADLW2JN7wLom0has2IGlgairQo";
    return config;
});

axiosInstance.interceptors.response.use(undefined, async function axiosRetryInceptors(err) {
     //Token Takibi
     const originalRequest = err.config
     console.log(err.response)
     if(err.response.status !== 200 && err.response.status !== 201 ){
        console.log(err)
        vm.$root.$bvToast.toast(err.response.data.description, {
            title: `Hata! `,
            variant: 'danger',
            noAutoHide: true
          })
          return err.config;
    }  else 
    return err.config;
});
export default axiosInstance
/**/
