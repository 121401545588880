import vue from "vue"
import {stopTimeout, startTimeout} from '../../../assets/js/timing'
// Actiondan gelen datalar ile stateler güncellenecek
export const panelLoading = (state, payload) => {
    state.loading = payload
}

export const panels = (state, payload) => {
    let newPanels = []
    if(payload.data ){
        if (payload.offset == 0)
            state.panels = payload.data
        else {
            newPanels = state.panels.concat(payload.data.filter(item => state.panels.indexOf(item)<0))
            state.panels = newPanels
        }
    }
}

export const allPanels = (state, payload) => {
    if(payload.data)
        state.allPanels = payload.data
    else
        state.allPanels = []
    state.pageInfo = payload.list_info
}

export const panelStatus = (state, payload) => {
    if(payload.data && payload.data.status == "Success"){
        vm.$root.$bvToast.toast("İşlem Başarıyla Gerçekleşti", {
            title: `Başarılı! `,
            variant: 'success',
        })
    }
    state.status = payload.data ? payload.data : {}
}

export const zones = (state, payload) => {
    state.zones = payload.data
}

export const outs = (state, payload) => {
    state.outs = payload.data
}

export const panelDetail = (state, payload) => {
    state.detail = payload.data
}

export const users = (state, payload) => {
    state.users = payload.data
}

export const settings = (state, payload) => {
    if(payload.data){
        if(payload.data.err){
            vm.$root.$bvToast.toast("Komut panele gönderilirken bir hata oluştu", {
                title: `Hata! `,
                variant: 'danger',
                noAutoHide: true
            })
            state.settings = {}
            state.auth = {}
        }else if(payload.data.command == "USER"){
            startTimeout();
            state.auth = payload.data
        }
        state.settings = payload.data
    }else{
        state.settings = {}
        state.auth = {}
    }
   
}
export const settingStatus = (state, payload) => {
    state.settingStatus = payload.data
}

export const keypads = (state, payload) => {
    state.keypads = payload.data
}

export const alarmDesc = (state, payload) => {
    state.descriptions = payload.data
}

export const siaCodes = (state, payload) => {
    state.siaCodes = payload.data
}

export const addPanel = (state, payload) => {
    state.panel = payload.data
}
export const behaviorList = (state, payload) => {
    state.behaviors = payload.data
}

export const zoneConnectTypeList = (state, payload) => {
    state.connectTypes = payload.data
}

export const zoneTypeList = (state, payload) => {
    state.zoneTypes = payload.data
}

export const outFuncTypeList = (state, payload) => {
    state.outFuncs = payload.data
}

export const timedArmActList = (state, payload) => {
    state.timedArms = payload.data
}

export const autoArmList = (state, payload) => {
    state.autoArms = payload.data
}
export const copyPanel = (state, payload) => {
    console.log(payload)
    if(payload.status = "Success"){
        vm.$root.$bvToast.toast("İşlem Başarıyla Gerçekleşti", {
            title: `Başarılı! `,
            variant: 'success',
        })
    }
    state.status = payload
}