<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="testList" :filtered="filtered" type="List" :loading="loading"  :actions="actions" :actionEvent="actionEvent"
          :goDetail="goDetail" addUrl="PanelTest/Add" buttonUrl="PanelTest/Models" buttonName="Modeller"/>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";

    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
    ]
     let fields = [ 
         { key: 'version', label: 'Versiyon', keys:['firmware']},
         { key: 'model', label: 'Model', keys:['model_name'] },
         { key: 'user', label: 'Sorumlu', keys:['user_name'] },
         { key: 'percentage', type: "percent", label: 'Test Durumu', keys:['percentage'] },
         { key: 'date', type: "date", label: 'Tarih', keys:['add_time'] },
         { key: 'actions', label: 'İşlemler', }        
    ]; 

    let actions = [
        {key: 'test', text: 'Teste Başla'},
        {key: 'report', text: 'Test Raporu Al'},
    ]

    let operations = [
        {key: 'versions', text: 'Versiyonlar'},
        {key: 'addTest', text: 'Test aşaması Ekle'},
    ]

    let filtered = ['version', "user", "model", 'connection', 'percentage',  'date', 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listFirmwareTest', this.params)
        },
        computed:{
            testList(){
                return this.$store.getters.getFirmwareTests.data
            },
            /*pageInfo(){
                return this.$store.getters.getUserPageInfo
            }*/

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, operations, modalOpen: false,
                modalProps: {}, currentForm:"Test", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                },

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
           
            actionEvent: async function(key, data){
                switch(key){
                     case 'test':
                        this.$router.push( { path: '/PanelTest/'+data.id+'/test', name:"StartTest", params:{id: data.id, test: data}})
                        break;
                    case 'report': 
                        this.openModal(true, 'report', {version: data})
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  {version: data})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>