<template>
    <div class="sidebar left " :class="{fliph: collapsed}" >       
        <div class="user-panel">
            <div class="pull-left image">
            <img src="@/assets/img/logo.png" class="logo" alt="Alarm Panelim">
            <hr>
            </div>
        </div>
        <ul class="list-sidebar">
            <li>
                <span>
                     <router-link to="/" tag="a" :title="$t('menus.home')" >  
                        <b-icon icon="grid3x3-gap-fill" class="color-1"></b-icon> 
                        <span class="nav-label"> {{$t('menus.home')}} </span>
                    </router-link>
                </span>
            </li>
            <li>
                <span>
                     <router-link to="/Panels" tag="a" :title="$t('menus.panels')" >  
                        <b-icon icon="shield-shaded" class="color-2"></b-icon> 
                        <span class="nav-label"> {{$t('menus.panels')}} </span>
                    </router-link>
                </span>
            </li>
            <li v-if="getRole() > 0">
                <span>
                     <router-link to="/Users" tag="a" :title="$t('menus.users')" >  
                        <b-icon icon="people-fill" class="color-3"></b-icon> 
                        <span class="nav-label"> {{$t('menus.users')}} </span>
                    </router-link>
                </span>
            </li>
            <li v-if="getRole() > 0">
                <span>
                     <router-link to="/PanelManage" tag="a" :title="$t('menus.managePanel')" >  
                        <b-icon icon="toggles" class="color-4"></b-icon> 
                        <span class="nav-label"> {{$t('menus.managePanel')}} </span>
                    </router-link>
                </span>
            </li>
             <li v-if="getRole() > 1">
                <span>
                     <router-link to="/Dealer/Requests" tag="a" :title="$t('menus.dealerRequests')" >  
                        <b-icon icon="briefcase-fill" class="color-5"></b-icon> 
                        <span class="nav-label"> {{$t('menus.dealerRequests')}} </span>
                    </router-link>
                </span>
            </li>
             <li v-if="getRole() > 1">
                <span>
                     <router-link to="/PanelTest/List" tag="a" :title="$t('menus.test')" >  
                        <b-icon icon="ui-checks" class="color-6"></b-icon> 
                        <span class="nav-label"> {{$t('menus.test')}} </span>
                    </router-link>
                </span>
            </li>
            <li>
                <span>
                     <router-link to="/Payment/List" tag="a" :title="$t('menus.subs')" >  
                        <b-icon icon="credit-card" class="color-7"></b-icon> 
                        <span class="nav-label"> {{$t('menus.subs')}} </span>
                    </router-link>
                </span>
            </li>
             <li v-if="getRole() > 1">
                <span>
                     <router-link to="/Payment/Billing" tag="a" :title="$t('menus.billing')" >  
                        <b-icon icon="file-earmark-text-fill" class="color-8"></b-icon> 
                        <span class="nav-label"> {{$t('menus.billing')}} </span>
                    </router-link>
                </span>
            </li>
            <li v-if="getRole() > 1">
                <span>
                     <router-link to="/Payment/Coupons" tag="a" :title="$t('menus.coupons')" >  
                        <b-icon icon="tags" class="color-9"></b-icon> 
                        <span class="nav-label"> {{$t('menus.coupons')}} </span>
                    </router-link>
                </span>
            </li>
            <li v-if="getRole() > 0">
                <span>
                     <router-link to="/Payment/History" tag="a" :title="$t('menus.orderHistory')" >  
                        <b-icon icon="tag" class="color-10"></b-icon> 
                        <span class="nav-label"> {{$t('menus.orderHistory')}} </span>
                    </router-link>
                </span>
            </li>
            <li>
                <span>
                     <router-link to="/Payment/Coupons" tag="a" :title="$t('menus.subsHistory')" >  
                        <b-icon icon="person-lines-fill" class="color-11"></b-icon> 
                        <span class="nav-label"> {{$t('menus.subsHistory')}} </span>
                    </router-link>
                </span>
            </li>
            <li v-if="getRole() > 1">
                <span>
                     <router-link to="/Help/Updates" tag="a" :title="$t('menus.firmwares')" >  
                        <b-icon icon="question-octagon-fill" class="color-1"></b-icon> 
                        <span class="nav-label"> {{$t('menus.firmwares')}} </span>
                    </router-link>
                </span>
            </li>
            <li>
                <span>
                     <router-link to="/Help/List" tag="a" :title="$t('menus.help')" >  
                        <b-icon icon="question-octagon-fill" class="color-2"></b-icon> 
                        <span class="nav-label"> {{$t('menus.help')}} </span>
                    </router-link>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
    import jwt_decode from "jwt-decode";

    export default {
        name: "DashboardMenu",
        props:{
            collapsed: Boolean
        },
        methods:{
            getRole: function(){
                return jwt_decode(localStorage.getItem('access_token')).UserType
            },
            accordionMenu : (key, collapsed, type, child) => {
                if(child>0){
                    let clsList = document.getElementById(key).classList;
                    let element = document.getElementById(key);
                    let parentLi = element.parentNode;
                 if(!collapsed && type == "click"){
                        parentLi.classList.contains("active") ? parentLi.classList.remove("active") : parentLi.classList.add("active")
                        element.classList.contains("show") ? element.classList.remove("show"): element.classList.add("show")
                    }else if(collapsed){
                        type == 'leave' ? clsList.remove("show") : clsList.add("show")
                        parentLi.classList.remove("active") 
                    }
                }
                
            
            }
        }
    }
</script>

<style lang="css" scoped>
    @import "./styles/dashboardMenu.min.css";
</style>