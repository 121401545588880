<template>
    <b-modal :scrollable="true" ref="modal" :title="title" @hide="onHide" @cancel="onCancel" :centered="true" :visible="open" :ok-title="okTitle"
     :cancel-title="$t('general.cancel')" :size="size" @ok="saveModal" :ok-disabled="!saveState" :static="true">
            <component v-bind:is="renderModalForm" :save="saveAct" :data="modalProps" :changeVal="changeDisabled" :changeData="changeData" 
            :saveState="saveState" :modalName="modalName" :modalEvent="modalEvent" :formName="form" :onComplete="onComplete"></component>
    </b-modal>
</template>

<script>
    import ProsecPanel from "./ProsecPanel"
    import Delete from "./Delete"
    import Zones from "./Zones"
    import Outs from "./Outs"
    import ChangePanel from "./ChangePanel"
    import i18n from '@/plugins/i18n';
    import Zone from './Zone'
    import ZonePRG from './ZonePRG'
    import Outputs from './Outputs'
    import OutputsPRG from './OutputsPRG'
    import Users from './Users'
    import UsersPRG from './UsersPRG'
    import Keypads from './Keypads'
    import KeypadsPRG from './KeypadsPRG'
    import AlarmSettingsPRG from './AlarmSettingsPRG'
    import SIAEventPRG from './SIAEventPRG'
    import Password from './Password'
    import AutomationPRG from './AutomationPRG'
    import TimeArmDisarmPRG from './TimeArmDisarmPRG'
    import PanelUserChangePwd from './PanelUserChangePwd'
    import SetStatePanel from './SetStatePanel'
    import CopyPanel from './CopyPanel'
    import ResetPanel from './ResetPanel'



    export default {
        name: 'Modals',
        data: function(){
             return{
                title: '',
                okTitle: i18n.t('general.save'),
                disabled: false,
                saveAct: false,
                size: 'lg',
            }
        }, components:{
            ProsecPanel, ChangePanel, Delete, Zones, Outs, Zone, ZonePRG, Outputs, OutputsPRG, Users, UsersPRG, Keypads, KeypadsPRG,
            AlarmSettingsPRG, SIAEventPRG, Password, AutomationPRG, TimeArmDisarmPRG, PanelUserChangePwd, SetStatePanel, CopyPanel, ResetPanel
        },
        props:{
            open: Boolean,
            modalEvent: Function,
            form: String,
            modalName: String,
            modalProps: Object,
            onComplete: Function
        },
        computed:{
            //Açılacak Modalı belirleme işlemleri
            renderModalForm: function(){
                this.okTitle= i18n.t('general.save');
                this.size = 'lg'
                switch(this.form){
                    case "ProsecPanel":
                        switch(this.modalName){
                            case "changeDesc":
                                this.title = i18n.t('menus.changePanel')
                                return "ChangePanel"
                            case "delete":
                                this.title = i18n.t('menus.removePanel')
                                this.okTitle = i18n.t('menus.removePanel')
                                return "Delete"
                            case "zones":
                                this.title = i18n.t('menus.zoneList')
                                this.okTitle = i18n.t('general.close')
                                this.$store.dispatch('panelZones', this.modalProps)
                                return "Zones" //return "Zones"
                            case "outs":
                                this.title = i18n.t('menus.outList')
                                this.okTitle = i18n.t('general.close')
                                this.$store.dispatch('panelOuts',this.modalProps)
                                return "Outs"//"Outs"
                            case "password":
                                if(this.modalProps.form == 'ARM'){
                                    this.title = this.modalProps.panel.dev_id +' - '+ this.modalProps.panel.description
                                    this.okTitle = i18n.t('commands.arm')
                                }else if(this.modalProps.form == 'STAYARM'){
                                    this.title = this.modalProps.panel.dev_id +' - '+ this.modalProps.panel.description
                                    this.okTitle = i18n.t('commands.stayarm')
                                }else if(this.modalProps.form == 'DISARM'){
                                    this.title = this.modalProps.panel.dev_id +' - '+ this.modalProps.panel.description
                                    this.okTitle = i18n.t('commands.disarm')
                                }else{
                                    this.title = i18n.t('program.login.title')
                                this.okTitle = i18n.t('program.login.btn')
                                }
                                
                                this.size = "md"
                                return "Password"
                        }
                    case "PanelManage":
                        switch(this.modalName){
                            case "SetState":
                                this.title = this.modalProps.panel.dev_id+" - "+this.modalProps.panel.description
                                return "SetStatePanel"
                            case "CopyPanel":
                                this.title = this.modalProps.panel.device_info.dev_id+" - "+this.modalProps.panel.description
                                this.okTitle = i18n.t('panelManage.move')
                                this.$store.dispatch('listUser',{limit: 1000, ofset: 0})
                                this.size = 'xl'
                                return "CopyPanel"
                            case "ResetPanel":
                                this.title = 'Panel Kart Sıfırlama'
                                this.okTitle = 'Sıfırla'
                                return "ResetPanel"

                        }
                }
                   if(this.modalName === "add"){
                        this.title = i18n.t('general.add')
                        this.okTitle = i18n.t('general.add')
                        return this.form
                    } else if (this.modalName === "program"){
                        this.okTitle = i18n.t('general.save')
                        if(this.form == "Zone")
                            this.title = this.modalProps.zone_no+1+" - "+this.modalProps.name+" "+i18n.t('general.edit')
                        else if(this.form == "Users"){
                            if(this.modalProps.user_no == 0)
                                this.title = "[PRG] - "+this.modalProps.name+" "+i18n.t('general.edit')
                            else
                                this.title = this.modalProps.user_no+" - "+this.modalProps.name+" "+i18n.t('general.edit')
                        } else if(this.form == "AlarmSettings"){
                            this.title = this.modalProps.Description+" "+i18n.t('general.edit')
                        } else if(this.form == "Outputs"){
                            this.title = this.modalProps.out_no+ " - "+this.modalProps.name+" "+i18n.t('general.edit')
                        }
                        else if(this.form == "Keypads")
                            this.title = this.modalProps.keypad_no+" - "+this.modalProps.name+" "+i18n.t('general.edit')
                        else{
                            this.title = this.modalProps.Description+" "+i18n.t('general.edit')
                        }
                        this.okTitle = i18n.t('general.edit')
                        return this.form+"PRG"
                    }else if(this.modalName == "delete"){
                        this.okTitle = i18n.t('general.delete')
                        return "Delete"
                    }else if(this.modalName == "changePwd"){
                        this.okTitle = i18n.t('general.save')
                        return "PanelUserChangePwd"
                    }
                return ""
            },
        },
        methods:{
            
            onHide: function(){
                if(!this.saveAct)
                    this.modalEvent(false)
            },
            onCancel: function(){
                this.saveState(false)
                if(!this.saveAct)
                    this.modalEvent(false)

            },
            saveState: function(val){
                this.saveAct = val
            },
            // Modal tamamlandığı zaman olacak event
            saveModal: async function(bvModalEvt){
                bvModalEvt.preventDefault()
                this.saveState(true)
            },
            changeData(val, name){
                this.modalProps[name] = val
            },
            changeDisabled(val){
                if(val == this.modalProps.name)
                    this.disabled = false
            }
        }
    }
</script>
