<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <div>
            <span> Raporlamak istediğiniz tarih aralığını girerek rapor oluşturabilirsiniz. Eğer girdiğiniz tarih aralığı 500'den fazla sipariş içeriyorsa yalnızca ilk 500 sipariş görüntülenecektir. </span>
            <div style="display:flex; flex-direction: :row; flex-wrapper: wrap; margin-top: 25px; ">
                <div class="filterInputs">
                    <label> Başlangıç Tarihi </label>
                    <Datetime  class="filterInput" v-model="params.begin_date" ></Datetime>
                </div>
                <div class="filterInputs">
                    <label> Bitiş Tarihi </label>
                    <Datetime  class="filterInput" v-model="params.end_date" ></Datetime>
                </div>
                <div style="margin-bottom: 1rem; margin-left: 1rem;">
                    <b-button variant="primary" @click="getReport"> Rapor Al </b-button>
                </div>
            </div>              
        </div>
        <div class="multiItems">
                 <b-card body-class="noScroll" :class="'item summary '+getVariant(pR)" v-for="pR in paymentReport" :key="pR.id">
                    <div class="cardDesc">
                        <div class="cardTitle"> 
                            {{getMonthName(pR)}}
                        </div>
                        <div class="cardDesc">
                            <div class="information"> 
                                <span class="small"> Toplam Sipariş Tutarı: </span>
                                <span>  {{pR.total_price}} TL </span>
                            </div>
                            <div class="information"> 
                                <a class="small" @click="getReportRecords(pR)"> Siparişleri Görüntüle </a>
                            </div>
                        </div>
                    </div>
                </b-card>
        </div>
        <div class="multiItems">
            <b-card style="margin: 1rem">
                <LineChartGenerator
                    :chart-options="{responsive:true}"
                    :chart-data="chartData"
                />
            </b-card>
            <b-card  style="margin: 1rem">
                <Bar
                    :chart-options="{responsive:true}"
                    :chart-data="chartData"
                />
            </b-card>
            <b-card  style="margin: 1rem">
                <Doughnut
                    :chart-options="{responsive:true}"
                    :chart-data="chartData"
                />
            </b-card>
        </div>
        <Table v-if="records.length > 0" :fields="fields" :items="records" :filtered="filtered" type="List" :loading="loading" />
     </div>
</template>
<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";
    import { Datetime } from 'vue-datetime';
    import moment from 'moment';
    import { Line as LineChartGenerator, Bar, Doughnut } from 'vue-chartjs/legacy'
    import { Chart as ChartJS,   CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement, ArcElement } from 'chart.js'
    ChartJS.register(  CategoryScale, LinearScale, PointElement, LineElement, Title,  Tooltip, Legend, BarElement, ArcElement)
    let breadCrumb = [
        { label: 'Faturalandırmalar', url: "/Payment/Billing" },
        { label: 'Rapor', url: "" },
    ]
     let fields = [ 
        { key: 'date', type:'date', label: i18n.t('payment.createDate'), keys:['created_at']},
         { key: 'month', label: i18n.t('payment.duration'), keys:['sub_month'] },
         { key: 'amount', label: i18n.t('payment.amount'), keys:['amount'] },
         { key: 'bill', type: 'badge', label: 'Abonelik Tipi', keys:['payment_plan','title'] },
         { key: 'price', type: 'price', label: i18n.t('payment.price'), keys:['price'] },
         { key: 'user', type: 'user',label: 'Satın Alan Kullanıcı', keys:['user_info'] },
         { key: 'dealer',type: 'badge', label: 'Bayi Kodu', keys:['user_info', 'Dealercode'] },
         { key: 'subs', type:'date', label: 'Abonelik Bitişi', keys:['sub_expire_date']},
         { key: 'order_state', label: 'Sipariş Durumu', keys:['order_state']},
         { key: 'is_billing', label: 'Fatura Durumu', keys:['is_billing']},
    ]; 

    let filtered = ['date', 'bill', 'month', 'price', "user", 'dealer', 'subscription', 'order_state', 'is_billing', 'amount']; 
    export default {
        name: 'PaymentReport',
         created: async function(){
            await this.$store.dispatch('paymentReportList', this.params)
        },
        computed:{
            paymentReport(){
                this.chartData={
                    labels:[],
                    datasets:[
                        {
                            label: 'Toplam Fatura Tutarı',
                            backgroundColor: [],
                            data: [] 
                        }
                    ]
                }
                this.$store.getters.getPaymentReport.map(pr => {
                    this.chartData.labels.push(this.getMonthName(pr))
                    this.chartData.datasets[0].data.push(pr.total_price )
                    this.chartData.datasets[0].backgroundColor.push( this.getPriceVariant(pr.total_price) )


                })

                this.chartData.labels.reverse()
                this.chartData.datasets[0].data.reverse()
                this.chartData.datasets[0].backgroundColor.reverse()


                return this.$store.getters.getPaymentReport
            },
            loading(){
                return this.$store.getters.getPaymentLoading
            },
            pageInfo(){
                return this.$store.getters.getPaymentReportInfo
            }

        },
        data: function(){
            return{
                breadCrumb, fields, filtered,  params:{
                    limit:500,
                    offset: 0,
                    begin_date: null,
                    end_date: null,
                },
                chartData: {
                    labels: [],
                    datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: '#007bff',
                        data: []
                    }
                    ]
                },
                records:[]

            }
        },
        components:{
            BreadCrumb, Table, Modals, Datetime, LineChartGenerator, Bar, Doughnut
        },

        methods:{
            getReportRecords(data){
                this.records = data.records
            },
            getMonthName(pR){
               return moment.months(pR.month - 1)
            },
            getVariant(pR){
                let variants = ['primary','success','danger','info','warning','dark']

                return variants[pR.month%6]
            },
            getPriceVariant(data){
                console.log(data)
                if(data > 500){
                    if(data > 750){
                        if(data > 1000){
                            if(data > 1500){
                                return 'green'
                            }else{
                                return 'blue'
                            }
                        }else{
                            return 'lightblue'
                        }
                    }else{
                        return 'yellow'
                    }
                }else{
                    return 'red'
                }
            },
            async getReport(){
                await this.$store.dispatch('paymentReportList', this.params)

            }
        }
    }
</script>