<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-table-simple v-else hover responsive>
            <b-th> {{$t('program.events.code')}} </b-th>
            <b-th> {{$t('program.events.eventCode')}} </b-th>
            <b-th> {{$t('program.events.desc')}} </b-th>
            <b-th> {{$t('program.general.program')}} </b-th>
        <b-tbody >
            <b-tr v-for="item in data" :key="item.id"> 
                <b-td > {{item.Code}} </b-td>
                <b-td > {{item.SiaCode}} </b-td>
                <b-td > {{item.Description}} </b-td>
                <b-td> <b-button size="sm" variant="info" @click="modalEvent(true,'program', item)">{{$t('general.edit')}} </b-button>  </b-td>
            </b-tr>
            
        </b-tbody>
    </b-table-simple>
    </div>
     
</template>

<script>
    import Modals from "../modals/"
   
    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                
            }
        },       
        props:{
            data: Array,
            loading: Boolean,
            modalEvent: Function
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>