<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
        <form v-else ref="form"> 
            {{ this.kType }}
            <validation-observer  ref="KeypadPRG">
                <validation-provider :name="$t('program.keypads.name')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.keypads.name')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="keypadName" :placeholder="$t('program.keypads.name')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.keypads.outNo')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.keypads.outNo')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="outNo" :placeholder="$t('program.keypads.outNo')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                 <b-form-group>
                    <b-row>
                        <b-col sm="4"> <label> {{$t('program.keypads.lightLevel')}} </label> </b-col>
                        <b-col sm="8">
                            <b-input-group :append="lightLevel" class="mt-3">
                                <b-form-input type="range" min="0" max="10" v-model="lightLevel" ></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.usage')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="using"
                                :options="yesNo"
                                :aria-describedby="ariaDescribedby"
                                name="using"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.removeAlarm')}} </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="removeAlarm"
                                :options="yesNo"
                                :aria-describedby="ariaDescribedby"
                                name="removeAlarm"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.keyBeep')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="keyBeep"
                                :options="yesNo"
                                name="keyBeep"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.delayBeep')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="delayBeep"
                                :options="yesNo"
                                name="delayBeep"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.alarmBeep')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="alarmBeep"
                                :options="yesNo"
                                name="alarmBeep"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                 <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.chimeBeep')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="chimeBeep"
                                :options="yesNo"
                                :aria-describedby="ariaDescribedby"
                                name="chimeBeep"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.keypadRole')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="relayControl"
                                :options="rols"
                                name="relayControl"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.errBeep')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="errorBeep"
                                :options="yesNo"
                                name="errorBeep"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.keypads.kType')}}  </b-col>
                        <b-col sm="8">
                            <b-form-radio-group
                                v-model="kType"
                                :options="ktype"
                                name="kType"
                            />
                        </b-col>
                    </b-row>
                </b-form-group>
            </validation-observer>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

   let keypads = [
          { id: 1, name: '1. '+i18n.t('program.general.keypad') },
          { id: 2, name: '2. '+i18n.t('program.general.keypad') },
          { id: 3, name: '3. '+i18n.t('program.general.keypad') },
          { id: 4, name: '4. '+i18n.t('program.general.keypad') }
        ]
    export default {
        name: "Zone",
        components:{
            Multiselect, 
        },
        created: async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 1,
                member: this.data.keypad_no,
                user_pass: this.$store.getters.getPWD,
                offset: 0,
                list_id: this.data.id ? this.data.id : -1
            }
            await this.$store.dispatch('panelSettings', params)
            await this.$store.dispatch('panelKeypads', {id: this.$route.params.id})
        },
         computed:{
            settings(){
                return this.$store.getters.getSettings
            },
             loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            }
        },
         watch:{
            settings: function(newVal, oldVal) { // watch it 
                this.keypadName = newVal.name
                this.lightLevel = newVal.light_level
                this.using = newVal.active
                this.removeAlarm = newVal.remove_alarm
                this.keyBeep = newVal.key_beep
                this.delayBeep = newVal.delay_beep
                this.alarmBeep = newVal.alarm_beep
                this.delayBeep = newVal.delay_beep
                this.chimeBeep = newVal.chime_beep
                this.relayControl = newVal.roll
                this.errorBeep = newVal.error_beep
                this.kType =  newVal.keypad_type
                this.outNo = newVal.out_no
                this.id = newVal.id
            },
            save: async function(data){
                if(data){
                    this.$refs.KeypadPRG.validate().then( async success =>{
                        if(success){
                            let params = {
                                id: this.id,
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 1,
                                member: this.data.keypad_no,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                keypad_no: this.data.keypad_no,
                                name:this.keypadName,
                                remove_alarm:this.removeAlarm,
                                light_level:this.lightLevel,
                                roll:this.relayControl,
                                out_no: parseInt(this.outNo),
                                key_beep: this.keyBeep,
                                delay_beep:this.delayBeep,
                                alarm_beep: this.alarmBeep,
                                chime_beep: this.chimeBeep,
                                error_beep:  this.errorBeep,
                                keypad_type: this.kType,
                                active: this.using
                            }
                            await this.$store.dispatch('setKeypadSettings', params)
                        }else{
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                }
                this.saveState(false)
            }

        },
        data:function(){
           return{
               lightLevel: 0,
               id: 0,
               keypads, yesNo: [
                    { text:  i18n.t('program.general.no'), value: false},
                    { text:  i18n.t('program.general.yes'), value: true},
                ], rols: [
                    { text: i18n.t('program.keypads.role1'), value: 0},
                    { text: i18n.t('program.keypads.role2'), value: 1},
                ], ktype: [
                    { text: i18n.t('program.keypads.input'), value: 0},
                    { text: i18n.t('program.keypads.output'), value: 1},
                ]
           } 
        },
         methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        }
        
    }
</script>