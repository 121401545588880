<template>
    <div>
        <div class="cardList">
            <div v-for="panel in data" :key="panel.id" :class="{panel:true, green:panel.partcount>0 ? panel.PartList[partIndex].armed : panel.armed, yellow: delayArr.includes(panel.status), gray: !panel.connected}" @dblclick="goDetail(panel)">
                <div :class="panel.KLStatus && panel.KLStatus.alarm ? 'additional alarming' : 'additional'">
                    <div class="user-card">
                        <div class="menus">
                            <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                                <template #button-content>
                                    <b-icon icon="list" />
                                </template>
                                <b-dropdown-item href="#" @click="openModal(true, 'changeDesc', panel)">{{$t('menus.changeDesc')}} </b-dropdown-item>
                                <b-dropdown-item href="#" @click="openModal(true, 'delete', panel)" > {{$t('menus.removePanel')}} </b-dropdown-item>
                                <!-- <b-dropdown-item href="#" @click="pdksRapor(panel)" > PDKS Raporu </b-dropdown-item> -->
                                <b-dropdown-item v-if="panel.connected && auth.BYPASS" @click="openModal(true, 'password', {panel: panel, form:'zones'})" > {{$t('menus.zoneList')}} </b-dropdown-item>
                                <b-dropdown-item v-if="panel.connected && auth.OUTPUT" href="#" @click="openModal(true, 'password', {panel: panel, form:'outs'})" > {{$t('menus.outList')}} </b-dropdown-item>
                                <b-dropdown-item v-if="panel.connected && !panel.armed && auth.PRG" href="#" @click="goDetail(panel)" > Paneli Programla</b-dropdown-item>
                            </b-dropdown>
                             <b-dropdown v-if="panel.partcount>0" size="md"  variant="link" toggle-class="text-decoration-none" no-caret>
                                <template #button-content >
                                    <span style="color: #fff; font-size: 16px"> {{panel.PartList[partIndex].partno+". Part "+panel.PartList[partIndex].name}} </span>
                                </template>
                                <b-dropdown-item v-for="(part, i) in panel.PartList" :key="part.partno" @click="partIndex = i; part">{{part.partno+". Part "+part.name}} </b-dropdown-item>
                                
                            </b-dropdown>
                        </div>
                        <div class="state">
                            
                            <template v-if="panel.KLStatus && panel.KLStatus.firealarm">
                                <v-icon name="fire" scale="4" />
                                <div class="title"> {{panel.partcount>0 ? panel.PartList[partIndex].description : panel.status_desc}} </div>
                            </template>
                            <template v-else-if="panel.KLStatus && panel.KLStatus.alarm">
                                <v-icon name="user-secret" scale="4" />
                                <div class="title"> {{panel.partcount>0 ? panel.PartList[partIndex].description : panel.status_desc}} </div>
                            </template>
                            <template v-else-if="delayArr.includes(panel.status)">
                                <v-icon name="stopwatch" scale="4" />
                                <div class="title">{{panel.partcount>0 ? panel.PartList[partIndex].description : panel.status_desc}}</div>
                            </template>
                            <template v-else>
                                <div v-if="panel.partcount>0 ? panel.PartList[partIndex].armed : panel.armed">
                                    <b-iconstack v-if="panel.stayarmed"  font-scale="5" :title="$t('icons.stayarm')">
                                        <b-icon stacked icon="house-fill" animation="fade" scale="0.75" shift-v="-0.25" variant="danger"></b-icon>
                                        <b-icon stacked icon="lock" variant="default" scale="0.75" shift-v="-0.25" ></b-icon>
                                    </b-iconstack>
                                    <b-icon v-else icon="lock-fill" scale="1.25" shift-v="1.25" aria-hidden="true" :title="$t('icons.arm')"></b-icon> 
                                    
                                </div>
                                <b-icon v-else icon="unlock-fill" scale="1.25" shift-v="1.25" aria-hidden="true" :title="$t('icons.disarm')"></b-icon> 
                                <div class="title"> {{panel.partcount>0 ? panel.PartList[partIndex].description : panel.status_desc}} </div>
                            </template>
                            
                        </div>
                        <div class="icons">
                            <div v-if="panel.model.slice(-1) === 'G'"><b-icon :icon="'reception'+Math.floor(panel.gsmlevel/20)" /> %{{panel.gsmlevel}} </div>
                            <div v-if="panel.KLStatus.temp < 128"><b-icon icon="thermometer-half" /> {{panel.KLStatus.temp}} °C</div>
                        </div>                                   
                    </div>
                </div>
                <div class="general">
                    <div class="title"> {{panel.description}} </div>
                    <span>{{panel.dev_id}}</span>  
                    <div class="buttons" v-if=" panel.partcount>0 ? !panel.PartList[partIndex].armed : !panel.armed">
                        <b-button v-if="panel.connected && auth.ARM" variant="outline-success" @click="openModal(true, 'password', {panel: panel, form:'ARM', part:panel.partcount>0 ? panel.PartList[partIndex].partno : 0 })"> <b-icon icon="shield-lock-fill" /> {{ $t('commands.arm') }} </b-button>
                        <b-button v-if="panel.connected && auth.STAYARM && panel.partcount==0" variant="outline-danger" @click="openModal(true, 'password', {panel: panel, form:'STAYARM', part:panel.partcount>0 ? panel.PartList[partIndex].partno : 0})">  <b-icon icon="house-fill" />{{ $t('commands.stayarm') }}</b-button>
                    </div> 
                     <div class="buttons" v-else>
                        <b-button v-if="panel.connected && auth.DISARM" variant="outline-danger" @click="openModal(true, 'password', {panel: panel, form:'DISARM', part:panel.partcount>0 ? panel.PartList[partIndex].partno : 0})">  <b-icon icon="door-open-fill" /> {{ $t('commands.disarm') }} </b-button>
                    </div> 
                    <div class="stats">
                        <div v-if="panel.KLStatus.power !== undefined">
                            <b-icon
                                icon="lightning-fill" 
                                :variant="panel.KLStatus.power ? 'success': 'danger'" 
                                :title="panel.KLStatus.power ? $t('icons.power1') : $t('icons.power0')" />
                        </div>
                        <div v-if="panel.battary !== undefined">
                            <b-icon v-if="panel.battary === 1" icon="battery" variant="danger" :title="$t('icons.battery1')" />
                            <b-icon v-else-if="panel.battary === 0" icon="battery-charging" variant="success" :title="$t('icons.battery1')" />
                             <b-iconstack v-else :title="$t('icons.battery0')">
                                <b-icon stacked icon="x" animation="fade" scale="1.25" variant="default"></b-icon>
                                <b-icon stacked icon="battery" variant="danger" ></b-icon>
                            </b-iconstack>
                        </div>
                        <div v-if="panel.KLStatus.bypass">
                            <b-icon  icon="bootstrap-fill" variant="danger" :title="$t('icons.bypass')"/>
                        </div>
                        <div v-if="panel.motion">
                            <v-icon name="walking" scale="1.25" color="#dc3545" variant="danger" animation="throb" :title="$t('icons.motion')"/>
                        </div>
                        <div v-if="panel.KLStatus.event">
                            <b-icon   icon="exclamation-triangle-fill" variant="warning" :title="$t('icons.event')" />
                        </div>
                        <div>
                            <b-icon 
                                v-if="!panel.ahm1"
                                :icon="panel.ahm1  ? 'shield-fill-check': 'shield-fill-x'"
                                :variant="panel.ahm1 ? 'success': 'danger'" 
                                :title="panel.ahm1 ? $t('icons.cms11') : $t('icons.cms10')" />
                        </div>    
                        <div>
                            <b-icon 
                                v-if="!panel.ahm2"
                                :icon="panel.ahm2  ? 'shield-fill-check': 'shield-fill-x'"
                                :variant="panel.ahm2 ? 'success': 'danger'" 
                                :title="panel.ahm2 ? $t('icons.cms21') : $t('icons.cms20')" />
                        </div>                        
                        <div>
                            <b-icon 
                                v-if="!panel.connected"
                                :icon="panel.connected ? 'cloud-check-fill': 'cloud-slash-fill'"
                                :variant="panel.connected ? 'success': 'danger'"
                                :title="panel.connected ? $t('icons.cloud1') : $t('icons.cloud0')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      
</template>

<script>
    import moment from 'moment'
    export default {
        name: 'ProsecPanel',
    
        props:{
            data: Array,
            openModal: Function
        },
        data: function(){
            return{
                delayArr : [9, 10, 14, 15],
                delayArrPart : [9, 15, 10, 14],
                partIndex: 0,
            }
        },
        methods:{
            goDetail(panel){
               panel.connected && panel.armed == false && this.$store.getters.getAuthPlan.PRG && this.openModal(true, 'password', panel.id)
            }, 
            pdksRapor: async function(panel) {        
                let params = { 
                    id: panel.id,
                  
                }
                params["responseType"]="arraybuffer"
                await this.$store.dispatch('panelInOutReport', params)
                console.log(this.$store.getters.pdksExcel)
                if(this.$store.getters.pdksExcel){
                    let url = window.URL.createObjectURL(new Blob([this.$store.getters.pdksExcel]))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', moment(this.beginDate).format('DD.MM.YYYY')+'-'+moment(this.endDate).format('DD.MM.YYYY')+' Report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }else{
                    this.$bvToast.toast('Rapor Oluşturulamadı. Filtrelemelerinizin doğru olduğundan emin olun lütfen', {
                        title: `Başarılı! `,
                        variant: 'danger',
                        solid: true
                    })
                }
            }
        },
        computed:{
            auth: function(){
                return this.$store.getters.getAuthPlan
            }
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>