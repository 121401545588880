<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
         <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.outs.no')}} </b-th>
                <b-th> {{$t('program.outs.name')}} </b-th>
                <b-th> {{$t('program.outs.state')}} </b-th>
                <b-th v-if="auth.auth.installer"> {{$t('program.general.program')}} </b-th>
            </b-thead>
            <b-tbody >
                <b-tr v-for="item in data" :key="item.id"> 
                    <b-td> {{item.out_no}} </b-td>
                    <b-td > {{item.name}} </b-td>
                    <b-td> 
                        <b-badge v-if="item.out_status == 1" variant="success"> {{$t('program.outs.active')}}</b-badge>
                        <b-badge v-else variant="danger"> {{$t('program.outs.passive')}}</b-badge>
                    </b-td>
                    <b-td v-if="auth.auth.installer"> <b-button size="sm" variant="info" @click="openModal(true,'program', item)">{{$t('general.edit')}} </b-button> </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
    import Modals from "../modals/"

    export default {
        name: 'ProsecPanel',
     
       
        props:{
            data: Array,
            loading: Boolean,
            openModal: Function,
            auth: Object
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>