<template>
      <div>
        <b-icon icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p> {{$t('general.deleteMsg')}}</p>
   </div>
</template>

<script>
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                name: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        watch:{
            save: async function(data){
                if(data){
                    if(this.formName == "ProsecPanel"){
                        let params = {
                            device_id : this.data.id
                        }
                        await this.$store.dispatch('removePanel',params)
                        await this.$store.dispatch('panels',{offset: 0})
                    }else{
                        console.log(this.data)
                        let params = {
                            dev_id: parseInt(this.$route.params.id),
                            command: 'DELETEUSER',
                            user_pass: this.$store.getters.getPWD,
                            member: parseInt(this.data.user_no)+1 // 0 Müh 1 Master bu nedenle +1
                        }
                        await this.$store.dispatch('deletePanelUser',params)
                        await this.$store.dispatch('panelUsers', {id: this.$route.params.id}) 

                    }
                }
                this.saveState(false)
            },
        }
    }
</script>