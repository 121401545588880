<template>
    <form ref="form" autocomplete="off">
        <template v-if="newUser">
             <b-form-group>
                <b-row style="margin: 1rem; justify-content: center">
                    <p> {{$t('program.users.asignPwd')}}</p>
                </b-row>
                <b-row style="margin: 1rem"> 
                    <b-col sm="4"> {{$t('program.users.newPwd')}} </b-col>
                    <b-col sm="8"> <b-form-input id="password" v-model="newPass" :placeholder="$t('program.users.newPwd')" type="password" autocomplete="off" /></b-col>

                </b-row>
                <b-row style="margin: 1rem"> 
                    <b-col sm="4"> {{$t('program.users.newPwd2')}}</b-col>
                    <b-col sm="8"> <b-form-input id="password" v-model="newPass2" :placeholder="$t('program.users.newPwd2')" type="password" autocomplete="off" /></b-col>
                </b-row>
            </b-form-group>
        </template>
        <template v-else>
             <b-form-group>
                <b-row style="margin: 1rem">
                    <p> {{$t('program.users.changePwdMsg')}} </p>
                </b-row>
                <b-row style="margin: 1rem">
                    <b-col sm="4"> {{$t('program.users.currentPwd')}} </b-col>
                    <b-col sm="8"> <b-form-input id="password" v-model="password" :placeholder="$t('program.users.currentPwd')" type="password" autocomplete="off" /></b-col>
                </b-row>
                <b-row style="margin: 1rem"> 
                    <b-col sm="4"> {{$t('program.users.newPwd')}} </b-col>
                    <b-col sm="8"> <b-form-input id="password" v-model="newPass" :placeholder="$t('program.users.newPwd')" type="password" autocomplete="off" /></b-col>

                </b-row>
                <b-row style="margin: 1rem"> 
                    <b-col sm="4"> {{$t('program.users.newPwd2')}} </b-col>
                    <b-col sm="8"> <b-form-input id="password" v-model="newPass2" :placeholder="$t('program.users.newPwd2')" type="password" autocomplete="off" /></b-col>
                </b-row>
            </b-form-group>
        </template>
    </form>
</template>

<script>
import i18n from '../../../plugins/i18n'
    export default {
        name: "AddPanel",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
        data: function(){
            return{
                newUser: false,
                password:  "",
                newPass:  "",
                newPass2:  "",
            }
        },
       
        watch: {
            save: async function(data){
                if(data){
                    if(this.newPass2 == this.newPass){
                        let params = {
                            dev_id: parseInt(this.$route.params.id),
                            command: 'PWCHG',
                            user_pass: this.password,
                            new_pass: this.newPass
                        }
                        await this.$store.dispatch('panelUserPasswordChg',params)
                    }else{
                        vm.$root.$bvToast.toast(i18n.t('messages.changePwdError'), {
                            title: i18n.t('general.error'),
                            variant: 'danger',
                            noAutoHide: true
                        })
                    }
                }
                this.saveState(false)
                
            }
        }
        
    }
</script>