<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
        <form v-else ref="form">
            <validation-observer  ref="OutPRG">
                <validation-provider :name="$t('program.outs.name')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.outs.name')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="outName" :placeholder="$t('program.outs.name')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.outs.function')" :rules="{ required }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.outs.function')}} </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    v-model="func"
                                    :show-labels="false" 
                                    :options="funcList"
                                    label="Description"
                                    track-by="Code" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.Description }} </strong> 
                                        </template>
                                </Multiselect> 
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                 <b-form-group>
                    <b-row>
                        <b-col sm="4"> <label for="accountName"> {{$t('program.outs.usage')}} </label> </b-col>
                        <b-form-radio-group
                            v-model="reverse"
                            :options="usingList"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        />
                    </b-row>
                </b-form-group>
                 <validation-provider :name="$t('program.outs.param1')" :rules="{ integer }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.outs.param1')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="param1" :placeholder="$t('program.outs.param1')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.outs.param2')" :rules="{ integer }"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.outs.param2')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="param2" :placeholder="$t('program.outs.param2')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </validation-observer>
           
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    export default {
        name: "Outs",
        components:{
            Multiselect, 
        },
        created: async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 4,
                member: this.data.out_no,
                user_pass: this.$store.getters.getPWD,
                offset: 0,
                list_id: this.data.id ? this.data.id : -1
            }
            await this.$store.dispatch('outFuncTypeList')
            await this.$store.dispatch('panelSettings', params)
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
            funcList(){
                return this.$store.getters.getOutFuncs
            },
        },
         watch:{
            settings: function(newVal, oldVal) { // watch it
                this.outName = newVal.name
                this.func = this.funcList[newVal.aim]
                this.param1 = newVal.prm1
                this.param2 = newVal.prm2
                this.reverse = newVal.reverse
                this.id = newVal.id
            },
            save: async function(data){
                if(data){
                    this.$refs.OutPRG.validate().then( async success =>{
                        if(success){
                            let params = {
                                id: this.id,
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 4,
                                member: this.data.out_no,
                                out_no: this.data.out_no,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                name:this.outName,
                                reverse:this.reverse,
                                prm1: parseInt(this.param1),
                                prm2: parseInt(this.param2),
                                aim: parseInt(this.func.Code),
                            }
                            await this.$store.dispatch('setOutputSettings', params)
                            await this.$store.dispatch('panelOuts',{id: this.$route.params.id})
                        }else {
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                }
                this.saveState(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        data:function(){
           return{
               id:0,
               outNo:{},
               func:{},
               using: {},
               params1: "",
               params2: "",
               usingList : [
                    { text: i18n.t("program.general.nOpen"), value: false},
                    { text: i18n.t("program.general.nClose"), value: true},
                ]
           } 
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        }
        
    }
</script>