<template>
    <div >
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <div v-else>
            <form ref="add" v-if="activeTab == 0">
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> <label for="accountNo"> {{$t('add.id')}} </label> </b-col>
                        <b-col sm="8"> <b-form-input id="accountNo" v-model="devId" :placeholder="$t('add.id')" /></b-col>
                    </b-row>
                </b-form-group>

                <b-form-group>
                    <b-row>
                        <b-col sm="4"> <label for="accountName"> {{$t('add.description')}} </label> </b-col>
                        <b-col sm="8"> <b-form-input id="accountName" v-model="description" :placeholder="$t('add.description')" /></b-col>
                    </b-row>
                </b-form-group>
            </form>

            <form ref="code" v-else>
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> <label for="accountNo">{{$t('add.code')}}</label> </b-col>
                        <b-col sm="8"> <b-form-input id="accountNo" v-model="code" :placeholder="$t('add.code')" /></b-col>
                    </b-row>
                </b-form-group>
            </form>
        </div>
    </div>
  
  
</template>

<script>
    export default {
        name: "AddPanel",
        data: function() {
            return{
                activeTab: 0,
                devId: "",
                description: "",
                code: ""
            }
            
        },
        props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: String

        },
        watch: {
            save: async function(data){
                if(data){
                    let data = {
                        dev_id: this.devId,
                        Description: this.description
                    }
                    if(this.activeTab == 0){
                        await this.$store.dispatch('sendPanelCode', data)
                        if(!this.$store.getters.getPanelCode.err){
                            this.activeTab = 1
                        }
                    }else{
                        if(this.code == this.$store.getters.getPanelCode.code){
                            await this.$store.dispatch('savePanel', data)
                            await this.$store.dispatch('panels',{offset: 0})
                            this.activeTab = 0
                            this.modalEvent(false)
                        }
                    }
                }
                this.saveState(false)
            }
        },
        methods:{
            clickTab: function(val){
                this.activeTab = 0
                return false
            }
        },
        computed:{
             loading(){
                return this.$store.getters.getPanelSettingLoading
            },
        }
        
    }
</script>