<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
         <b-tabs v-else fill :value="type-1">
            <b-tab :title="$t('program.network.tab1')" @click="getData(1)">
                <validation-observer  ref="network">
                     <b-form class="formBox"> 
                        <b-form-group class="inputGrp" :label="$t('program.network.dhcp')" label-for="dhcp">
                            <b-form-radio-group
                                id="dhcp"
                                v-model="dhcp"
                                :options="yesNo"
                                aria-describedby="live-feedback"></b-form-radio-group>
                        </b-form-group>      

                         <validation-provider :name="$t('program.network.ip')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.ip')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="ip" :placeholder="$t('program.network.ip')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        
                        <validation-provider :name="$t('program.network.gateway')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.gateway')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="gateway" :placeholder="$t('program.network.gateway')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider :name="$t('program.network.mask')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.mask')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="mask" :placeholder="$t('program.network.mask')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </validation-observer>
            </b-tab>
            <b-tab :title="$t('program.network.tab2')" @click="getData(2)">
                <validation-observer  ref="wifi">
                    <b-form class="formBox"> 
                         <validation-provider :name="$t('program.network.ssid')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.ssid')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="ssid" :placeholder="$t('program.network.ssid')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.network.password')"   v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.password')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="password" :placeholder="$t('program.network.password')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </validation-observer>
                
            </b-tab>
            <b-tab :title="$t('program.network.tab3')" @click="getData(3)">
                <validation-observer  ref="dns">
                    <b-form class="formBox"> 
                        <validation-provider :name="$t('program.network.dns1')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.dns1')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="dns1" :placeholder="$t('program.network.dns1')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.network.dns2')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.dns2')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="dns1" :placeholder="$t('program.network.dns2')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </validation-observer>
            </b-tab>
            <b-tab :title="$t('program.network.tab4')"  @click="getData(4)">
                <validation-observer  ref="domain">
                    <b-form class="formBox"> 
                        <validation-provider :name="$t('program.network.domain1')" :rules="{regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.domain1')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="domain1" :placeholder="$t('program.network.domain1')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.network.domain2')" :rules="{regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.domain1')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="domain2" :placeholder="$t('program.network.domain2')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.network.domain3')" :rules="{regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="name"> {{$t('program.network.domain3')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-form-input id="name" v-model="domain3" :placeholder="$t('program.network.domain3')" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                    </b-form>
                </validation-observer>
            </b-tab>
        </b-tabs>
    </div>    
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                dhcp: false,
                ip: "",
                gateway: "",
                mask: "",
                dns1: "",
                dns2: "",
                ssid: "",
                password: "",
                domain: "",
                type: 1,
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.no'), value: true },
                ],
            }
        },
         computed:{
            settings(){
                return this.$store.getters.getSettings
            }
        },
        methods: {
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            async getData(type){
                this.type = type;
                let params = {
                    dev_id: parseInt(this.$route.params.id),
                    command: 'GET',
                    group: 10,
                    member: 0,
                    user_pass: this.$store.getters.getPWD,
                    offset: 0
                }
                        
                if(type == 1){
                    params.group = 10
                    params.member = 0
                    await this.$store.dispatch('panelSettings', params)
                }else if(type == 2){
                    params.group = 21
                    params.member = 0
                    await this.$store.dispatch('panelSettings', params)
                }else if(type == 3){
                    params.group = 19
                    params.member = 0 //Genel Ayarlar için member 2 olarak kullanıyoruz
                    await this.$store.dispatch('panelSettings', params)
                }else if(type == 4){
                    params.group = 20
                    params.member = 0 //Genel Ayarlar için member 2 olarak kullanıyoruz
                    await this.$store.dispatch('panelSettings', params)
                }
            }
        },
        
        watch: { 
            data: function(newVal, oldVal) { // watch it
                if(newVal.group == 10){
                    this.dhcp = newVal.dhcp,
                    this.ip = newVal.ip
                    this.gateway = newVal.gateway
                    this.mask = newVal.mask
                }else if(newVal.group == 21){
                    this.ssid = newVal.ssid,
                    this.password = newVal.password
                }else if(newVal.group == 19){
                    this.dns1 = newVal.dns1,
                    this.dns2 = newVal.dns2
                }else if(newVal.group == 20){
                    this.domain1 = newVal.domain_ahm1
                    this.domain2 = newVal.domain_ahm2
                    this.domain3 = newVal.domain_rmt
                }
            },
            setting: function(newVal, oldVal) { // watch it
                if(newVal.group == 10){
                    this.dhcp = newVal.dhcp,
                    this.ip = newVal.ip
                    this.gateway = newVal.gateway
                    this.mask = newVal.mask
                }else if(newVal.group == 21){
                    this.ssid = newVal.ssid,
                    this.password = newVal.password
                }else if(newVal.group == 19){
                    this.dns1 = newVal.dns1,
                    this.dns2 = newVal.dns2
                }else if(newVal.group == 20){
                    this.domain = newVal.domainName
                }
               
            },
            save: async function(newVal, oldVal){
                if(newVal){
                     let params = {
                            dev_id: parseInt(this.$route.params.id),
                            user_pass: this.$store.getters.getPWD,
                            offset: 0,
                            id: this.data.id,
                            member: 0,
                        }
                        switch(this.type){
                            case 1:
                                this.$refs.network.validate().then( async success =>{
                                    if(success){
                                        params.dhcp = this.dhcp;
                                        params.ip = this.ip;
                                        params.gateway = this.gateway;
                                        params.mask = this.mask;
                                        params.group = 10;
                                        await this.$store.dispatch('setEthSettings', params)
                                    }else{
                                         vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                            title: i18n.t('program.general.warning'),
                                            variant: 'info',
                                            noAutoHide: true
                                        })
                                    }
                                })
                                break;
                            case 2:
                                 this.$refs.wifi.validate().then( async success =>{
                                    if(success){
                                        params.ssid = this.password;
                                        params.password = this.password;
                                        params.group = 21;
                                        await this.$store.dispatch('setWifiSettings', params)
                                    }else{
                                         vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                            title: i18n.t('program.general.warning'),
                                            variant: 'info',
                                            noAutoHide: true
                                        })
                                    }
                                })
                              
                                break;
                            case 3:
                                 this.$refs.dns.validate().then( async success =>{
                                    if(success){
                                        params.dns1 = this.dns1;
                                        params.dns2 = this.dns2;
                                        params.group = 19;
                                        await this.$store.dispatch('setDNSSettings', params)
                                    }else{
                                        vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                            title: i18n.t('program.general.warning'),
                                            variant: 'info',
                                            noAutoHide: true
                                        })
                                    }
                                 })

                                
                                break;
                            case 4:
                                 this.$refs.domain.validate().then( async success =>{
                                    if(success){
                                        params.domainName = this.domain;
                                        params.group = 20;
                                    }else{
                                        vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                            title: i18n.t('program.general.warning'),
                                            variant: 'info',
                                            noAutoHide: true
                                        })
                                    }
                                 })
                                break;

                        }
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>