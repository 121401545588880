<template>  
    <validation-observer style="align-items: center; display: flex; justify-content: center; flex: 1;" ref="dealer">
        <b-form style="flex: 1; padding: 1rem;">
            <validation-provider :name="$t('user.dealers.title')" :rules="{min:3, required}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group class="inputGrp" >
                            <b-input-group-prepend is-text>
                                <b-icon icon="building"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="title" v-model="title" type="text" required :placeholder="$t('user.dealers.title')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.dealers.tax')" :rules="{required}" v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-col :cols="2">
                           <span>{{$t('user.dealers.tax')}}</span>
                        </b-col>
                        <b-col>
                            <b-form-file
                                plain
                                v-model="taxFile"
                                file_id="file"
                                :placeholder="$t('user.dealers.tax')"
                                drop-placeholder="Dosyayı yükle veya sürükle"
                                :state="getValidationState(validationContext)"
                                aria-describedby="live-feedback"
                            ></b-form-file>
                        </b-col> 
                    </b-row>
                    <b-row>
                        <span style="color: red; font-size: 12px;"> Lütfen 1 mb'tan küçük ve sadece jpg,png formatında dosyalar gönderin </span>
                    </b-row>
                     <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
                
            </validation-provider>
            <validation-provider :name="$t('user.dealers.name')" :rules="{min:3}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group class="inputGrp" >
                            <b-input-group-prepend is-text>
                                <b-icon icon="person-fill"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="name" v-model="name" type="text" required :placeholder="$t('user.dealers.name')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <validation-provider :name="$t('user.dealers.mail')" :rules="{required}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group >
                            <b-input-group-prepend is-text>
                                <b-icon icon="at"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="mail" v-model="info" type="text" required :placeholder="$t('user.dealers.mail')" :state="getValidationState(validationContext)"></b-form-input>
                        </b-input-group>
                    </b-row>
                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            
            <validation-provider :name="$t('user.dealers.saler')" :rules="{required}"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-icon icon="shop"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input id="dealer" v-model="dealer" type="text" :placeholder="$t('user.dealers.saler')" ></b-form-input>
                        </b-input-group>
                    </b-row>
                    
                     <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
                
            </validation-provider>
             
            <validation-provider :name="$t('user.dealers.logo')"  v-slot="validationContext">
                <b-form-group class="inputGrp">
                    <b-row>
                        <b-col :cols="2">
                           <span>{{$t('user.dealers.logo')}}</span>
                        </b-col>
                        <b-col>
                            <b-form-file
                                plain
                                v-model="logoFile"
                                file_id="file"
                                :placeholder="$t('user.dealers.logo')"
                                drop-placeholder="Dosyayı yükle veya sürükle"
                                :state="getValidationState(validationContext)"
                                aria-describedby="live-feedback"
                            ></b-form-file>
                        </b-col>
                             
                    </b-row>
                    <b-row>
                        <span style="color: red; font-size: 12px;"> Lütfen 1 mb'tan küçük ve sadece jpg,png formatında dosyalar gönderin </span>
                    </b-row>
                     <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                </b-form-group>
                
            </validation-provider>

            <div class="submitButton" variant="danger" @click="register"> {{$t('user.dealers.save')}} </div>
        </b-form>
    </validation-observer>
    
</template>

<style lang="css" scoped> 
    @import "../styles/login.min.css"; 
</style>
<script>
    import i18n from '@/plugins/i18n'
    export default {
        name: "LoginForm",
        data: function(){
            return{
                name: "",
                title: "",
                info: "",
                dealer: "",
                taxFile: null,
                logoFile: null,

            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            phoneValid: function(val, obj){
                this.phoneValidVal = obj
                this.phone = obj.number
            },
            register: function(){
               this.$refs.dealer.validate().then( async success =>{
                    if(success){ 
                        let formData = new FormData();
                        if(this.taxFile){
                            formData.append('tax_plate', this.taxFile)
                        }
                        if(this.logoFile){
                            formData.append('logo', this.logoFile)
                        }
                        formData.append('dealer_name', this.title)
                        formData.append('name', this.name)
                        formData.append('seller', this.dealer)
                        formData.append('info', this.info)

                       this.onSave(formData)
                    }else{
                        this.$bvToast.toast(i18n.t('messages.formError'), {
                                    title: i18n.t('general.error'),
                                    variant: 'warning',
                                })
                    }
                })
            }
        },
        props:{
            onSave: Function,
            changeForm: Function,
        }
    }
</script>