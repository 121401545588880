import * as getters from './Getters'
import * as actions from './Actions'
import * as mutations from './Mutaions'

//Statelerimiz
const state = () => ({
    loading: false,
    status: {},
    dealers: []
})

export default {
    state,
    getters,
    actions,
    mutations
}