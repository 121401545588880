<template>
    <div>
        <validation-observer  ref="panelGeneral">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <validation-provider :name="$t('program.panelGeneral.lang')" :rules="{required}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.panelGeneral.lang')}} </label> </b-col>
                            <b-col sm="8"> 
                                 <Multiselect  
                                    v-model="lang"
                                    :show-labels="false" 
                                    :options="langList"
                                    label="name"
                                    track-by="id" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.name }} </strong> 
                                        </template>
                                </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
               <validation-provider :name="$t('program.panelGeneral.shortPass')" :rules="{integer}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.panelGeneral.shortPass')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="shortLength" :placeholder="$t('program.panelGeneral.shortPass')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.panelGeneral.longPass')" :rules="{integer}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.panelGeneral.longPass')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="longLength" :placeholder="$t('program.panelGeneral.longPass')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
               <validation-provider :name="$t('program.panelGeneral.name')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.panelGeneral.name')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="name" :placeholder="$t('program.panelGeneral.name')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                
            </b-form>
        </validation-observer>
    </div>
   
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                lang: 0,
                shortLength: 0,
                longLength: 0,
                name: "",
                langList: [
                    { id: 0, name: 'Türkçe' },
                    { id: 1, name: 'English' },
                ]
            }
        },
       
        watch: { 
            data: function(newVal, oldVal) { // watch it
                this.lang = this.langList[newVal.lang]
                this.shortLength = newVal.min_pass_len
                this.longLength = newVal.max_pass_len
                this.name = newVal.description

               
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.panelGeneral.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 16,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                id: this.data.id,
                                lang: this.lang.id,
                                min_pass_len: parseInt(this.shortLength),
                                max_pass_len: parseInt(this.longLength),
                                description: this.name,
                            }
                            await this.$store.dispatch('setDeviceGenSettings', params)
                        } else {
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                     
                }
                this.saveRemove(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime, Multiselect
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>