<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-form v-else class="formBox"> 
                
            <b-form-group  class="inputGrp" :label="$t('program.sms.send')" label-for="sending">
                <div class="elements">
                <b-form-radio-group
                        v-model="sending"
                        :options="actives"
                        name="sending"
                    />
                </div>
            </b-form-group>

            <b-form-group  class="inputGrp" :label="$t('program.sms.manage')" label-for="arm">
                <div class="elements">
                <b-form-radio-group
                        v-model="arm"
                        :options="actives"
                        name="arm"
                    />
                </div>
            </b-form-group>

            <b-form-group  class="inputGrp" :label="$t('program.sms.routing')" label-for="routing">
                <div class="elements">
                <b-form-radio-group
                        v-model="routing"
                        :options="actives"
                        name="routing"
                    />
                </div>
            </b-form-group>
        </b-form>
    </div>
    
</template>

<script>
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                arm: this.data.sms_control_enabled ,
                sending: this.data.sms_send_enabled,
                routing: this.data.sms_forward_enabled,
                actives: [
                    { text: i18n.t('program.general.passive'), value: false },
                    { text: i18n.t('program.general.active'), value: true },
                ],
            }
        },
        
        watch: { 
            data: function(newVal, oldVal) { // watch it
                this.arm = newVal.sms_control_enabled 
                this.sending = newVal.sms_send_enabled
                this.routing =  newVal.sms_forward_enabled
            },
            save: async function(newVal, oldVal){
                if(newVal){
                     let params = {
                            dev_id: parseInt(this.$route.params.id),
                            group: 18,
                            member: 0,
                            user_pass: this.$store.getters.getPWD,
                            offset: 0,
                            id: this.data.id,
                            sms_send_enabled: this.sending,
                            sms_control_enabled: this.arm,
                            sms_forward_enabled: this.routing
                        }
                        await this.$store.dispatch('setSMSSettings', params)
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>