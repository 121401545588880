<template>
    <b-breadcrumb>
        <b-breadcrumb-item>
            <router-link to="/"> <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> {{$t('menus.home')}} </router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item v-for="item in data" :key="item.id"> <router-link :to="item.url"> {{item.label}} </router-link> </b-breadcrumb-item>
    </b-breadcrumb>
</template>

<script>
    export default {
        name: 'BreadCrumb',
        props:{
            data: Array
        },
    }
</script>
