<template>
    <b-card header-tag="header" footer-tag="footer" class="cardFullHeight"> 
         <template v-slot:header v-if="type !== 'detail'">
            <div class="headerLeft" >
                <b-button class="hl-element sm" v-b-modal.filterModal variant="info" @click="changeType">
                    <b-icon v-if="type=='Card'" icon="list"  />
                    <b-icon v-else icon="grid3x3-gap-fill"  />
                </b-button>
                <b-button class="hl-element"  variant="success" @click="openModal(true, 'add')"> 
                    <b-icon icon="plus" scale="1.25" shift-v="1.25"  aria-hidden="true" />
                    {{$t('general.newPanel')}}
                </b-button>
                <b-button class="hl-element"  variant="primary" @click="morePanel()"> 
                    <b-icon icon="arrow-clockwise" scale="1.25" shift-v="1.25"  aria-hidden="true" />
                    {{$t('general.morePanel')}}
                </b-button>
            </div>
            <div class="headerRight">
                <b-form-input :placeholder="$t('general.search')" v-model="keyword" @keyup="onSearch"></b-form-input>
            </div>
        </template>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <template v-else>
            <PanelTableList v-if="type == 'List'" :data="data" :openModal="openModal" />
            <PanelCardList v-else :data="data" :openModal="openModal" />
        </template>
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
    </b-card>
</template>

<script>
    import Modals from "../modals/"
    import PanelTableList from './PanelTableList';
    import PanelCardList from './PanelCardList';

    export default {
        name: 'ProsecPanel',
        data:function(){
            return {
                modalOpen: false,
                modalProps: {},
                currentForm:"ProsecPanel",
                modalName: "",
                keyword: ""
            }
        },
        methods:{
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
            pageSettings: function(form){
                this.currentForm = form
            },
            onSearch: function(e){
                if (e.keyCode === 13) {
                    this.search(this.keyword)
                } 
            }
        },
        props:{
            data: Array,
            search: Function,
            loading: Boolean,
            type: String,
            changeType: Function,
            morePanel: Function
        },
        components:{
            Modals, PanelTableList, PanelCardList
        }
    }
</script>
<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>