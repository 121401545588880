//Stateler çekilip return edilecek

export const getPanelLoading = (state) => {
    return state.loading
}

export const panels = (state) => {
    return state.panels
}

export const getAllPanels = (state) => {
    return state.allPanels
}
export const getPanelPageInfo = (state) => {
    return state.pageInfo
}

export const getPanelDetail = (state) => {
    return state.detail
}

export const getStatus = (state) => {
    return state.status
}

export const getZones = (state) => {
    return state.zones
}
export const getOuts = (state) => {
    return state.outs
}
export const getUsers = (state) => {
    return state.users
}

export const getSettings = (state) => {
    return state.settings
}

export const setSettings = (state) => {
    return state.settingStatus
}

export const getKeypads = (state) => {
    return state.keypads
}

export const getPWD = (state) => {
    return state.pwd
}

export const getAlarmDescriptions = (state) => {
    return state.descriptions
}

export const getSiaCodes = (state) => {
    return state.siaCodes
}


export const getBehaviors = (state) => {
    return state.behaviors
}

export const getConnectTypes = (state) => {
    return state.connectTypes
}

export const getZoneTypes= (state) => {
    return state.zoneTypes
}

export const getOutFuncs = (state) => {
    return state.outFuncs
}

export const getTimedArms = (state) => {
    return state.timedArms
}

export const getautoArms = (state) => {
    return state.autoArms
}

export const getAuth = (state) => {
    return state.auth
}