
// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const dealerRequest = async ({ commit }, data) => {
   commit('dealerLoading', true);
   let res;
   res = await sendReq("post","/dealer-requests", data, {responseType: 'blob' })
   commit('dealerStatus', res);
   commit('dealerLoading', false);
}

export const listDealerRequest = async ({ commit }, data) => {
   commit('dealerLoading', true);
   let res;
   res = await sendReq("get","/dealer-requests", data)
   commit('dealerList', res);
   commit('dealerLoading', false);
}

export const setStateDealer = async ({ commit }, data) => {
   commit('dealerLoading', true);
   let res;
   res = await sendReq("post","/dealer-requests/"+data.id+'/approve', data)
   commit('setState', res);
   commit('dealerLoading', false);
}

