<template>
    <div>
        <validation-observer  ref="armDisarm">

        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-form v-else class="formBox"> 
                 <validation-provider :name="$t('program.armDisarm.entryDelayTime')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.armDisarm.entryDelayTime')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="entryTime" :placeholder="$t('program.armDisarm.entryDelayTime')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.armDisarm.outDelayTime')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.armDisarm.outDelayTime')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="outTime" :placeholder="$t('program.armDisarm.outDelayTime')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                 <validation-provider :name="$t('program.armDisarm.doublePassword')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.armDisarm.doublePassword')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="doublePasswordTime" :placeholder="$t('program.armDisarm.doublePassword')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.armDisarm.reAlarm')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.armDisarm.reAlarm')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="reAlarmPeriod" :placeholder="$t('program.armDisarm.reAlarm')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                 <b-form-group class="inputGrp" :label="$t('program.armDisarm.extendDelay')" label-for="outTimeRelay">
                    <b-form-radio-group
                        id="outTimeRelay"
                        v-model="outTimeRelay"
                        :options="yesNo"
                        aria-describedby="live-feedback"></b-form-radio-group>
                </b-form-group>  

           
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.outDelayAlarm')" label-for="outRelayAlarm">
                <b-form-radio-group
                    id="outRelayAlarm"
                    v-model="outRelayAlarm"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            

            <b-form-group class="inputGrp" :label="$t('program.armDisarm.terminateDelay')" label-for="exitFinish">
                <b-form-radio-group
                    id="exitFinish"
                    v-model="exitFinish"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  

            <b-form-group class="inputGrp" :label="$t('program.armDisarm.homeModeDelay')" label-for="stayMod">
                <b-form-radio-group
                    id="stayMod"
                    v-model="stayMod"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.oneKeyArm')" label-for="quickArm">
                <b-form-radio-group
                    id="quickArm"
                    v-model="quickArm"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp"  :label="$t('program.armDisarm.signalArm')"  label-for="sirenBeep">
                <b-form-radio-group
                    id="sirenBeep"
                    v-model="sirenBeep"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
             <b-form-group class="inputGrp" :label="$t('program.armDisarm.signalDisarm')" label-for="sirenDisArmBeep">
                <b-form-radio-group
                    id="sirenDisArmBeep"
                    v-model="sirenDisArmBeep"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp"  :label="$t('program.armDisarm.armCancel')" label-for="disarmBtn">
                <b-form-radio-group
                    id="disarmBtn"
                    v-model="disarmBtn"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.unBypassDisarm')"  label-for="disarmBypass">
                <b-form-radio-group
                    id="disarmBypass"
                    v-model="disarmBypass"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.outsideDisarm')"  label-for="remoteDisarm">
                <b-form-radio-group
                    id="remoteDisarm"
                    v-model="remoteDisarm"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.doublePasswordArm')"  label-for="disarmBypass">
                <b-form-radio-group
                    id="doublePassword"
                    v-model="doublePassword"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
            <b-form-group class="inputGrp" :label="$t('program.armDisarm.ArmDoor')"  label-for="disarmDoor">
                <b-form-radio-group
                    id="disarmDoor"
                    v-model="disarmDoor"
                    :options="yesNo"
                    aria-describedby="live-feedback"></b-form-radio-group>
            </b-form-group>  
        </b-form>
            </validation-observer>

    </div>
</template>

<script>
    import Modals from "../modals/"
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ArmDisarm',
        data: function(){
            return {
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false},
                    { text: i18n.t('program.general.yes'), value: true},
                ],
                entryTime: 0,
                outTime: 0,
                outTimeRelay:  false,
                exitFinish: false,
                stayMod:false,
                quickArm:false,
                sirenBeep: false,
                sirenDisArmBeep: false,
                disarmBtn:false,
                disarmBypass:  false,
                remoteDisarm: false,
                doublePassword: false,
                outArm: false,
                disarmDoor: false,
                outRelayAlarm: false,
                doublePasswordTime: 0,
                reAlarmPeriod: 0
            }
        },    
         watch: { 
            data: function(newVal, oldVal) { // watch it
                console.log(newVal)
                this.entryTime =  newVal.entry_delay_time 
                this.outTime = newVal.exit_delay_time 
                this.outTimeRelay = newVal.part_futures.exit_delay ? newVal.part_futures.exit_delay : false
                this.outRelayAlarm = newVal.part_futures.exit_delay_alarm ? newVal.part_futures.exit_delay_alarm : false
                this.exitFinish = newVal.part_futures.exit_finish ? newVal.part_futures.exit_finish : false
                this.stayMod = newVal.part_futures.staymod_delay ? newVal.part_futures.staymod_delay : false
                this.quickArm = newVal.part_futures.quickarm ? newVal.part_futures.quickarm : false
                this.sirenBeep = newVal.part_futures.siren_beep_arm ? newVal.part_futures.siren_beep_arm : false
                this.sirenDisArmBeep = newVal.part_futures.siren_beep_disarm ? newVal.part_futures.siren_beep_disarm : false
                this.disarmBtn = newVal.part_futures.delay_disarmbtn_cancel ? newVal.part_futures.delay_disarmbtn_cancel : false
                this.disarmBypass = newVal.part_futures.disarm_bypass_cancel ? newVal.part_futures.disarm_bypass_cancel : false
                this.remoteDisarm = newVal.part_futures.remote_disarm ? newVal.part_futures.remote_disarm : false
                this.doublePassword = newVal.double_password ? newVal.double_password : false
                this.doublePasswordTime = newVal.double_password_time 
                this.disarmDoor = newVal.part_futures.open_door_when_arm ? newVal.part_futures.open_door_when_arm : false
                this.reAlarmPeriod = newVal.re_alarm_period
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.armDisarm.validate().then( async success =>{
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 6,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                entry_delay_time: parseInt(this.entryTime),
                                exit_delay_time: parseInt(this.outTime),
                                re_alarm_period: parseInt(this.reAlarmPeriod),
                                double_password_time: parseInt(this.doublePasswordTime),
                                doublePassword: this.double_password,
                                part_futures:{
                                    exit_delay: this.outTimeRelay,
                                    exit_finish: this.exitFinish,
                                    staymod_delay: this.stayMod,
                                    quickarm: this.quickArm,
                                    siren_beep_arm: this.sirenBeep,
                                    delay_disarmbtn_cancel: this.disarmBtn,
                                    disarm_bypass_cancel: this.disarmBypass ,
                                    remote_disarm: this.remoteDisarm,
                                    open_door_when_arm: this.disarmDoor,
                                    exit_delay_alarm: this.outRelayAlarm,
                                    siren_beep_disarm: this.sirenDisArmBeep,
                                },
                            }
                            await this.$store.dispatch('setPartitionSettings', params)
                        }else{
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                }
                this.saveRemove(false)
            }
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>