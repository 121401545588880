<template>
    <div class="login">
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
        <b-container>
            <b-navbar toggleable="lg">
                    <b-navbar-brand href="#"> <img src="@/assets/img/logo.png" class="logo" alt="Alarm Panelim"> </b-navbar-brand>
                     <b-collapse id="nav-collapse" is-nav>
                        <!-- Right aligned nav items -->
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item href="/login"> Giriş Yap </b-nav-item>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
                <b-row class="content">
                    <b-col cols="12" class="form">
                        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
                        <template v-else>
                            <DealerForm :onSave="onSave" />
                        </template>
                    </b-col>
                </b-row>
                
        </b-container>
    </div>

</template>

<style lang="css" scoped> 
    @import "./styles/login.min.css"; 
</style>
<script>
    import DealerForm from './components/DealerForm'
    import Modals from './modals'
    export default {
        name: "Login",
        data:function(){
            return{
                formType: 1, modalOpen: false, modalProps: {}, currentForm:"User", modalName: "",
            }
        },
        components:{
          Modals, DealerForm
        },
        computed:{
            loading(){
                return this.$store.getters.getDealerLoading
            },
        },
        methods:{
            changeForm: function(data){
                this.formType = data
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
            onSave: async function(data){
                await this.$store.dispatch('dealerRequest', data)
                console.log(this.$store.state.Dealer)
                if(this.$store.state.Dealer.status.status == "Success"){
                    this.openModal(true, 'DealerApply')
                }
            }
        }
    }
</script>
