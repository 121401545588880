<template>
      <div>
        <b-icon icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0"> Emin misiniz ? </h3>
        <p>
            <strong> {{moment(data.created_at)}} </strong> tarihinde  oluşturulan <strong> {{data.price}} {{data.currency}} </strong> tutarındaki  <strong> {{data.user_info.name}} - 
            {{data.user_info.email}} </strong>  ait siparişi iptal etmek üzeresiniz. Bu işlemi yapmadan önce lütfen ödemenin iade edildiğinden emin olun.
         </p>
         <p>
            <b-row > 
                <b-col sm="3"> <label for="name"> İptal Sebebi </label>  </b-col>
                <b-col sm="9" style="display: block; width: 100%"> 
                    <span style="font-size: 18px; font-weight: bold">
                        <b-textarea rows="5" v-model="reason"></b-textarea> 
                    </span>
                </b-col>
                <b-col sm="1"></b-col>
            </b-row>
         </p>
   </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "AccountDelete",
        data:function(){
            return{
                reason: ""
            }
        },
        props:{
            data: Object,
            modalName: String,
            modalEvent: Function,
            save: Boolean,
            saveState: Function,
            formName: String
        },
        methods:{
            moment: function (date) {
                return moment(date.substring(0,19)).format('DD MMMM YYYY');
            },
        },
        watch:{
            save: async function(data){
                if(data){
                    await this.$store.dispatch('cancelOrder',  {desc: this.reason, id: this.data.id})
                    await this.$store.dispatch('orderList')
                    this.saveState(false)
                    this.modalEvent(false)
                }
                this.saveState(false)
            },
        }
    }
</script>