<template>
      <div>
        <b-icon  icon="exclamation-triangle" variant="info" animation="throb" font-scale="4" />
        <hr>
        <h3 class="mb-0">  {{type == 0 ? $t('login.verify') : $t('login.reVerify')}} </h3>
       
        <p > 
           {{type == 0 ?   $t('login.verifyMsg') : $t('login.reVerifyMsg')}}
        </p>
        <template v-if="type == 0">
            <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="code"> Kod </label> </b-col>
                    <b-col sm="8"> 
                        <b-form-input id="code" v-model="code" placeholder="Kod" />
                    </b-col>
                </b-row>
            </b-form-group>
        </template>
        <template v-else>
             <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="username"> {{$t('login.user') }} </label> </b-col>
                    <b-col sm="8"> 
                        <b-form-input id="username" v-model="username" :placeholder="$t('login.user') " />
                    </b-col>
                </b-row>
            </b-form-group>
             <b-form-group class="inputGrp">
                <b-row>
                    <b-col sm="4"> <label for="pwd"> {{$t('login.password') }} </label> </b-col>
                    <b-col sm="8"> 
                        <b-form-input id="pwd" type="password" v-model="pwd" placeholder="Şifre" />
                    </b-col>
                </b-row>
            </b-form-group>
        </template>
        <div class="registerLink" @click="changeForm(type == 0 ? 1 : 0)"> {{type == 0 ? $t('login.reVerify') : $t('login.verify')}} </div>

   </div>
</template>

<script>
    import i18n from '@/plugins/i18n';
    export default {
        name: "SetStateCustomer",
        data:function(){
            return{
                code: "",
                username: "",
                pwd: "",
                type: ""
            }
        },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
        methods:{
            changeForm: function(val){
                this.type = val;
            }
        },
         watch: {
            save: async function(data){
                if(data){
                    if(this.type == 0){
                        let data = { code: this.code }
                        await this.$store.dispatch('verifyUser', data)
                        if(this.$store.state.User.userStatus.data.status== "Success"){
                            vm.$root.$bvToast.toast(i18n.t('messages.verifySuccess'), {
                                title: i18n.t('general.success'),
                                variant: 'success',
                                noAutoHide: true
                            })
                        }
                        this.modalEvent(false)
                    }else{
                         let data = { 
                            Username:this.username,
                            Password:this.pwd
                        }
                        await this.$store.dispatch('reSendCode', data)
                        if(this.$store.state.User.userStatus.data.status== "Success"){
                            vm.$root.$bvToast.toast(i18n.t('messages.verifyCommand'), {
                                title: i18n.t('general.success'),
                                variant: 'success',
                                noAutoHide: true
                            })
                            this.type = 0
                        }
                    }
                }
                this.saveState(false)
            }
        }
    }
</script>