//Stateler çekilip return edilecek
export const getYandexList = (state) => {
    return state.yandexList
}

export const getYandexFiles = (state) => {
    return state.yandexFiles
}

export const getYandexLoading = (state) => {
    return state.loading
}

export const getYandexDownload = (state) => {
    return state.yandexDownload
}
