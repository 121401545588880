<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="dealers" :filtered="filtered" type="List" :loading="loading" :actions="actions"
         :inputs="inputs" :search="search" :actionEvent="actionEvent" />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";

    let breadCrumb = [
        { label: i18n.t('user.dealers.dealer'), url: "#" },
    ]
     let fields = [ 
         { key: 'dealer', label: 'Bayi Adı', keys:['dealer_name']},
         { key: 'name', label: 'Talep Eden', keys:['name'] },
         { key: 'mail', label: 'E-posta Adresi', keys:['email'] },
         { key: 'phone', label: 'Telefon Numarası', keys:['phone'] },
         { key: 'seller', label: 'Satıcı Firma', keys:['seller'] },
         { key: 'tax', type:'show', label: 'Vergi Levhası', keys:['tax_plate_link'] },
         { key: 'logo', type:'show',label: 'Firma Logosu', keys:['logo_link'] },
         { key: 'state', type:'state',label: 'Durum', keys:['state'] },

         { key: 'actions', label: i18n.t('general.operation'), }        
    ]; 

    let actions = [
        {key: 'approve', text: 'Bayiliği Aktif Et', state: 1},
        {key: 'reject', text: 'Bayiliği Red Et', state: 2},
    ]

    let filtered = ['state', 'dealer', "name", 'mail', 'phone',  'seller', 'tax', 'logo',  'actions']; 
    
    let inputs = [ 
        { key: "state", label: "Durumu", type: "Select",checked: false,val: "", array: [
            { text: "Bekleyen Bayilikler", value: 0},
            { text: "Onaylanan Bayilikler", value: 1},
            { text: "Red Edilen Bayilikler", value: 2}
        ]},
    ]; 
    export default {
        name: 'DealerList',
         created: async function(){
            await this.$store.dispatch('listDealerRequest', this.params)
        },
        computed:{
            dealers(){
                return this.$store.getters.getDealerList.data
            },
            pageInfo(){
                return this.$store.getters.getDealerPageInfo
            }

        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"Dealer", modalName: "", searchVal:"", inputs,  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                }

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },
        watch:{
            limit(val){
                this.params['limit'] = val
                this.$store.dispatch('listDealerRequest', this.params)
            },
            offset(val){
                 this.params['offset'] = val
                 this.$store.dispatch('listDealerRequest', this.params)
            },
            searchVal(val){
                 this.params['search'] = val
                 this.$store.dispatch('listDealerRequest', this.params)
            }
        },

        methods:{
           
            complete(){
                this.$store.dispatch('listUser', this.params)
            },

              search(){
                this.inputs.map((input)=>{
                    input.checked ? this.params[input.key] = input.val : delete this.params[input.key]
                })
                console.log(this.params,  this.inputs)
                this.$store.dispatch('listDealerRequest', this.params)
            },

            setLimit:function(limit){
                this.limit = limit
            },
            setPage:function(page){
                if(page > 0){
                    this.page = page
                    this.offset = ((page-1) * this.limit)
                }
            },
            actionEvent: async function(key, data){
                console.log(data, key)
                switch(key){
                    case 'approve':
                        this.openModal(true, 'SetState', {dealer: data, state:true})
                        break;
                    case 'reject': 
                        this.openModal(true, 'SetState', {dealer: data, state:false})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>