<template>
    <div>
        <p> 
            Iade veya değişim için gelen ve üretime ya da stoğa alınan panellerin tekrar satışı yapılmadan önce mutlaka sıfırlanması gerekmektedir. Aksi halde satın alan 
            kullanıcı panelini mobil uygulamaya ekleyemeyebilir veya önceden satın almış olan kullanıcıya yanlış bildirimler gidebilir. Lütfen sıfırlamak istediğiniz panelin
            imei ya da mac adresini girerek sıfırlama işlemini tamamlayınız.
        </p>

        <strong> 
            Mevcutta çalışan bir panelde bu işlemi yaparsanız panel bütün kullanıcılardan silinir ve yeniden tuş takımına kod düşürerek paneli eklemeniz gerekir. Panel tam 
            olarak yanınızda olmadan bu işlemi gerçekleştirmeyiniz
        </strong>

        <form ref="form" style="margin-top: 10px;">
        
        <b-form-group>
           <b-row>
               <b-col sm="4"> <label for="accountName"> IMEI / MAC Adresi </label> </b-col>
               <b-col sm="8"> <b-form-input id="accountName" v-model="dev_id" placeholder="IMEI / MAC Adresi" /></b-col>
           </b-row>
       </b-form-group>
   </form>
    </div>
   
</template>

<script>
    export default {
        name: "AddPanel",
         props: {
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        data: function(){
            return{
                dev_id:  ''
            }
        },
       
        watch: {
            save: async function(data){
                if(data){
                    let data = {
                        dev_id: this.dev_id
                    }
                    await this.$store.dispatch('resetPanel',data)
                }
                this.saveState(false)
            }
        }
        
    }
</script>