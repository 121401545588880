// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const listPayment = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","payment-plans", data)
    commit('payments', res.data);
    commit('paymentLoading', false);
}

export const paymentInfo = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","payment-plans/"+data)
    commit('paymentInfo', res.data);
    commit('paymentLoading', false);
}
export const paymentSettings = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","payment-settings")
    commit('paymentSettings', res.data);
    commit('paymentLoading', false);
}
export const codeRequest = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("post","coupons",data)
    commit('couponReq', res.data);
    commit('paymentLoading', false);
}
export const codeRequests = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","coupons",data)
    commit('couponReqs', res.data);
    commit('paymentLoading', false);
}
export const codeHistory = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","orders/history",data)
    commit('couponHistory', res.data);
    commit('paymentLoading', false);
}
export const codeList = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","coupons/"+data.id+"/codes")
    commit('couponList', res.data);
    commit('paymentLoading', false);
}

export const orders = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("post","orders",data)
    commit('orders', res.data);
    commit('paymentLoading', false);
}

export const orderList = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","orders", data)
    commit('orderList', res.data);
    commit('paymentLoading', false);
}

export const couponOrder = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("post","orders/coupon",data)
    commit('paymentStatus', res.data);
    commit('paymentLoading', false);
}

export const billingOrder = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("put","orders/"+data.id+'/billing')
    commit('paymentStatus', res.data);
    commit('paymentLoading', false);
}

export const cancelOrder = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("put","orders/"+data.id+'/cancel',data)
    commit('paymentStatus', res.data);
    commit('paymentLoading', false);
}

export const couponRequest = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("post","coupons/create-for-administrator",data)
    commit('paymentStatus', res.data);
    commit('paymentLoading', false);
}

export const paymentReportList = async ({ commit }, data) => {
    commit('paymentLoading', true);
    let res;
    res = await sendReq("get","reports/order",data)
    commit('paymentReport', res.data);
    commit('paymentLoading', false);
}