<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <Table :fields="fields" :items="stages" :filtered="filtered" type="List" :loading="loading"  :actions="actions" :actionEvent="actionEvent" />
        <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" :onComplete="complete" />
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import Table from "@/components/Table"
    import i18n from '@/plugins/i18n';
    import Modals from "./modals/";

    let breadCrumb = [
        { label: 'Panel Yönetimi', url: "" },
        { label: 'Versiyon Test', url: "/PanelTest/List" },
        { label: 'Test Aşamaları', url: "" },
    ]
     let fields = [ 
         { key: 'description', label: 'Açıklama', keys:['description'] },
         { key: 'actions', label: 'İşlemler', }        
    ]; 

    let actions = [
        {key: 'edit', text: 'Aşamayı Düzenle'},
        {key: 'delete', text: 'Aşamayı Sil'},
        {key: 'addStage', text: 'Yeni Aşama Ekle'}
    ]

    

    let filtered = [ "description", 'actions']; 

    export default {
        name: 'UserList',
         created: async function(){
            await this.$store.dispatch('listFirmwaresStage', {id: this.$route.params.id})
        },
        computed:{
            stages(){
                return this.$store.getters.getFirmwareStages.data
            },
        },
        data: function(){
            return{
                breadCrumb, fields, filtered, limit:10, page: 1, offset: 0, actions, modalOpen: false,
                modalProps: {}, currentForm:"Stage", modalName: "", searchVal:"",  params:{
                    limit:10,
                    offset: 0,
                    search: ""
                },
               

            }
        },
        components:{
            BreadCrumb, Table, Modals
        },

        methods:{
           
            actionEvent: async function(key, data){
                switch(key){
                     case 'addStage':
                        this.openModal(true, 'addStage',  {id: parseInt(this.$route.params.id)})
                        break;
                     case 'edit':
                        this.openModal(true, 'edit',  {stage: data})
                        break;
                    case 'delete':
                        this.openModal(true, 'delete',  {stage: data})
                        break;
                }
            },
            openModal: function(state, modal, props){
                this.modalOpen = state
                this.modalProps = props
                this.modalName = modal
            },
        }
    }
</script>