<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <b-table-simple v-else hover responsive>
            <b-thead>
                <b-th> {{$t('program.logs.date')}} </b-th>
                <b-th> {{$t('program.logs.responsible')}} </b-th>
                <b-th> {{$t('program.logs.desc')}} </b-th>
            
            </b-thead>
            <b-tbody >
                <b-tr v-for="item in data" :key="item.ID"> 
                    <b-td> {{moment(item.event_time)}}</b-td>
                    <b-td > {{item.parameter}}</b-td>
                    <b-td> {{item.description}}</b-td>
                </b-tr>
                
            </b-tbody>
        </b-table-simple>
    </div>
    
</template>

<script>
    import Modals from "../modals/"
    import moment from 'moment'

    export default {
        name: 'ProsecPanel',
     
       
        props:{
            data: Array,
            loading: Boolean,
            openModal: Function
        },
        methods:{
             moment: function (date) {
                return moment(date).format('DD MMMM YYYY HH:mm');/*.utcOffset(0, false).format('DD MMMM YYYY HH:mm');*/
            }
        },
        components:{
            Modals
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>