<template>
    <div>
        <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
        <b-tabs v-else :value="type-1" fill >
            <b-tab :title="$t('program.cms.tab1') " @click="getData(1)">
                <b-card-text style="margin-top: 1rem">
                    <validation-observer  ref="ahm1">
                         <b-form class="formBox"> 
                            <b-form-group class="inputGrp" :label="$t('program.cms.cmsConnection')" label-for="cmsConnect">
                                <b-form-radio-group
                                    id="cmsConnect"
                                    v-model="cmsActive"
                                    :options="cmsConnects"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>      

                            <b-form-group class="inputGrp" :label="$t('program.cms.connectType')" label-for="type">
                                <b-form-radio-group
                                    id="type"
                                    v-model="connectType"
                                    :options="types"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>

                             <validation-provider :name="$t('program.cms.cmsNo')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="cmsNo"> {{$t('program.cms.cmsNo')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="cmsNo" v-model="cmsNo" :placeholder="$t('program.cms.cmsNo')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>

                            <validation-provider :name="$t('program.cms.cmsName')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsName')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsName" :placeholder="$t('program.cms.cmsName')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsAccount')" :rules="{ integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsAccount')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="accountNo" :placeholder="$t('program.cms.cmsName')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider v-if="connectType == 0"  :label="$t('program.cms.cmsIp')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsIp')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsIP" :placeholder="$t('program.cms.cmsIp')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPort')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPort')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPort" :placeholder="$t('program.cms.cmsPort')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPing')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPing')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPing" :placeholder="$t('program.cms.cmsPing')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPrefix')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPrefix')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPrefix" :placeholder="$t('program.cms.cmsPrefix')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsRec')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsRec')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsRec" :placeholder="$t('program.cms.cmsRec')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                           <!-- <b-form-group v-else  class="inputGrp" label="AHM URL" label-for="cmsURL">
                                <div class="elements">
                                    <b-form-input id="cmsURL" v-model="cmsURL" />
                                </div>
                            </b-form-group>-->
                        </b-form>
                    </validation-observer>
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('program.cms.tab2')" @click="getData(2)" >
                <b-card-text style="margin-top: 1rem">
                   <validation-observer  ref="ahm2">
                         <b-form class="formBox"> 
                            <b-form-group class="inputGrp" :label="$t('program.cms.cmsConnection')" label-for="cmsConnect">
                                <b-form-radio-group
                                    id="cmsConnect"
                                    v-model="cmsActive"
                                    :options="cmsConnects"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>      

                            <b-form-group class="inputGrp" :label="$t('program.cms.connectType')" label-for="type">
                                <b-form-radio-group
                                    id="type"
                                    v-model="connectType"
                                    :options="types"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>

                             <validation-provider :name="$t('program.cms.cmsNo')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="cmsNo"> {{$t('program.cms.cmsNo')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="cmsNo" v-model="cmsNo" :placeholder="$t('program.cms.cmsNo')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>

                            <validation-provider :name="$t('program.cms.cmsName')" :rules="{ regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsName')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsName" :placeholder="$t('program.cms.cmsName')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsAccount')" :rules="{ integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsAccount')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="accountNo" :placeholder="$t('program.cms.cmsName')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                    
                                </b-form-group>
                            </validation-provider>
                            <validation-provider v-if="connectType == 0"  :name="$t('program.cms.cmsIp')" :rules="{regex: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsIp')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsIP" :placeholder="$t('program.cms.cmsIp')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPort')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPort')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPort" :placeholder="$t('program.cms.cmsPort')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPing')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPing')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPing" :placeholder="$t('program.cms.cmsPing')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsPrefix')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsPrefix')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsPrefix" :placeholder="$t('program.cms.cmsPrefix')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider :name="$t('program.cms.cmsRec')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.cmsRec')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="cmsRec" :placeholder="$t('program.cms.cmsRec')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                           <!-- <b-form-group v-else  class="inputGrp" label="AHM URL" label-for="cmsURL">
                                <div class="elements">
                                    <b-form-input id="cmsURL" v-model="cmsURL" />
                                </div>
                            </b-form-group>-->
                        </b-form>
                    </validation-observer>
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('program.cms.tab3')" @click="getData(3)" >
                <b-card-text style="margin-top: 1rem">
                   <validation-observer  ref="general">
                        <b-form class="formBox">  
                            <b-form-group class="inputGrp" :label="$t('program.cms.cmsConnection')" label-for="cmsConnect2">
                                <b-form-radio-group
                                    id="cmsConnect2"
                                    v-model="cmsActiveGeneral"
                                    :options="cmsConnects"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>               
                            <b-form-group class="inputGrp" :label="$t('program.cms.siaTime')" label-for="SIATime">
                                <b-form-radio-group
                                    id="SIATime"
                                    v-model="SIATime"
                                    :options="yesNo"
                                    aria-describedby="live-feedback"></b-form-radio-group>
                            </b-form-group>  
                            <validation-provider :name="$t('program.cms.timeout')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.timeout')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="timeout" :placeholder="$t('program.cms.timeout')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>       
                            
                            <validation-provider :name="$t('program.cms.reportDelay')" :rules="{integer}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.reportDelay')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <b-form-input id="name" v-model="power_delay" :placeholder="$t('program.cms.reportDelay')" :state="getValidationState(validationContext)" />
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider> 

                            
                            <b-form-group  class="inputGrp" :label="$t('program.cms.reportTime')" label-for="accountNo">
                                <div class="elements">
                                    <Datetime v-model="reportTime" type="time" ></Datetime>
                                </div>
                            </b-form-group>

                            <validation-provider :name="$t('program.cms.reportPeriod')" :rules="{required}"  v-slot="validationContext">
                                <b-form-group class="inputGrp">
                                    <b-row>
                                        <b-col sm="4"> <label for="name"> {{$t('program.cms.reportPeriod')}} </label> </b-col>
                                        <b-col sm="8"> 
                                            <Multiselect  
                                                id="reportPeriod"
                                                v-model="reportPeriod"
                                                :show-labels="false" 
                                                :options="periods"
                                                label="text"
                                                track-by="value" >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <strong>{{ option.text }} </strong> 
                                                    </template>
                                            </Multiselect>
                                            <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-col>
                                    </b-row>
                                </b-form-group>
                            </validation-provider>
                        </b-form>
                   </validation-observer>
                </b-card-text>
            </b-tab>
            
        </b-tabs>
    </div>
 
</template>

<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import moment from 'moment'
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                type: 1,
                connectType: false,
                cmsNo: this.data.cms_no ? this.data.cms_no : "",
                cmsName: this.data.cms_name ? this.data.cms_name : "",
                accountNo: this.data.account ? this.data.account : "",
                cmsIP:  this.data.ip ? this.data.ip : "",
                cmsURL:  this.data.url ? this.data.url : "",
                cmsPort:  this.data.port ? this.data.port : "",
                cmsPing:  this.data.account ? this.data.ping_period : "",
                cmsPrefix: this.data.prefix ? this.data.prefix : "",
                cmsRecNo: this.data.rec_no ? this.data.rec_no : "",
                cmsActive: this.data.active ? this.data.active : false,
                cmsActiveGeneral: false,
                SIATime: false,
                timeout: 0,
                power_delay: 0,
                reportTime: 0,
                reportPeriod: 0,
                types: [
                    { text: i18n.t('program.general.ip'), value: false },
                    { text: i18n.t('program.general.url'), value: true },
                ],
                cmsConnects: [
                    { text: i18n.t('program.general.passive'), value: false },
                    { text: i18n.t('program.general.active'), value: true },
                ],
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.yes'), value: true },
                ],
                periods: [
                    { text: i18n.t('program.cms.reportTimeElements[0]'), value: 0 },
                    { text: i18n.t('program.cms.reportTimeElements[1]'), value: 1 },
                    { text: i18n.t('program.cms.reportTimeElements[2]'), value: 2 },
                    { text: i18n.t('program.cms.reportTimeElements[3]'), value: 3 },
                    { text: i18n.t('program.cms.reportTimeElements[4]'), value: 4 },
                    { text: i18n.t('program.cms.reportTimeElements[5]'), value: 5 },
                    { text: i18n.t('program.cms.reportTimeElements[6]'), value: 6 },
                    { text: i18n.t('program.cms.reportTimeElements[7]'), value: 7 },
                ]
            }
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            }
        },
        methods: {
            async getData(type){
                this.type = type
                console.log("click")
                let params = {
                    dev_id: parseInt(this.$route.params.id),
                    command: 'GET',
                    group: 3,
                    member: 0,
                    user_pass: this.$store.getters.getPWD,
                    offset: 0
                }
                        
                if(type == 1){
                    params.group = 3
                    params.member = 0
                    await this.$store.dispatch('panelSettings', params)
                }else if(type == 2){
                    params.group = 3
                    params.member = 1
                    await this.$store.dispatch('panelSettings', params)
                }else{
                    params.group = 17
                    params.member = 2 //Genel Ayarlar için member 2 olarak kullanıyoruz
                    await this.$store.dispatch('panelSettings', params)
                }
            },
             moment: function (date) {
                return moment(date).format('HH:mm:ss');
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        watch: { 
            settings: function(newVal, oldVal) { // watch it
                if(newVal.group == 3){
                    this.cmsNo = newVal.cms_id ? newVal.cms_id : 0,
                    this.cmsName = newVal.cms_name ? newVal.cms_name : "",
                    this.accountNo = newVal.account ? newVal.account : "",
                    this.cmsIP = newVal.ip ? newVal.ip : "",
                    this.cmsURL =  newVal.url ? newVal.url : "",
                    this.cmsPort =  newVal.port ? newVal.port : "",
                    this.cmsPing =  newVal.account ? newVal.ping_period : "",
                    this.cmsPrefix = newVal.prefix ? newVal.prefix : "",
                    this.cmsRecNo = newVal.rec_no ? newVal.rec_no : "",
                    this.cmsActive = newVal.active ? newVal.active : false
                    this.connectType = newVal.use_domainname ? newVal.use_domainname : false
                }else{
                   
                    this.cmsActiveGeneral = newVal.options ? newVal.options.enabled : false;
                    this.SIATime = newVal.options ? newVal.options.add_time : false
                    this.timeout = newVal.con_timemout ? newVal.con_timemout : 0;
                    this.power_delay = newVal.power_delay ? newVal.power_delay : 0;
                    this.reportTime = newVal.test_time ? newVal.test_time.hour+":"+newVal.test_time.minute : 0;
                    this.reportPeriod = newVal.test_period ? this.periods[newVal.test_period] : this.periods[0]
                }
               
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    let paramaters = {
                        dev_id:parseInt(this.$route.params.id),
                        user_pass: this.$store.getters.getPWD
                    }
                    switch(this.type){
                        case 1:
                            this.$refs.ahm1.validate().then( async success =>{
                                if(success){
                                    paramaters.cms_no = parseInt(this.cmsNo);
                                    paramaters.cms_id = this.data.cms_id;
                                    paramaters.cms_name = this.cmsName;
                                    paramaters.account = this.accountNo;
                                    paramaters.prefix = this.cmsPrefix;
                                    paramaters.rec_no = this.cmsRecNo;
                                    paramaters.ip = this.cmsIP;
                                    paramaters.port = parseInt(this.cmsPort);
                                    paramaters.ping_period = parseInt(this.cmsPing);
                                    paramaters.url = this.cmsURL;
                                    paramaters.active = this.cmsActive;
                                    paramaters.group = 3;
                                    paramaters.member = 0;
                                    paramaters.use_domainname = this.connectType;
                                    await this.$store.dispatch('setPanelCMS', paramaters)
                                }else{
                                    vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                        title: i18n.t('program.general.warning'),
                                        variant: 'info',
                                        noAutoHide: true
                                    })
                                }
                            })
                            break;
                        case 2:
                            this.$refs.ahm2.validate().then( async success =>{
                                if(success){
                                    paramaters.cms_no = parseInt(this.cmsNo);
                                    paramaters.cms_id = this.data.cms_id;
                                    paramaters.cms_name = this.cmsName;
                                    paramaters.account = this.accountNo;
                                    paramaters.prefix = this.cmsPrefix;
                                    paramaters.rec_no = this.cmsRecNo;
                                    paramaters.ip = this.cmsIP;
                                    paramaters.port = parseInt(this.cmsPort);
                                    paramaters.ping_period = parseInt(this.cmsPing);
                                    paramaters.url = this.cmsURL;
                                    paramaters.active = this.cmsActive;
                                    paramaters.group = 3;
                                    paramaters.member = 1;
                                    paramaters.use_domainname = this.connectType;
                                    await this.$store.dispatch('setPanelCMS', paramaters)
                                }else{
                                    vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                        title: i18n.t('program.general.warning'),
                                        variant: 'info',
                                        noAutoHide: true
                                    })
                                }
                            })
                            break;
                        case 3:
                            this.$refs.ahm2.validate().then( async success =>{
                                if(success){
                                    paramaters.con_timemout = parseInt(this.timeout);
                                    paramaters.power_delay = parseInt(this.power_delay)
                                    paramaters.test_time = {
                                        hour: moment(this.reportTime).format('HH'),
                                        minute: moment(this.reportTime).format('mm')
                                    }
                                    paramaters.test_period = parseInt(this.reportPeriod.value)
                                    paramaters.options = {
                                        enabled: this.cmsActiveGeneral,
                                        add_time: this.SIATime
                                    }
                                    paramaters.group = 17;
                                    paramaters.member = 0;
                                    await this.$store.dispatch('setPanelGenCMS', paramaters)
                                }else{
                                    vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                        title: i18n.t('program.general.warning'),
                                        variant: 'info',
                                        noAutoHide: true
                                    })
                                }
                            })
                            break;
                    }
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        components:{
            Modals, Datetime, Multiselect
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>