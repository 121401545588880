<template>
    <div>
        <BreadCrumb :data="breadCrumb" /> 
        <b-card no-body class="formGroup">
            <b-tabs pills card :value="activeIndex">
                <b-tab :title="$t('program.menus.General')" @click="pageSettings([], 'General')">
                    <b-card-text>
                        <Panel :loading="loading" :data="[panel]" type="detail" />
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('program.menus.Zones')" @click="pageSettings(['add'], 'Zone')" >
                    <b-card-text>
                        <Zones :loading="loading" :openModal="openModal" :data="zones" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('program.menus.Users')"  @click="pageSettings(['add'], 'Users')">
                    <b-card-text> 
                        <Users :loading="loading" :openModal="openModal" :data="users" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('program.menus.Outs')" @click="pageSettings(['add'], 'Outputs')"  >
                   <b-card-text>
                        <Outputs :loading="loading" :openModal="openModal" :data="outs" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('program.menus.Logs')" @click="pageSettings([], 'Logs')">
                    <b-card-text>
                       <Logs :loading="settingLoading" :modalEvent="openModal" :data="compData" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
                 <b-tab :title="$t('program.menus.Keypads')" @click="pageSettings(['add'], 'Keypads')">
                    <b-card-text> 
                        <Keypads :loading="loading" :modalEvent="openModal" :data="keypads" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
               
                <b-tab :title="$t('program.menus.PTS')" @click="pageSettings([], 'Reader')">
                    <b-card-text> 
                        <Reader :loading="settingLoading" :modalEvent="openModal" :data="readers" :auth="auth" :cmd="cmd" />
                    </b-card-text>
                </b-tab>
                <b-tab :title="otherTab && $t('program.menus.'+otherTab)" @click="pageSettings(buttons, otherTab)">
                    <b-card-text> 
                          <component :auth="auth" :cmd="cmd" :loading="loading" v-bind:is="otherTab" :modalType="pageSettings" :modalEvent="openModal" :data="compData" :save="saveState" :saveRemove="saveRemove"></component>
                    </b-card-text>
                </b-tab>
            </b-tabs>
            <template v-slot:footer class="footer">
                <div> {{$t('program.general.loginUser')}} <strong> {{auth.uname}}</strong> </div>
                <div class="footerButtons">
                    <b-dropdown variant="warning" style="margin-right: 1rem" v-if="auth.auth.installer">
                        <template slot="button-content"> 
                            {{$t('program.general.otherMenu')}}
                            </template>
                        <b-dropdown-item @click="otherTabClick('CMS', 7)"> {{$t('program.menus.CMS')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('ArmDisarm', 7)"> {{$t('program.menus.ArmDisarm')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('AlarmSettings', 7)"> {{$t('program.menus.AlarmSettings')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('SIAEvent', 7)"> {{$t('program.menus.SIAEvent')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('Automation', 7 )"> {{$t('program.menus.Automation')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('Network', 7)"> {{$t('program.menus.Network')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('GSM', 7)"> {{$t('program.menus.GSM')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('NTP', 7)"> {{$t('program.menus.NTP')}}  </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('Stationary', 7)"> {{$t('program.menus.Stationary')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('TimeArmDisarm', 7 )"> {{$t('program.menus.TimeArmDisarm')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('SMS', 7)"> {{$t('program.menus.SMS')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('RemoteServer', 7)"> {{$t('program.menus.RemoteServer')}} </b-dropdown-item>
                         <b-dropdown-item @click="otherTabClick('KeypadGeneral', 7)"> {{$t('program.menus.KeypadGeneral')}} </b-dropdown-item>
                         <b-dropdown-item @click="otherTabClick('PanelGeneral', 7)"> {{$t('program.menus.PanelGeneral')}} </b-dropdown-item>
                        <b-dropdown-item @click="otherTabClick('PanelMessage', 7 )"> {{$t('program.menus.PanelMessage')}} </b-dropdown-item>
                    </b-dropdown>
                    <b-button variant="success" v-if="newButton" @click="openModal(true, 'add' )">{{$t('general.add')}}</b-button>
                    <b-button variant="primary" v-if="saveButton" @click="saveForm" >{{$t('general.save')}}</b-button>
                </div>
          
            </template>
        </b-card>
         <Modals :open="modalOpen" :modalEvent="openModal" :modalProps="modalProps" :form="currentForm" :modalName="modalName" />
    </div>
</template>

<script>
    import Modals from "./modals/"
    import BreadCrumb from '@/components/BreadCrumb'
    import Panel from './components/Panel'
    import Zones from './components/Zones.vue'
    import Outputs from './components/Outputs.vue'
    import Users from './components/Users.vue'
    import Logs from './components/Logs.vue'
    import Keypads from './components/Keypads.vue'
    import ArmDisarm from './components/ArmDisarm.vue'
    import CMS from './components/CMS.vue'
    import AlarmSettings from './components/AlarmSettings.vue'
    import SIAEvent from './components/SIAEvent.vue'
    import Network from './components/Network.vue'
    import NTP from './components/NTP.vue'
    import GSM from './components/GSM.vue'
    import SMS from './components/SMS.vue'
    import Stationary from './components/Stationary.vue'
    import Automation from './components/Automation.vue'
    import TimeArmDisarm from './components/TimeArmDisarm.vue'
    import RemoteServer from './components/RemoteServer.vue'
    import KeypadGeneral from './components/KeypadGeneral.vue'
    import PanelGeneral from './components/PanelGeneral.vue'
    import PanelMessage from './components/PanelMessage.vue'
    import Reader from './components/Reader.vue'
    import i18n from '@/plugins/i18n'

    let breadCrumb = [
        { label: i18n.t('login.title'), url: "/Panels" },
        { label: i18n.t('program.general.title'), url: "#" },
    ]
    export default {
        name: 'ProsecPanelDetail',
         mqtt: {
           'Sanat/+' (data, topic) {
            if (topic.split('/').pop() === this.panel.dev_id) {
               //this.$store.detail = JSON.parse(this.bin2String(data))
               this.$store.commit('panelDetail', {data: JSON.parse(this.bin2String(data))})
                console.log('topic:',this.$store.detail )
                console.log(this.$store.getters.getPanelDetail)
            }
           }
        },
        created: async function(){
            await this.$store.dispatch('panelDetail', {id: this.$route.params.id})
            this.$mqtt.subscribe('Sanat/'+this.panel.dev_id)
        },
        data: function(){
            return{
                breadCrumb,
                newButton: false,
                saveButton: false,
                modalOpen: false,
                tolerans: false,
                currentForm: null,
                modalProps: {},
                modalName: null,
                activeIndex: 0,
                otherTab:"",
                saveState: false,
                compData: {},
                buttons: [],
                cmd : {
                    dev_id: parseInt(this.$route.params.id),
                    member: 0,
                    user_pass: this.$store.getters.getPWD,
                    offset: 0
                }
            }
        },
        computed:{
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
            panel(){
                return this.$store.getters.getPanelDetail
            },
            zones(){
                return this.$store.getters.getZones
            },
            outs(){
                return this.$store.getters.getOuts
            },
            users(){
                return this.$store.getters.getUsers
            },
            keypads(){
                return this.$store.getters.getKeypads
            },
            settings(){
                return this.$store.getters.getSettings
            },
            descriptions(){
                return this.$store.getters.getAlarmDescriptions
            },
            siaCodes(){
                return this.$store.getters.getSiaCodes
            },            
            pwd(){
                return this.$store.getters.getPWD
            },
            auth(){
                return this.$store.getters.getAuth
            },
            command(){
                return this.$store.getters.getCommand
            },
            readers(){
                return this.$store.getters.getReader
            }
        },
        components:{
            BreadCrumb, Zones, Modals, Outputs, Users, Logs, Keypads, ArmDisarm, AlarmSettings,
            CMS, SIAEvent, Network, NTP, GSM, SMS, Stationary, Automation, TimeArmDisarm, RemoteServer,
            KeypadGeneral, PanelGeneral, PanelMessage, Panel, Reader
        },
        watch:{
            descriptions: function(newVal, oldVal){
                 this.compData = newVal
            },
            siaCodes: function(newVal, oldVal){
                 this.compData = newVal
            },
            settings: function(newVal, oldVal){
                if(this.currentForm == "AlarmSettings" || this.currentForm == "SIAEvent"){
                    if(typeof newVal == "Array"){
                        this.compData = newVal
                    }
                }else{
                 this.compData = newVal
                }
            },
            command: function(newVal, oldVal){
                 this.compData = newVal
            }
        },
        methods:{
            bin2String: function(array) {
                let encodedString = String.fromCharCode.apply(null, array),
                decodedString = decodeURIComponent(escape(encodedString));
                return decodedString;
            },
            otherTabClick: function(val, index){
              this.otherTab = val
              this.activeIndex = index
              this.buttons = ['save']
              this.pageSettings( this.buttons, val)     
            },
            openModal: function(state, modal, props){
                this.modalProps = props
                this.modalName = modal
                this.modalOpen = state
            },
            saveForm: function(){
                this.saveState = true
            },
             saveRemove: function(data){
                this.saveState = data
            },
            pageSettings: async function(state, form, data){
                this.newButton = state.indexOf('add') >= 0
                this.saveButton = state.indexOf('save') >= 0
                this.currentForm = form
                 let params = {
                    dev_id: parseInt(this.$route.params.id),
                    command: 'GET',
                    group: 3,
                    member: 0,
                    user_pass: this.$store.getters.getPWD,
                    offset: 0
                }
                switch(form){
                    case 'General':
                        this.activeIndex = 0
                        this.newButton = false
                        await this.$store.dispatch('panelDetail', {id: this.$route.params.id})
                        break;
                    case 'Zone':
                        this.activeIndex = 1
                        this.newButton = this.$store.getters.getAuth.auth.installer
                        await this.$store.dispatch('panelZones', {id: this.$route.params.id})
                        break;
                    case 'Users':
                        this.activeIndex = 2
                        await this.$store.dispatch('panelUsers', {id: this.$route.params.id}) 
                        break;
                    case 'Outputs':
                        this.activeIndex = 3
                        this.newButton = this.$store.getters.getAuth.auth.installer
                        await  this.$store.dispatch('panelOuts',{id: this.$route.params.id})
                        break;
                    case 'Logs':
                        this.activeIndex = 4;
                         let cmd = {
                            dev_id: parseInt(this.$route.params.id),
                            command: 'EVENT',
                            event_start_pos: 1,
                            event_count: 10,
                            user_pass: this.$store.getters.getPWD,
                            offset: 0
                        }
                        await this.$store.dispatch('panelCommand', cmd)
                        break;
                    case 'Keypads':  
                        this.activeIndex = 5
                        this.newButton = this.$store.getters.getAuth.auth.installer
                        await this.$store.dispatch('panelKeypads', {id: this.$route.params.id})
                        break;
                    case 'Reader':
                        this.activeIndex = 6
                        this.newButton = false
                        await  this.$store.dispatch('panelReader',{id: this.$route.params.id})
                        break;
                    case 'CMS':
                        this.activeIndex = 7
                        params.group = 3;
                       params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'ArmDisarm':
                        this.activeIndex = 7
                        params.group = 6;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'AlarmSettings':
                        this.activeIndex = 7
                        await this.$store.dispatch('alarmDesc')
                        break;
                    case 'SIAEvent':
                        this.activeIndex = 7
                        await this.$store.dispatch('siaCodes')
                        break;
                    case 'Automation':
                        this.activeIndex = 7
                        break;
                     case 'TimeArmDisarm':
                        this.activeIndex = 7
                        break;
                    case 'Network':
                        this.activeIndex = 7
                         params.group = 10;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                     case 'NTP':
                        this.activeIndex = 7
                         params.group = 14;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'GSM':
                        this.activeIndex = 7
                         params.group = 9;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'SMS':
                        this.activeIndex = 7
                         params.group = 18;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                     case 'Stationary':
                        this.activeIndex = 7
                         params.group = 11;
                        params.member = 0;
                        await this.$store.dispatch('autoArmList')
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'RemoteServer':
                        this.activeIndex = 7
                         params.group = 13;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'KeypadGeneral':
                        this.activeIndex = 7
                         params.group = 15;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                    case 'PanelGeneral':
                        this.activeIndex = 7
                         params.group = 16;
                        params.member = 0;
                        await this.$store.dispatch('panelSettings', params)
                        break;
                     case 'PanelMessage':
                        this.activeIndex = 7
                        break;
                    default :
                        this.activeIndex = 0
                        break;

                }
            }

        }
    }
</script>

<style lang="css" scoped> 
    @import "./styles/panel.min.css"; 
</style>