<template>
    <div>
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
        <form  v-else ref="form">
            <validation-observer  ref="events">
                <b-tabs >
                <b-tab :title="$t('program.events.tab1')" >
                    <b-card-text style="margin-top:1rem">
                        <validation-provider :name="$t('program.events.event')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="event_code"> {{$t('program.events.event')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="event_code" :placeholder="$t('program.events.event')" v-model="event_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                       <validation-provider :name="$t('program.events.restore')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="restore_code"> {{$t('program.events.restore')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="restore_code" :placeholder="$t('program.events.restore')" v-model="restore_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.events.cancel')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="restore_code"> {{$t('program.events.cancel')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="cancel_code" :placeholder="$t('program.events.cancel')" v-model="cancel_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.events.trouble')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="trb_event_code"> {{$t('program.events.trouble')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="trb_event_code" :placeholder="$t('program.events.trouble')" v-model="trb_event_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.events.troubleRestore')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="trb_restore_code"> {{$t('program.events.troubleRestore')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="trb_restore_code" :placeholder="$t('program.events.troubleRestore')" v-model="trb_restore_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.events.bypass')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="bypass_code"> {{$t('program.events.bypass')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="bypass_code" :placeholder="$t('program.events.bypass')" v-model="bypass_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('program.events.bypassCancel')" :rules="{ length: 2}"  v-slot="validationContext">
                            <b-form-group class="inputGrp">
                                <b-row>
                                    <b-col sm="4"> <label for="bypass_cancel_code"> {{$t('program.events.bypassCancel')}} </label> </b-col>
                                    <b-col sm="8"> 
                                        <b-input id="bypass_cancel_code" :placeholder="$t('program.events.bypassCancel')" v-model="bypass_cancel_code" :state="getValidationState(validationContext)" />
                                        <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </validation-provider>
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('program.events.bypass')" >
                    <b-card-text style="margin-top:1rem">
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventCreate')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventSetting"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                        <b-row>
                                <b-col sm="4"> {{$t('program.events.eventRestore')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventRestore"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventCancel')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventCancel"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventTrouble')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventTrouble"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventTroubleRestore')}}</b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventTroubleRestore"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventBypass')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventBypass"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group>
                            <b-row>
                                <b-col sm="4"> {{$t('program.events.eventBypassCancel')}} </b-col>
                                <b-col sm="8">
                                    <Multiselect  
                                        v-model="eventBypassCancel"
                                        :show-labels="false" 
                                        :options="eventList"
                                        :multiple="true"
                                        label="name"
                                        track-by="id" >
                                            <template slot="singleLabel" slot-scope="{ option }">
                                                <strong>{{ option.name }} </strong> 
                                            </template>
                                    </Multiselect>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        
            </validation-observer>
        
        </form>
    </div>
    
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import i18n from '@/plugins/i18n';

    export default {
        name: "Zone",
        components:{
            Multiselect, 
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 7,
                member: parseInt(this.data.Code),
                user_pass: this.$store.getters.getPWD,
                offset: 0
            }
            await this.$store.dispatch('panelSettings', params)
        },
        data:function(){
           return{
               events:[],
               eventSetting: [],
               eventArr: [],
               eventRestore: [],
               eventRestoreArr: [],
               eventCancel: [],
               eventCancelArr: [],
               eventTrouble:[],
               eventTroubleArr: [],
               eventTroubleRestore: [],
               eventTroubleRestoreArr: [],
               eventBypass:[],
               eventBypassArr: [],
               eventBypassCancel: [],
               eventBypassCancelArr: [],
               eventList: [
                    {name: i18n.t('program.events.log'), id:0},
                    {name: i18n.t('program.events.cms'), id:1},
                    {name: i18n.t('program.events.notfication'), id:2},
                    {name: i18n.t('program.events.sms'), id:3} 
                ],
                event_code: "",
                restore_code: "",
                cancel_code: "",
                trb_event_code: "",
                trb_restore_code: "",
                bypass_code: "",
                bypass_cancel_code: ""
           } 
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
        },
        watch:{
            settings: function(newVal, oldVal) { // watch it
                console.log(newVal)
                this.event_code = newVal.event_code
                this.restore_code = newVal.restore_code
                this.cancel_code = newVal.cancel_code
                this.trb_event_code = newVal.trb_event_code
                this.trb_restore_code = newVal.trb_restore_code
                this.bypass_code = newVal.bypass_code
                this.bypass_cancel_code = newVal.bypass_cancel_code
                this.eventArr = Object.values(newVal.on_event);
                this.eventRestoreArr = Object.values(newVal.on_restore);
                this.eventCancelArr = Object.values(newVal.on_cancel);
                this.eventTroubleArr = Object.values(newVal.on_trouble_event);
                this.eventTroubleRestoreArr = Object.values(newVal.on_trouble_restore);
                this.eventBypassArr = Object.values(newVal.on_bypass);
                this.eventBypassCancelArr = Object.values(newVal.on_bypass_cancel);
            },
            eventArr: function(newVal, oldVal){
                this.eventSetting = []
                newVal.map((item,i) => {
                    item && this.eventSetting.push(this.eventList[i])
                })
            },
            eventRestoreArr: function(newVal, oldVal){
                this.eventRestore = []
                newVal.map((item,i) => {
                    item && this.eventRestore.push(this.eventList[i])
                })
            },
             eventCancelArr: function(newVal, oldVal){
                this.eventCancel = []
                newVal.map((item,i) => {
                    item && this.eventCancel.push(this.eventList[i])
                })
            },
            eventTroubleArr: function(newVal, oldVal){
                this.eventTrouble= []
                newVal.map((item,i) => {
                    item && this.eventTrouble.push(this.eventList[i])
                })
            },
            eventTroubleRestoreArr: function(newVal, oldVal){
                this.eventTroubleRestore= []
                newVal.map((item,i) => {
                    item && this.eventTroubleRestore.push(this.eventList[i])
                })
            },
            eventBypassArr: function(newVal, oldVal){
                this.eventBypass= []
                newVal.map((item,i) => {
                    item && this.eventBypass.push(this.eventList[i])
                })
            },
            eventBypassCancelArr: function(newVal, oldVal){
                this.eventBypassCancel = []
                newVal.map((item,i) => {
                    item && this.eventBypassCancel.push(this.eventList[i])
                })
            },
             save: async function(data){
                if(data){
                     this.$refs.events.validate().then( async success =>{
                         if(success){
                              let params = {
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 7,
                                member: this.data.Code,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                event_code: this.event_code,
                                restore_code: this.restore_code,
                                cancel_code:this.cancel_code,
                                trb_event_code: this.trb_event_code,
                                trb_restore_code: this.trb_restore_code,
                                bypass_code: this.bypass_code,
                                bypass_cancel_code: this.bypass_cancel_code,
                                on_event:{
                                    log_write: this.eventSetting.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventSetting.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventSetting.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventSetting.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_restore:{
                                    log_write: this.eventRestore.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventRestore.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventRestore.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventRestore.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_cancel:{
                                    log_write: this.eventCancel.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventCancel.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventCancel.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventCancel.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_trouble_event:{
                                    log_write: this.eventTrouble.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventTrouble.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventTrouble.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventTrouble.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_trouble_restore:{
                                    log_write: this.eventTroubleRestore.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventTroubleRestore.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventTroubleRestore.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventTroubleRestore.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_bypass:{
                                    log_write: this.eventBypass.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventBypass.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventBypass.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventBypass.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                                on_bypass_cancel:{
                                    log_write: this.eventBypassCancel.findIndex(e => e.id == 0) > -1 ? true : false,
                                    send_cms: this.eventBypassCancel.findIndex(e => e.id == 1) > -1 ? true : false,
                                    send_push: this.eventBypassCancel.findIndex(e => e.id == 2) > -1 ? true : false,
                                    send_sms: this.eventBypassCancel.findIndex(e => e.id == 3) > -1 ? true : false,                           
                                },
                            }
                            console.log(params)
                            await this.$store.dispatch('setSiaCodeSettings', params)
                         }else{
                             vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                         }
                     })
                     
                   
                    this.saveState(false)

                }
             }
          },
        methods:{
            keypadCount: function(arr){
                let val = arr.map(function(item) {
                    return item['id'];
                });
                return val
            },
            arrToStrByte: function(arr){
                let val = ""
                for(let i = 0; i<4; i++){
                    let isSelect = arr.find(e => e == i+1);
                    isSelect ? val = val + "11" : val = val+"00";
                }
                return val;                
            },
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
         props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
    }
</script>