// Database işlemeleri yapılacak ve dönen datanın state e geçmesi için mutation a gönderilecek
import sendReq from '@/utils/request'

export const setPanelCMS = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setcmssettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

//Komutlarda da bunu kullan
export const panelCommand = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("post","/command/devices/controlpanel", data)
    commit('command', res.data);
    commit('panelSettingLoading', false);
}

export const setZoneSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setzonesettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setKeypadSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setkeypadsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setPanelGenCMS = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setcmsgensettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setKeypadGenSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setkeypadgensettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setUserSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setusersettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setOutputSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setoutputsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setAlarmSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setalarmsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setPartitionSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setpartitionsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setSiaCodeSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setsiacodesettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setAutomationSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/settimersettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setRemoteSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setremotesettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setGSMSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setgsmsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setStationarySettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setautoarmsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const setTimeArmSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/settimedarmsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setNTPSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setntpsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setDeviceGenSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setdevicegensettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setSMSSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setdevicesmssettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setEthSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setethsettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setDNSSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setdevicednssettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const setWifiSettings = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/setwifisettings",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}

export const sendPanelCode = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/generatecode",data)
    commit('panelCode', res.data);
    commit('panelSettingLoading', false);
}
export const deletePanelUser = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("DELETE","command/devices/deletepaneluser",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const panelUserPasswordChg = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    res = await sendReq("POST","command/devices/changepaneluserpass",data)
    commit('setSettings', res.data);
    commit('panelSettingLoading', false);
}
export const panelReader = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    let url = "/devices/"+data.id+"/card-report"
    /*if(data.begin_date)
        url +='?begin_date='+data.begin_date+'&end_date'+data.end_date*/
    res = await sendReq("GET",url,data)
    console.log(res.data)
    commit('reader', res.data);
    commit('panelSettingLoading', false);
}

export const panelInOutReport = async ({ commit }, data) => {
    commit('panelSettingLoading', true);
    let res;
    let url = "/devices/"+data.id+"/card-report-export"
    /*if(data.begin_date)
        url +='?begin_date='+data.begin_date+'&end_date'+data.end_date*/
    // console.log(data)
    res = await sendReq("get",url,data) 
    commit('pdksReport', res);
    commit('panelSettingLoading', false);
}


