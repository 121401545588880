<template>
    <div>
        <validation-observer  ref="timearmdisarm">
             <b-spinner v-if="loading || settingLoading" variant="primary" label="Spinning"></b-spinner>
            <form v-else ref="form">
                <validation-provider :name="$t('program.timeArmDisarm.desc')" :rules="{regex: /^[a-zA-Z0-9 ]+$/}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.timeArmDisarm.desc')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="desc" :placeholder="$t('program.timeArmDisarm.desc')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>

                <validation-provider :name="$t('program.timeArmDisarm.action')" :rules="{required}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.timeArmDisarm.action')}} </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    v-model="act"
                                    :show-labels="false" 
                                    :options="actions"
                                    label="Description"
                                    track-by="Code" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.Description }} </strong> 
                                        </template>
                                </Multiselect> 
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                
                <b-form-group>
                    <b-row>
                        <b-col sm="4"> {{$t('program.timeArmDisarm.processTime')}} </b-col>
                        <b-col sm="8">
                            <Datetime v-model="time" type="time" ></Datetime>
                        </b-col>
                    </b-row>
                </b-form-group>

                <validation-provider :name="$t('program.timeArmDisarm.days')" :rules="{required}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.timeArmDisarm.days')}} </label> </b-col>
                            <b-col sm="8"> 
                                <Multiselect  
                                    v-model="days"
                                    :show-labels="false" 
                                    :options="dayList"
                                    :multiple="true"
                                    label="name"
                                    track-by="id" >
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            <strong>{{ option.name }} </strong> 
                                        </template>
                                </Multiselect>
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </form>
        </validation-observer>
    </div>
    
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'
    import i18n from '@/plugins/i18n';

 import { Datetime } from 'vue-datetime';
   
    let dayList = [
        { id: 1, name: i18n.t('program.general.monday') },
        { id: 2, name: i18n.t('program.general.tuesday') },
        { id: 3, name: i18n.t('program.general.wednesday') },
        { id: 4, name: i18n.t('program.general.thursday') },
        { id: 5, name: i18n.t('program.general.friday') },
        { id: 6, name: i18n.t('program.general.saturday') },
        { id: 7, name: i18n.t('program.general.sunday')},
    ]
    export default {
        name: "Automation",
        components:{
            Multiselect, Datetime
        },
        created:async function(){
            let params = {
                dev_id: parseInt(this.$route.params.id),
                command: 'GET',
                group: 12,
                member: parseInt(this.data.Code),
                user_pass: this.$store.getters.getPWD,
                offset: 0
            }
            await this.$store.dispatch('timedArmActList')
            await this.$store.dispatch('panelSettings', params)
        },
        data:function(){
           return{
             act: null, dayList, days: [], id:0, daysArr: [],
           } 
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        computed:{
            settings(){
                return this.$store.getters.getSettings
            },
            loading(){
                return this.$store.getters.getPanelLoading
            },
            settingLoading(){
                return this.$store.getters.getPanelSettingLoading
            },
            actions(){
                return this.$store.getters.getTimedArms
            }
        },
          watch:{
            settings: function(newVal, oldVal) { // watch it
                this.id = newVal.id
                this.act = this.actions[newVal.aim]
                this.time = newVal.time.hour+":"+newVal.time.minute
                this.daysArr = Object.values(newVal.days);
                this.desc = newVal.description
            },
            daysArr: function(newVal, oldVal){
                this.days = []
                newVal.map((day,i) => {
                    day && this.days.push(this.dayList[i])
                })
            },
            save: async function(data){
                if(data){      
                    this.$refs.timearmdisarm.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                command: 'SET',
                                group: 12,
                                member: this.data.user_no,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                aim: parseInt(this.act.Code),
                                description: this.desc,
                                id: this.id,
                                days:{
                                    monday: this.days.findIndex(e => e.id == 1) > -1 ? true : false,
                                    tuesday: this.days.findIndex(e => e.id == 2) > -1 ? true : false,
                                    wednesday: this.days.findIndex(e => e.id == 3) > -1 ? true : false,
                                    thursday: this.days.findIndex(e => e.id == 4) > -1 ? true : false,
                                    friday: this.days.findIndex(e => e.id == 5) > -1 ? true : false,
                                    saturday: this.days.findIndex(e => e.id == 6) > -1 ? true : false,
                                    sunday: this.days.findIndex(e => e.id == 7) > -1 ? true : false
                                },
                                time: {
                                    hour: moment(this.time).format('HH'),
                                    minute: moment(this.time).format('mm'),
                                },
                            }
                            await this.$store.dispatch('setTimeArmSettings', params)
                        }else{
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                         
                    })
                   
                    this.saveState(false)

                }
            }
          },
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String
        },
        
    }
</script>