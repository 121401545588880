<template>
    <div>
        <validation-observer  ref="ntp">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <b-form v-else class="formBox"> 
                <b-form-group class="inputGrp" :label="$t('program.ntp.ntp')" label-for="ntp">
                    <b-form-radio-group
                        id="ntp"
                        v-model="ntp"
                        :options="yesNo"
                        aria-describedby="live-feedback"></b-form-radio-group>
                </b-form-group>      
                <validation-provider :name="$t('program.ntp.frequency')" :rules="{integer}"  v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.ntp.frequency')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="refreshPeriod" :placeholder="$t('program.ntp.frequency')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.ntp.server1')" :rules="{regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.ntp.server1')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="server1" :placeholder="$t('program.ntp.server1')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.ntp.server2')" :rules="{regex: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.ntp.server2')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="server2" :placeholder="$t('program.ntp.server2')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
                <validation-provider :name="$t('program.ntp.timeZone')" :rules="{integer}" v-slot="validationContext">
                    <b-form-group class="inputGrp">
                        <b-row>
                            <b-col sm="4"> <label for="name"> {{$t('program.ntp.timeZone')}} </label> </b-col>
                            <b-col sm="8"> 
                                <b-form-input id="name" v-model="timeZone" :placeholder="$t('program.ntp.timeZone')" :state="getValidationState(validationContext)" />
                                <b-form-invalid-feedback id="live-feedback" style="display: block; width: 100%">
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-provider>
            </b-form>
        </validation-observer>
    </div>
</template>
<script>
    import Modals from "../modals/"
    import { Datetime } from 'vue-datetime';
    import i18n from '@/plugins/i18n';


    export default {
        name: 'ProsecPanel',
        data: function(){
            return {
                ntp: false,
                refreshPeriod: "",
                server1: "",
                server2: "",
                timeZone: "",
                yesNo: [
                    { text: i18n.t('program.general.no'), value: false },
                    { text: i18n.t('program.general.yes'), value: true },
                ],
            }
        },
        
        watch: { 
            data: function(newVal, oldVal) { // watch it
                this.ntp = newVal.enabled
                this.refreshPeriod = newVal.refresh_period
                this.server1 =  newVal.server1
                this.server2 =  newVal.server2
                this.timeZone = newVal.time_zone
            },
            save: async function(newVal, oldVal){
                if(newVal){
                    this.$refs.ntp.validate().then( async success => {
                        if(success){
                            let params = {
                                dev_id: parseInt(this.$route.params.id),
                                group: 14,
                                member: 0,
                                user_pass: this.$store.getters.getPWD,
                                offset: 0,
                                id: this.data.id,
                                refresh_period: parseInt(this.refreshPeriod),
                                server1: this.server1,
                                server2: this.server2,
                                time_zone: parseInt(this.timeZone),
                                enabled: this.ntp
                            }
                            await this.$store.dispatch('setNTPSettings', params)
                        }else{
                            vm.$root.$bvToast.toast(i18n.t('program.general.validateErr'), {
                                title: i18n.t('program.general.warning'),
                                variant: 'info',
                                noAutoHide: true
                            })
                        }
                    })
                    
                }
                this.saveRemove(false)
            }
        },
        props:{
            data: Object,
            loading: Boolean,
            openModal: Function,
            save: Boolean,
            saveRemove: Function
        },
        methods:{
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
        },
        components:{
            Modals, Datetime
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>