//Stateler çekilip return edilecek

export const login = (state) => {
    return state.login
}

export const getFirmwares = (state) => {
    return state.firmwares
}

export const getModels = (state) => {
    return state.models
}

export const getFirmwareStatus = (state) => {
    return state.status
}
export const getFirmwareTests = (state) => {
    return state.firmwaresTest
}
export const getFirmwareStages = (state) => {
    return state.firmwareStages
}
export const getFirmwareResults = (state) => {
    return state.results
}
