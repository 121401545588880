<template>
      <div>
        <b-icon v-if="data.state == false" icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <b-icon v-if="data.state ==true" icon="check" variant="success" animation="throb" font-scale="8" />
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p v-if="data.state == false"> {{data.panel.description ? data.panel.description: data.panel.Upaneldesc  }} - {{data.panel.dev_id && data.panel.dev_id}}
            {{$t('panel.passiveMsg')}}
        </p>
        <p v-if="data.state ==1"> {{data.panel.description}} - {{data.panel.dev_id}}
            {{$t('panel.activeMsg')}}
        </p>
   </div>
</template>

<script>
    export default {
        name: "SetStateCustomer",
//setState        
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = {
                            dev_id: this.data.panel.ID,
                            state: this.data.state,
                        }
                        await this.$store.dispatch('setpanelstatus', data)
                        if(this.$route.params.dev_id)
                            await this.$store.dispatch('listDeviceUser', this.$route.params.dev_id)

                        await this.$store.dispatch('panels',{offset: 0})
                        this.saveState(false)
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>