<template>
      <div>
        <b-icon icon="x-octagon" variant="danger"  animation="throb" font-scale="8" />
       
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
       
        <p> {{data.user.User ? data.user.User.name : data.user.name}} - {{data.user.User ? data.user.User.email : data.user.email}}
           {{$t('messages.moveSubs')}}
        </p>
   </div>
</template>

<script>
    export default {
        name: "SetStateCustomer",
//setState        
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            saveState: Function,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = {
                            user_id: this.data.user.id,
                        }
                        await this.$store.dispatch('userResetUid', data)
                        await this.$store.dispatch('listUser' )
                        this.saveState(false)
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>