<template>
      <div>
        <b-icon v-if="data.state == false" icon="x-octagon" variant="danger" animation="throb" font-scale="8" />
        <b-icon v-if="data.state ==true" icon="check" variant="success" animation="throb" font-scale="8" />
       
        <hr>
        <h3 class="mb-0">  {{$t('general.sure')}} </h3>
        <p v-if="data.state == false"> {{data.panel.description}} - {{data.panel.dev_id}}
            {{$t('messages.passivePanel')}}
        </p>
        <p v-if="data.state ==1"> {{data.panel.description}} - {{data.panel.dev_id}}
            {{$t('messages.activePanel')}}
        </p>
   </div>
</template>

<script>
    export default {
        name: "SetStateCustomer",
//setState        
        props:{
            data: Object,
            save: Boolean,
            saveState: Function,
            modalName: String,
            modalEvent: Function
        },
         watch: {
            save: async function(data){
                if(data){
                      let data = {
                            dev_id: this.data.panel.id,
                            state: this.data.state,
                        }
                        await this.$store.dispatch('setpanelstatus', data)
                        await this.$store.dispatch('panels',{offset: 0} )
                        this.saveState(false)
                        this.modalEvent(false)
                }
                this.saveState(false)
            }
        }
    }
</script>