<template>
    <div style="height: 100%">
        <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
         <b-table-simple v-else hover responsive>
                <b-th> </b-th>
                <b-th> {{$t('general.state')}} </b-th>
                 <b-th> {{$t('general.name')}} </b-th>
                <b-th> {{$t('general.detail')}} </b-th>
                <b-th> {{$t('general.imei')}} </b-th>
               
                <b-th> {{$t('general.events')}} </b-th>
                <b-th> {{$t('general.operation')}} </b-th>

            <b-tbody >
                
                <b-tr :class="{tblGray: !panel.connected}" v-for="panel in data" :key="panel.id" @dblclick="goDetail(panel)" > 
                    <b-td > 
                        <template v-if="panel.KLStatus && panel.KLStatus.firealarm">
                            <v-icon name="fire" scale="1.25" color="#dc3545" />
                        </template>
                        <template v-else-if="panel.KLStatus && panel.KLStatus.alarm">
                            <v-icon name="user-secret" scale="1.25" />
                        </template>
                        <template v-else-if="delayArr.includes(panel.status)">
                            <v-icon name="stopwatch" scale="1.25" color="#fe8c00" />
                        </template>
                        <template v-else>
                            <div v-if="panel.partcount>0 ? panel.PartList[partIndex].armed : panel.armed">
                                <b-iconstack v-if="panel.stayarmed"  font-scale="1.25" :title="$t('icons.stayarm')">
                                    <b-icon stacked icon="house-fill" animation="fade" scale="0.75" shift-v="-0.25" variant="success"></b-icon>
                                    <b-icon stacked icon="lock" variant="default" scale="0.75" shift-v="-0.25" ></b-icon>
                                </b-iconstack>
                                <b-icon v-else icon="lock-fill" variant="success" scale="1.25" shift-v="1.25" aria-hidden="true" :title="$t('icons.arm')"></b-icon> 
                            </div>
                            <b-icon v-else icon="unlock-fill" variant="danger" scale="1.25" shift-v="1.25" aria-hidden="true" :title="$t('icons.disarm')"></b-icon> 
                        </template>
                    </b-td>
                   <b-td> {{panel.partcount>0 ? panel.PartList[partIndex].description : panel.status_desc}} </b-td>
                   <b-td> {{panel.description}} </b-td>
                   <b-td> 
                       <div class="detail">
                            <div v-if="panel.model.slice(-1) === 'G'"><b-icon :icon="'reception'+Math.floor(panel.gsmlevel/20)" /> <span> %{{panel.gsmlevel}} </span> </div>
                            <div v-if="panel.KLStatus.temp < 128"><b-icon icon="thermometer-half" /> {{panel.KLStatus.temp}} °C</div>
                       </div>
                   </b-td>
                   <b-td> {{panel.dev_id}} </b-td>
                   
                   <b-td> 
                        <div class="stats">
                             <div v-if="panel.KLStatus.power !== undefined">
                            <b-icon
                                icon="lightning-fill" 
                                :variant="panel.KLStatus.power ? 'success': 'danger'" 
                                :title="panel.KLStatus.power ? $t('icons.power1') : $t('icons.power0')" />
                        </div>
                        <div v-if="panel.battary !== undefined">
                            <b-icon v-if="panel.battary === 1" icon="battery" variant="danger" :title="$t('icons.battery1')" />
                            <b-icon v-else-if="panel.battary === 0" icon="battery-charging" variant="success" :title="$t('icons.battery1')" />
                             <b-iconstack v-else :title="$t('icons.battery0')">
                                <b-icon stacked icon="x" animation="fade" scale="1.25" variant="default"></b-icon>
                                <b-icon stacked icon="battery" variant="danger" ></b-icon>
                            </b-iconstack>
                        </div>
                        <div v-if="panel.KLStatus.bypass">
                            <b-icon  icon="bootstrap-fill" variant="danger" :title="$t('icons.bypass')"/>
                        </div>
                        <div v-if="panel.motion">
                             <v-icon name="walking" scale="1.25" color="#dc3545" variant="danger" animation="throb" :title="$t('icons.motion')"/>
                        </div>
                        <div v-if="panel.KLStatus.event">
                            <b-icon   icon="exclamation-triangle-fill" variant="warning" :title="$t('icons.event')" />
                        </div>
                        <div>
                            <b-icon 
                                v-if="!panel.ahm1"
                                :icon="panel.ahm1  ? 'shield-fill-check': 'shield-fill-x'"
                                :variant="panel.ahm1 ? 'success': 'danger'" 
                                :title="panel.ahm1 ? $t('icons.cms11') : $t('icons.cms10')" />
                        </div>    
                        <div>
                            <b-icon 
                                v-if="!panel.ahm2"
                                :icon="panel.ahm2  ? 'shield-fill-check': 'shield-fill-x'"
                                :variant="panel.ahm2 ? 'success': 'danger'" 
                                :title="panel.ahm2 ? $t('icons.cms21') : $t('icons.cms20')" />
                        </div>                        
                        <div>
                            <b-icon 
                                v-if="!panel.connected"
                                :icon="panel.connected ? 'cloud-check-fill': 'cloud-slash-fill'"
                                :variant="panel.connected ? 'success': 'danger'"
                                :title="panel.connected ? $t('icons.cloud1') : $t('icons.cloud0')" />
                        </div>
                        </div>
                   </b-td> 
                   <b-td>
                       <b-dropdown variant="default">
                            <template slot="button-content"> <b-icon icon="three-dots"  scale="1.25" shift-v="1.25" aria-hidden="true" /> </template>
                            <b-dropdown-item href="#" @click="openModal(true, 'changeDesc', panel)">{{$t('menus.changeDesc')}} </b-dropdown-item>
                            <b-dropdown-item href="#" @click="openModal(true, 'delete', panel)" > {{$t('menus.removePanel')}} </b-dropdown-item>
                            <b-dropdown-item v-if="auth.BYPASS" href="#" @click="openModal(true, 'password', {panel: panel, form:'zones'})" > {{$t('menus.zoneList')}} </b-dropdown-item>
                            <b-dropdown-item v-if="auth.OUTPUT" href="#" @click="openModal(true, 'password', {panel: panel, form:'outs'})" > {{$t('menus.outList')}} </b-dropdown-item>
                            <b-dropdown-item v-if="!panel.armed && auth.PRG" href="#" @click="goDetail(panel)" > {{$t('menus.program')}}</b-dropdown-item>
                        </b-dropdown>
                   </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
    import Modals from "../modals/"
    export default {
        name: 'ProsecPanel',
    
        props:{
            data: Array,
            openModal: Function
        },
        data: function(){
            return{
                delayArr : [9, 10, 14, 15],
                delayArrPart : [9, 15, 10, 14],
                partIndex: 0,
            }
        },
        components:{
            Modals
        },
        methods:{
            goDetail(id){
               panel.armed == false && this.$store.getters.getAuthPlan.PRG && this.openModal(true, 'password', panel.id)
            }
        },
        computed:{
            auth: function(){
                return this.$store.getters.getAuthPlan
            }
        }
    }
</script>

<style lang="css" scoped> 
    @import "../styles/panel.min.css"; 
</style>