<template>
    <b-modal id="editModal" ref="modal" :title="title" @hide="onHide" @cancel="onCancel" :centered="true" :visible="open" :ok-title="okTitle"
     :cancel-title="$t('general.cancel')" size="xl" @ok="saveModal" :ok-disabled="disabled" :static="true" scrollable>
            <component v-bind:is="renderModalForm" :save="saveAct" :data="modalProps" :changeVal="changeDisabled" :changeData="changeData" 
            :saveState="saveState" :modalName="modalName" :modalEvent="modalEvent" :formName="form" :onComplete="onComplete"></component>
    </b-modal>
</template>

<script>
   
    import i18n from '@/plugins/i18n';
    import Delete from './Delete'
    import Report from './Report'
    import Test from './Test'
    import Version from './Version'
    import Stage from './Stage'

    

    export default {
        name: 'UserModal',
        data: function(){
            return{
                title: '',
                okTitle: i18n.t('general.save'),
                disabled: false,
                saveAct: false
            }
        },
        props:{
            open: Boolean,
            modalEvent: Function,
            form: String,
            modalName: String,
            modalProps: Object,
            onComplete: Function,
            
        },
        components:{
            Delete, Report, Test, Version, Stage
        },
        computed:{
            //Açılacak Modalı belirleme işlemleri
            renderModalForm: function(){
                if(this.modalName === "add"){
                    this.title = i18n.t('general.add')
                    this.okTitle = i18n.t('general.add')
                    return this.form
                } else if (this.modalName === "edit"){
                    this.title = i18n.t('general.edit')
                    this.okTitle = i18n.t('general.edit')
                    return this.form
                } else if(this.modalName === "delete") {
                    this.title = i18n.t('general.delete')
                    this.okTitle = i18n.t('general.delete')
                    return "Delete"
                } else if(this.modalName === "report") {
                    this.title = "Panel Test Raporu"
                    this.okTitle = "Raporu Al"
                    return "Report"
                }else if(this.modalName === "Error") {
                    this.title = "Hata Bildirimi"
                    this.okTitle = "Hatayı Bildir"
                    return this.form
                }else if(this.modalName === "addVersion") {
                    this.title = "Yeni Versiyon Ekle"
                    this.okTitle = "Versiyon Ekle"
                    return "Version"
                }else if(this.modalName === "addStage") {
                    this.title = "Yeni Aşama Ekle"
                    this.okTitle = "Aşama Ekle"
                    return "Stage"
                }else{
                    return this.modalName 
                }
                
            },
        },
        methods:{
            onHide: function(){
                if(!this.saveAct)
                    this.modalEvent(false)
            },
            onCancel: function(){
                this.saveState(false)
                if(!this.saveAct)
                    this.modalEvent(false)

            },
            saveState: function(val){
                this.saveAct = val
            },
            // Modal tamamlandığı zaman olacak event
            saveModal: async function(bvModalEvt){
                bvModalEvt.preventDefault()
                // Modalın içerisindeki watchı tetikler
                this.saveState(true)
            },
            changeData(val, name){
                this.modalProps[name] = val
            },
            changeDisabled(val){
                if(val == this.modalProps.name)
                    this.disabled = false
            }
        },
        watch:{
            saveAct(){
                return false
            }
        }
    }
</script>